import { httpsCallable } from 'firebase/functions'
import { functions } from '../firebase'

export const signupMailchimpFlow = async (userData) => {
  try {
    const addToMailchimp = httpsCallable(functions, 'signupMailchimpFlow')
    const result = await addToMailchimp(userData)
    console.log('Successfully added to Mailchimp:', result.data)
    return result.data
  } catch (error) {
    console.error('Error adding to Mailchimp:', error)
    if (error.code === 'functions/internal') {
      console.error('Internal server error occurred in Cloud Function')
    }
    throw error
  }
}
