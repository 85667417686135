import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Search,
  Share2,
  Star,
  MoreVertical,
  Paperclip,
  Send
} from 'lucide-react'
import EmployerNav from './Employers/EmployerNav'
import { getJobSeekers } from '../models/User'

const softColors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#4a90e2',
  secondary: '#f6e05e',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0',
  success: '#68d391',
  warning: '#f6ad55',
  danger: '#fc8181',
  info: '#63b3ed',
  icons: '#12C2E8',
  icontext: '#C859FF',
  yellow: '#f6e05e'
}

const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: ${softColors.background};
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const Sidebar = styled.div`
  width: 320px;
  border-right: 1px solid ${softColors.border};
  background-color: ${softColors.card};
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${softColors.border};
`

const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  color: ${softColors.text};
`

const SearchContainer = styled.div`
  position: relative;
`

const SearchInput = styled.input`
  width: 80%;
  padding: 8px 8px 8px 32px;
  border: 1px solid ${softColors.border};
  border-radius: 4px;
  font-size: 14px;
  color: ${softColors.text};
  background-color: ${softColors.background};

  &::placeholder {
    color: ${softColors.textLight};
  }
`

const SearchIcon = styled(Search)`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: ${softColors.textLight};
`

const JobSeekerList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  flex: 1;
`

const JobSeekerItem = styled.li`
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid ${softColors.border};
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${({ selected }) =>
    selected ? softColors.background : 'transparent'};
  &:hover {
    background-color: ${softColors.background};
  }
`

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${softColors.border};
  background-image: ${({ src }) => (src ? `url(${src})` : 'none')};
  background-size: cover;
  background-position: center;
`

const JobSeekerName = styled.div`
  font-weight: 600;
  color: ${softColors.text};
`

const JobSeekerEmail = styled.div`
  font-size: 12px;
  color: ${softColors.textLight};
`

const MainContent = styled.main`
  flex: 1;
  display: flex;
  overflow: hidden;
`

const MainChat = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${softColors.border};
  background-color: ${softColors.card};
`

const RecipientInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const RecipientName = styled.h2`
  font-weight: 600;
  color: ${softColors.text};
`

const RecipientStatus = styled.p`
  font-size: 14px;
  color: ${softColors.textLight};
`

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
`

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${softColors.textLight};
  padding: 4px;

  &:hover {
    color: ${softColors.primary};
  }
`

const ChatArea = styled.div`
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${softColors.textLight};
  background-color: ${softColors.background};
`

const InputArea = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px;
  border-top: 1px solid ${softColors.border};
  background-color: ${softColors.card};
`

const MessageInput = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid ${softColors.border};
  border-radius: 4px;
  font-size: 14px;
  color: ${softColors.text};

  &::placeholder {
    color: ${softColors.textLight};
  }
`

const SendButton = styled.button`
  background-color: ${softColors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: ${softColors.border};
    cursor: not-allowed;
  }
`

export default function Messaging() {
  const [jobSeekers, setJobSeekers] = useState([])
  const [selectedJobSeeker, setSelectedJobSeeker] = useState(null)

  useEffect(() => {
    const fetchJobSeekers = async () => {
      try {
        const seekers = await getJobSeekers(10) // Fetch top 10 job seekers
        setJobSeekers(seekers)
      } catch (error) {
        console.error('Error fetching job seekers:', error)
      }
    }

    fetchJobSeekers()
  }, [])

  return (
    <Container>
      <NavWrapper>
        <EmployerNav />
      </NavWrapper>
      <MainContent>
        <Sidebar>
          <Header>
            <Title>Messages</Title>
            <SearchContainer>
              <SearchIcon size={16} />
              <SearchInput placeholder="Search messages" />
            </SearchContainer>
          </Header>
          <JobSeekerList>
            {jobSeekers.map((seeker) => (
              <JobSeekerItem
                key={seeker.id}
                selected={selectedJobSeeker?.id === seeker.id}
                onClick={() => setSelectedJobSeeker(seeker)}
              >
                <Avatar src={seeker.profile_picture} />
                <div>
                  <JobSeekerName>{`${seeker.first_name} ${seeker.last_name}`}</JobSeekerName>
                  <JobSeekerEmail>{seeker.email}</JobSeekerEmail>
                </div>
              </JobSeekerItem>
            ))}
          </JobSeekerList>
        </Sidebar>
        <MainChat>
          <ChatHeader>
            <RecipientInfo>
              <Avatar src={selectedJobSeeker?.profile_picture} />
              <div>
                <RecipientName>
                  {selectedJobSeeker
                    ? `${selectedJobSeeker.first_name} ${selectedJobSeeker.last_name}`
                    : 'Start a conversation'}
                </RecipientName>
                <RecipientStatus>
                  {selectedJobSeeker
                    ? `Email: ${selectedJobSeeker.email}`
                    : 'Select a job seeker to message'}
                </RecipientStatus>
              </div>
            </RecipientInfo>
            <ActionButtons>
              <IconButton>
                <Share2 size={16} />
              </IconButton>
              <IconButton>
                <Star size={16} />
              </IconButton>
              <IconButton>
                <MoreVertical size={16} />
              </IconButton>
            </ActionButtons>
          </ChatHeader>
          <ChatArea>
            {selectedJobSeeker
              ? `Chatting with ${selectedJobSeeker.first_name} ${selectedJobSeeker.last_name}`
              : 'There are no job seekers to message at this time.'}
          </ChatArea>
          <InputArea>
            <IconButton>
              <Paperclip size={16} />
            </IconButton>
            <MessageInput
              placeholder={
                selectedJobSeeker
                  ? 'Type a message'
                  : 'Select a job seeker to start typing'
              }
              disabled={!selectedJobSeeker}
            />
            <SendButton disabled={!selectedJobSeeker}>
              <Send size={16} />
            </SendButton>
          </InputArea>
        </MainChat>
      </MainContent>
    </Container>
  )
}
