import { db } from '../firebase'
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  startAfter,
  arrayUnion,
  limit,
  arrayRemove
} from 'firebase/firestore'

const USERS_COLLECTION = 'users'
const PROFILE_SUBCOLLECTION = 'profiles'
const JOBSEEKER_PROFILE_DOC = 'jobseeker'

export const createJobseekerProfileObject = (profileData) => {
  const formatUrl = (url) => {
    if (!url) return null
    const trimmedUrl = url.trim()
    if (trimmedUrl === '') return null
    if (trimmedUrl.startsWith('http://') || trimmedUrl.startsWith('https://')) {
      return trimmedUrl
    }
    return `https://${trimmedUrl}`
  }
  return {
    intake_completed: profileData.intake_completed || false,
    demographics_completed: profileData.demographics_completed || false,
    blocked_companies: profileData.blocked_companies || [],
    favorites: profileData.favorites || [],

    // Personal Info
    date_of_birth: profileData.date_of_birth || null,
    pronouns:
      profileData.pronouns === 'other'
        ? profileData.customPronouns
        : profileData.pronouns || null,
    street_address: profileData.street_address || null,
    city: profileData.city || null,
    state: profileData.state || null,
    zip_code: profileData.zip_code || null,
    phone: profileData.phone || null,

    // Location/Address (from Google Places)
    address: profileData.address || null,
    has_seen_welcome: profileData.has_seen_welcome || false,

    // Work History with expanded fields
    work_history: (profileData.work_history || []).map((job) => ({
      title: job.title || null,
      employer: job.employer || null,
      start_date: job.start_date || null,
      end_date: job.end_date || null,
      present: job.present || false,
      industry: job.industry || [],
      company_type: job.company_type || [],
      job_function: job.job_function || [],
      other_job_function: job.job_function?.includes('Other (specify)')
        ? job.other_job_function
        : null,
      work_email: job.work_email || null
    })),

    // Athletic History
    athlete_status: profileData.is_athlete === 'yes',
    athletic_history: (profileData.athletic_history || []).map((history) => ({
      sport: history.sport || null,
      league: history.league || null,
      team: history.team || null,
      position: history.position || null,
      start_date: history.start_date || null,
      end_date: history.end_date || null,
      current: false,
      achievements: history.achievements || []
    })),

    // Education with expanded fields
    highest_education: profileData.highest_education || null,
    education_history: (profileData.education_history || []).map((edu) => ({
      institution: edu.institution || null,
      degree_type: edu.degree_type || null,
      field_of_study: edu.field_of_study || null,
      graduation_date: edu.graduation_date || null
    })),

    // Experience and Skills
    years_experience: profileData.years_experience || null,
    current_level: profileData.current_level || null, // Added this field
    startup_experience: profileData.startup_experience || false,
    technical_skills: profileData.technical_skills || [],
    other_technical_skill: profileData.technical_skills?.includes('Other')
      ? profileData.other_technical_skill
      : null,
    industries: profileData.industries || [],
    subcategories: profileData.subcategories || {},

    // Preferences and Strengths
    top_strengths: profileData.top_strengths || [], // Ensure array is initialized
    work_preference: profileData.work_preference || null, // Changed from preferred_telework_policy
    job_search_challenge: profileData.job_search_challenge || null,
    culture_preferences: profileData.culture_preferences || [], // Changed from company_culture_preferences
    languages: profileData.languages || [],

    // Social Links - expanded structure
    linkedin: profileData.social_links?.linkedin || null,
    gondola: profileData.social_links?.gondola || null,
    portfolio: profileData.social_links?.portfolio || null,
    github: profileData.social_links?.github || null,
    personal_website: profileData.social_links?.additional1 || null,
    additional_website_2: profileData.social_links?.additional2 || null,

    // Files
    profile_picture: profileData.profile_picture || null,
    resume_url: profileData.resume_url || null,

    // Demographics
    gender: profileData.gender || null,
    race: profileData.race || [],
    sexual_orientation: profileData.sexual_orientation || null,
    veteran_status: profileData.veteran_status || null,
    disability_status: profileData.disability_status || null,
    accommodation_needed: profileData.accommodation_needed || null,
    accommodation_details: profileData.accommodation_details || null,

    // Additional Info
    about: profileData.about || null,
    saved_job_filters: profileData.saved_job_filters || [],
    saved_company_filters: profileData.saved_company_filters || [],

    // Metadata
    created_at: profileData.created_at || serverTimestamp(),
    updated_at: serverTimestamp()
  }
}

// Create or Update Jobseeker Profile
export const createOrUpdateJobseekerProfile = async (userId, profileData) => {
  try {
    const profileRef = doc(
      db,
      USERS_COLLECTION,
      userId,
      PROFILE_SUBCOLLECTION,
      JOBSEEKER_PROFILE_DOC
    )

    await setDoc(
      profileRef,
      {
        ...createJobseekerProfileObject(profileData)
      },
      { merge: true }
    )

    const savedDoc = await getDoc(profileRef)
    const savedData = savedDoc.data()

    return savedData
  } catch (error) {
    console.error('Error in createOrUpdateJobseekerProfile:', error.message)
    throw error
  }
}

// Get a Jobseeker Profile by userId
export const getJobseekerProfile = async (userId) => {
  try {
    const profileRef = doc(
      db,
      USERS_COLLECTION,
      userId,
      PROFILE_SUBCOLLECTION,
      JOBSEEKER_PROFILE_DOC
    )
    const profileSnap = await getDoc(profileRef)
    if (profileSnap.exists()) {
      return profileSnap.data()
    } else {
      console.warn(`No profile found for user: ${userId}`)
      return null
    }
  } catch (error) {
    console.error('Error fetching jobseeker profile:', error.message)
    throw error
  }
}

// Update Jobseeker Profile fields
export const updateJobseekerProfile = async (userId, updateData) => {
  try {
    const profileRef = doc(
      db,
      USERS_COLLECTION,
      userId,
      PROFILE_SUBCOLLECTION,
      JOBSEEKER_PROFILE_DOC
    )

    await updateDoc(profileRef, {
      ...updateData,
      updated_at: serverTimestamp()
    })
  } catch (error) {
    console.error('Error in updateJobseekerProfile:', error.message)
    throw error
  }
}

// Add an employer to jobseeker's favorites
export const addFavoriteEmployer = async (userId, employerId) => {
  const profileRef = doc(
    db,
    USERS_COLLECTION,
    userId,
    PROFILE_SUBCOLLECTION,
    JOBSEEKER_PROFILE_DOC
  )

  await updateDoc(profileRef, {
    favorites: arrayUnion(employerId)
  })
}

// Remove an employer from jobseeker's favorites
export const removeFavoriteEmployer = async (userId, employerId) => {
  const profileRef = doc(
    db,
    USERS_COLLECTION,
    userId,
    PROFILE_SUBCOLLECTION,
    JOBSEEKER_PROFILE_DOC
  )

  await updateDoc(profileRef, {
    favorites: arrayRemove(employerId)
  })
}

// Get user by firebase_uid
export const getUserByFirebaseUid = async (firebase_uid) => {
  const userRef = doc(db, USERS_COLLECTION, firebase_uid)
  const userSnap = await getDoc(userRef)

  if (userSnap.exists()) {
    const userData = { id: userSnap.id, ...userSnap.data() }

    if (userData.role === 'jobseeker') {
      const jobseekerProfileRef = doc(
        db,
        USERS_COLLECTION,
        firebase_uid,
        PROFILE_SUBCOLLECTION,
        JOBSEEKER_PROFILE_DOC
      )
      const jobseekerProfileSnap = await getDoc(jobseekerProfileRef)

      if (jobseekerProfileSnap.exists()) {
        const jobseekerProfileData = jobseekerProfileSnap.data()
        return {
          ...userData,
          jobseekerProfile: jobseekerProfileData
        }
      }
    }

    return userData
  } else {
    return null
  }
}

export const getAllJobSeekerProfiles = async (userIds) => {
  try {
    const profiles = {}

    // Fetch profiles for each user ID
    for (const userId of userIds) {
      const profileRef = doc(db, 'users', userId, 'profiles', 'jobseeker')
      const profileSnap = await getDoc(profileRef)

      if (profileSnap.exists()) {
        profiles[userId] = profileSnap.data()
      }
    }

    return profiles
  } catch (error) {
    console.error('Error fetching job seeker profiles:', error)
    throw error
  }
}

export const getJobSeekers = async (maxResults = 60) => {
  try {
    // Limit the initial query to `maxResults`
    const jobSeekersQuery = query(
      collection(db, USERS_COLLECTION),
      where('role', '==', 'jobseeker'),
      limit(maxResults)
    )

    const jobSeekersSnapshot = await getDocs(jobSeekersQuery)
    const jobSeekers = []

    for (const docSnapshot of jobSeekersSnapshot.docs) {
      const userData = docSnapshot.data()

      // Fetch the job seeker profile from the subcollection
      const jobSeekerProfileRef = doc(
        db,
        USERS_COLLECTION,
        docSnapshot.id,
        PROFILE_SUBCOLLECTION,
        JOBSEEKER_PROFILE_DOC
      )

      const jobSeekerProfileSnap = await getDoc(jobSeekerProfileRef)
      let jobSeekerProfileData = {}

      if (jobSeekerProfileSnap.exists()) {
        jobSeekerProfileData = jobSeekerProfileSnap.data()
      } else {
        console.warn(
          `No profile data found for job seeker ID: ${docSnapshot.id}`
        )
      }

      jobSeekers.push({
        id: docSnapshot.id,
        first_name: userData.first_name || '',
        last_name: userData.last_name || '',
        email: userData.email || '',
        created_at: userData.created_at || null,
        updated_at: userData.updated_at || null,
        role: userData.role || 'jobseeker',
        pronouns: jobSeekerProfileData.pronouns || '', // Explicitly add pronouns
        ...jobSeekerProfileData
      })
    }

    return jobSeekers
  } catch (error) {
    console.error('Error in getJobSeekers:', error)
    return []
  }
}
export const getJobSeekers2 = async (pageSize = 100, lastVisible = null) => {
  try {
    // Base query with increased batch size
    const baseQueryConditions = [
      where('role', '==', 'jobseeker'),
      orderBy('created_at', 'desc'),
      limit(pageSize)
    ]

    let jobSeekersQuery
    if (lastVisible) {
      jobSeekersQuery = query(
        collection(db, 'users'),
        ...baseQueryConditions,
        startAfter(lastVisible)
      )
    } else {
      jobSeekersQuery = query(collection(db, 'users'), ...baseQueryConditions)
    }

    const jobSeekersSnapshot = await getDocs(jobSeekersQuery)

    const lastVisibleDoc =
      jobSeekersSnapshot.docs[jobSeekersSnapshot.docs.length - 1]

    const jobSeekers = jobSeekersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }))

    const hasMore = jobSeekersSnapshot.size === pageSize

    console.log(`Loaded batch of ${jobSeekers.length} job seekers`)

    return {
      jobSeekers,
      lastVisible: lastVisibleDoc,
      hasMore
    }
  } catch (error) {
    console.error('Error in getJobSeekers2:', error)
    throw error
  }
}
