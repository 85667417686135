// src/components/Admin/EmployersTab.js
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { collection, query, where, getDocs } from 'firebase/firestore'
import { db } from '../../firebase'
import { getEmployerJobPostingsWithApplicants } from '../../models/JobPosting'
import { X, User } from 'lucide-react'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  height: calc(100vh - 150px);
`

const EmployerList = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
  overflow-y: auto;
`

const JobList = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
  overflow-y: auto;
`

const EmployerCard = styled.div`
  background: ${(props) =>
    props.selected ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.1)'};
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`

const JobCard = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 10px;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`

const CompanyName = styled.h3`
  margin: 0 0 5px 0;
  font-size: 16px;
`

const CompanyDetails = styled.div`
  font-size: 14px;
  opacity: 0.8;
`

const JobTitle = styled.h4`
  margin: 0 0 5px 0;
  font-size: 16px;
`

const JobDetails = styled.div`
  font-size: 14px;
  opacity: 0.8;
`

const StatsContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
`

const StatBox = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
`

const EmptyState = styled.div`
  text-align: center;
  padding: 40px;
  opacity: 0.7;
`

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  color: white;
  margin-bottom: 15px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.3);
  }
`

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(135deg, #6366f1 0%, #a855f7 100%);
  border-radius: 12px;
  padding: 30px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  z-index: 1000;
  color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
`

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`

const JobDetailSection = styled.div`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`

const SectionTitle = styled.h3`
  font-size: 18px;
  margin-bottom: 12px;
  color: rgba(255, 255, 255, 0.9);
`

const DetailList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const DetailItem = styled.li`
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.5;

  &:last-child {
    margin-bottom: 0;
  }
`

const Tag = styled.span`
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-block;
`

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`

const SalaryTag = styled(Tag)`
  background: rgba(139, 92, 246, 0.3);
`

const NoDataText = styled.p`
  color: rgba(255, 255, 255, 0.6);
  font-style: italic;
`

const LoadingSpinner = styled.div`
  text-align: center;
  padding: 20px;
  color: rgba(255, 255, 255, 0.7);
`

const JobDetailModal = ({ job, onClose }) => {
  if (!job) return null

  const formatSalary = (salary) => {
    if (!salary) return 'Not specified'

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    })

    if (salary.type === 'unpaid') return 'Unpaid'
    if (salary.type === 'credit') return 'School Credit'

    const start = formatter.format(salary.range_start)
    const end = formatter.format(salary.range_end)
    const period = salary.type === 'hourly' ? '/hr' : '/year'

    return `${start} - ${end}${period}`
  }

  return (
    <>
      <ModalOverlay onClick={onClose} />
      <Modal>
        <CloseButton onClick={onClose}>
          <X size={24} />
        </CloseButton>

        <JobDetailSection>
          <h2>{job.title}</h2>
          <p>
            Created:{' '}
            {new Date(job.created_at?.seconds * 1000).toLocaleDateString()}
          </p>
          <SalaryTag>{formatSalary(job.salary)}</SalaryTag>
        </JobDetailSection>

        <JobDetailSection>
          <SectionTitle>Basic Information</SectionTitle>
          <DetailList>
            <DetailItem>Location: {job.location || 'Not specified'}</DetailItem>
            <DetailItem>
              Department: {job.department || 'Not specified'}
            </DetailItem>
            <DetailItem>
              Work Policy: {job.work_policy || 'Not specified'}
            </DetailItem>
            <DetailItem>Level: {job.level || 'Not specified'}</DetailItem>
            <DetailItem>Status: {job.status}</DetailItem>
            <DetailItem>
              Total Applicants: {job.applicant_count || 0}
            </DetailItem>
            <DetailItem>
              Willing to Sponsor: {job.willing_to_sponsor ? 'Yes' : 'No'}
            </DetailItem>
          </DetailList>
        </JobDetailSection>

        <JobDetailSection>
          <SectionTitle>Job Types</SectionTitle>
          <TagsContainer>
            {job.type?.length > 0 ? (
              job.type.map((type, index) => <Tag key={index}>{type}</Tag>)
            ) : (
              <NoDataText>No job types specified</NoDataText>
            )}
          </TagsContainer>
        </JobDetailSection>

        {job.description && (
          <JobDetailSection>
            <SectionTitle>Description</SectionTitle>
            <p style={{ whiteSpace: 'pre-wrap' }}>{job.description}</p>
          </JobDetailSection>
        )}

        {job.responsibilities && (
          <JobDetailSection>
            <SectionTitle>Responsibilities</SectionTitle>
            <p style={{ whiteSpace: 'pre-wrap' }}>{job.responsibilities}</p>
          </JobDetailSection>
        )}

        {job.requirements && (
          <JobDetailSection>
            <SectionTitle>Requirements</SectionTitle>
            <p style={{ whiteSpace: 'pre-wrap' }}>{job.requirements}</p>
          </JobDetailSection>
        )}

        {job.nice_to_haves && (
          <JobDetailSection>
            <SectionTitle>Nice to Have</SectionTitle>
            <p style={{ whiteSpace: 'pre-wrap' }}>{job.nice_to_haves}</p>
          </JobDetailSection>
        )}

        {job.technical_skills?.length > 0 && (
          <JobDetailSection>
            <SectionTitle>Technical Skills</SectionTitle>
            <TagsContainer>
              {job.technical_skills.map((skill, index) => (
                <Tag key={index}>{skill}</Tag>
              ))}
            </TagsContainer>
          </JobDetailSection>
        )}

        {job.strengths?.length > 0 && (
          <JobDetailSection>
            <SectionTitle>Required Strengths</SectionTitle>
            <TagsContainer>
              {job.strengths.map((strength, index) => (
                <Tag key={index}>{strength}</Tag>
              ))}
            </TagsContainer>
          </JobDetailSection>
        )}

        {job.benefits?.length > 0 && (
          <JobDetailSection>
            <SectionTitle>Benefits</SectionTitle>
            <TagsContainer>
              {job.benefits.map((benefit, index) => (
                <Tag key={index}>
                  {typeof benefit === 'object' ? benefit.description : benefit}
                </Tag>
              ))}
            </TagsContainer>
          </JobDetailSection>
        )}
        {job.additional_compensation?.length > 0 && (
          <JobDetailSection>
            <SectionTitle>Additional Compensation</SectionTitle>
            <TagsContainer>
              {job.additional_compensation.map((comp, index) => (
                <Tag key={index}>
                  {typeof comp === 'object' ? comp.description : comp}
                </Tag>
              ))}
            </TagsContainer>
          </JobDetailSection>
        )}
      </Modal>
    </>
  )
}

const EmployersTab = () => {
  const [employers, setEmployers] = useState([])
  const [selectedEmployer, setSelectedEmployer] = useState(null)
  const [employerJobs, setEmployerJobs] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(true)
  const [selectedJob, setSelectedJob] = useState(null)

  useEffect(() => {
    const fetchEmployers = async () => {
      try {
        const employersQuery = query(
          collection(db, 'users'),
          where('role', '==', 'employer')
        )
        const snapshot = await getDocs(employersQuery)
        const employerData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
        setEmployers(employerData)
      } catch (error) {
        console.error('Error fetching employers:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchEmployers()
  }, [])

  const handleEmployerSelect = async (employer) => {
    setSelectedEmployer(employer)
    try {
      const jobs = await getEmployerJobPostingsWithApplicants(employer.id)
      setEmployerJobs(jobs)
    } catch (error) {
      console.error('Error fetching employer jobs:', error)
    }
  }

  const filteredEmployers = employers.filter((employer) =>
    employer.company_name?.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A'
    return new Date(timestamp.seconds * 1000).toLocaleDateString()
  }

  return (
    <Container>
      <EmployerList>
        <SearchInput
          type="text"
          placeholder="Search employers..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {loading ? (
          <LoadingSpinner>Loading employers...</LoadingSpinner>
        ) : filteredEmployers.length > 0 ? (
          filteredEmployers.map((employer) => (
            <EmployerCard
              key={employer.id}
              selected={selectedEmployer?.id === employer.id}
              onClick={() => handleEmployerSelect(employer)}
            >
              <CompanyName>
                {employer.company_name || 'Unnamed Company'}
              </CompanyName>
              <CompanyDetails>
                <div>Email: {employer.email}</div>
                <div>Joined: {formatDate(employer.created_at)}</div>
              </CompanyDetails>
            </EmployerCard>
          ))
        ) : (
          <EmptyState>No employers found</EmptyState>
        )}
      </EmployerList>

      <JobList>
        {selectedEmployer ? (
          <>
            <h2>{selectedEmployer.company_name}'s Jobs</h2>
            {employerJobs.length > 0 ? (
              employerJobs.map((job) => (
                <JobCard key={job.id} onClick={() => setSelectedJob(job)}>
                  <JobTitle>{job.title}</JobTitle>
                  <JobDetails>
                    <div>Location: {job.location || 'Not specified'}</div>
                    <div>Work Policy: {job.work_policy || 'Not specified'}</div>
                    <div>Department: {job.department || 'Not specified'}</div>
                    <StatsContainer>
                      <StatBox>Status: {job.status}</StatBox>
                      <StatBox>Applicants: {job.applicant_count || 0}</StatBox>
                      <StatBox>Posted: {formatDate(job.created_at)}</StatBox>
                    </StatsContainer>
                  </JobDetails>
                </JobCard>
              ))
            ) : (
              <EmptyState>No jobs found for this employer</EmptyState>
            )}
          </>
        ) : (
          <EmptyState>Select an employer to view their jobs</EmptyState>
        )}
      </JobList>

      {selectedJob && (
        <JobDetailModal
          job={selectedJob}
          onClose={() => setSelectedJob(null)}
        />
      )}
    </Container>
  )
}

export default EmployersTab
