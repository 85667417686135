// JobseekerIntakeForm.js
import React, { useState, useRef } from 'react'
import { ChevronDown, ChevronUp, Upload, Info } from 'lucide-react'
import styled, { createGlobalStyle } from 'styled-components'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { serverTimestamp } from 'firebase/firestore'
import { storage, auth } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import { createOrUpdateJobseekerProfile } from '../../models/JobSeekerProfile'
import JobSeekerNav from './JobSeekerNav'
import { useLoadScript, Autocomplete } from '@react-google-maps/api'
const googleLibraries = ['places']
const googleMapsApiKey = 'AIzaSyCrPAUAvwtCGCro8f8GXJw7neQ6a3T4Ox0'
// Colors
const colors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#CAAAE1',
  primaryHover: '#C859FF',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0',
  success: '#68d391',
  error: '#fc8181'
}

// Data Constants
const states = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
]

const pronounOptions = [
  'he/him',
  'she/her',
  'they/them',
  'prefer not to say',
  'other'
]

const educationLevels = [
  'Some high school or less',
  'High school diploma or equivalent',
  'Vocational certificate or credential',
  'Some college',
  'Associates degree',
  'Bachelors degree',
  'Masters degree',
  'PhD',
  'MD, OD or related',
  'DDS, DMD or related',
  'JD',
  'Other'
]

const experienceLevels = [
  'None/Student',
  '1-2 years',
  '3-5 years',
  '6-10 years',
  '11-15 years',
  '16+ years'
]

const currentLevels = [
  'Internship',
  'Entry Level',
  'Manager',
  'Director',
  'Head / Lead',
  'Vice President',
  'Senior Vice President',
  'C-Suite Executive'
]

const leagues = [
  'NCAA Division I (National Collegiate Athletics Association)',
  'NCAA Division II (National Collegiate Athletics Association)',
  'NCAA Division III (National Collegiate Athletics Association)',
  'NAIA (National Association of Intercollegiate Athletics)',
  'NJCAA (National Junior College Athletic Association)',
  'AHL (American Hockey League)',
  'APP (Association of Pickleball Professionals)',
  'ATP (Association of Tennis Professionals)',
  'AUDL (American Ultimate Disc League)',
  'ECHL (East Coast Hockey League)',
  'IFL (Indoor Football League)',
  'LOVB (League One Volleyball)',
  'LPGA (Ladies Professional Golf Association)',
  'MLB (Major League Baseball)',
  'MLL (Major League Lacrosse)',
  'MLP (Major League Pickleball)',
  'MLR (Major League Rugby)',
  'MLS (Major League Soccer)',
  'NBA (National Basketball Association)',
  'G League (NBA G League)',
  'NFL (National Football League)',
  'NHL (National Hockey League)',
  'NISA (National Independent Soccer Association)',
  'NLL (National Lacrosse League)',
  'NPF (National Pro Fastpitch)',
  'NPL (National Pickleball League)',
  "NWSL (National Women's Soccer League)",
  'PBA (Professional Bowlers Association)',
  'PBR (Professional Bull Riders)',
  'PGA (Professional Golf Association)',
  'PHF (Premier Hockey Federation)',
  'PLL (Premier Lacrosse League)',
  'PPA (Professional Pickleball Association)',
  'PVL (Pro Volleyball League)',
  "PWBA (Professional Women's Bowling Association)",
  'USL (United Soccer League)',
  "WFA (Women's Football Alliance)",
  "WNBA (Women's National Basketball Association)",
  "WPLL (Women's Professional Lacrosse League)",
  "WTA (Women's Tennis Association)",
  'XFL (Xtreme Football League)',
  'Olympic Team Member',
  'Olympic Trials Qualifier',
  'Olympic National Team Member',
  'Paralympic Team Member',
  'Paralympic National Team Member',
  'Paralympic Trials Qualifier',
  'Olympic Junior National Team',
  'Youth Olympic Games',
  'Other'
]

const achievements = [
  'All-Star',
  'MVP',
  'Championship',
  'Olympic Medalist',
  'All-League Selection',
  'All-American',
  'Conference Player of the Year',
  'Rookie of the Year',
  'Hall of Fame',
  'Other'
]

const strengths = [
  'Communication',
  'Leadership',
  'Problem Solving',
  'Teamwork',
  'Adaptability',
  'Creativity',
  'Time Management',
  'Critical Thinking',
  'Attention to Detail',
  'Emotional Intelligence',
  'Strategic Planning',
  'Networking',
  'Decision Making',
  'Conflict Resolution',
  'Analytical Skills',
  'Project Management',
  'Negotiation',
  'Public Speaking',
  'Customer Service',
  'Technical Proficiency',
  'Data Analysis',
  'Research',
  'Writing',
  'Multitasking',
  'Sales',
  'Marketing',
  'Budgeting',
  'Coaching',
  'Innovation',
  'Interpersonal Skills'
].sort()

const workPreferences = ['Fully Remote', 'In-Office', 'Hybrid']

const jobSearchChallenges = [
  {
    value: 'figuring_out_fit',
    label: 'Figuring out what jobs I would be a top candidate for'
  },
  { value: 'ghosting', label: 'Ghosting/not hearing back from recruiters' },
  { value: 'lack_of_jobs', label: 'Hard to find jobs/lack of jobs' },
  { value: 'no_offers', label: 'Interviewing but not getting offers' },
  { value: 'salary_transparency', label: 'Lack of salary transparency' },
  { value: 'networking', label: 'Network/getting in front of people' },
  {
    value: 'time_consuming',
    label: 'Time-consuming process/not having enough time'
  }
]

const industries = ['Sports', 'Media', 'Entertainment', 'Fashion', 'Technology']

const allSubcategories = {
  Sports: [
    'Professional Sports',
    'College Sports',
    'E-Sports',
    'Sports Technology',
    'Sports Marketing',
    'Sports Management',
    'Sports Medicine',
    'Fitness',
    'Outdoor Sports',
    'Recreational Sports',
    'Sports Analytics',
    'Youth Sports',
    'Athlete Representation',
    'Stadium Operations',
    'Event Management',
    'Sports Sponsorships',
    'Sports Broadcasting',
    'Fan Engagement',
    'Team Operations'
  ],
  Media: [
    'Broadcast Media',
    'Digital Media',
    'Publishing',
    'Advertising',
    'Journalism',
    'Social Media',
    'Streaming Services',
    'Podcasting',
    'Film Journalism',
    'Investigative Reporting',
    'Public Relations',
    'Content Creation',
    'Media Buying',
    'Media Planning',
    'News Production',
    'Multimedia Journalism',
    'Interactive Media',
    'Community Management',
    'Influencer Marketing'
  ],
  Entertainment: [
    'Film Production',
    'Music',
    'Gaming',
    'Live Events',
    'Theater',
    'Animation',
    'Virtual Reality',
    'Theme Parks',
    'Television Production',
    'Documentary Filmmaking',
    'Concert Promotion',
    'Talent Management',
    'Celebrity Management',
    'Film Distribution',
    'Cinematography',
    'Sound Design',
    'Set Design',
    'Voice Acting',
    'Content Distribution',
    'Entertainment Law',
    'Reality TV',
    'Music Production',
    'Film Scoring',
    'Comedy'
  ],
  Fashion: [
    'Luxury Fashion',
    'Streetwear',
    'Sportswear',
    'Fashion Technology',
    'Sustainable Fashion',
    'Accessories',
    'Cosmetics',
    'Fashion Media',
    'Apparel Design',
    'Textile Design',
    'Retail Fashion',
    'Fashion Merchandising',
    'Fashion Photography',
    'Fashion Blogging',
    'Runway Shows',
    'Fashion Buying',
    'E-commerce Fashion',
    'Fashion Consulting',
    'Pattern Making',
    'Fashion Illustration',
    'Costume Design',
    'Footwear Design',
    'Fashion PR',
    'Jewelry Design'
  ],
  Technology: ['Media Tech', 'Entertainment Tech', 'Fashion Tech']
}
const culturePreferenceOptions = [
  'Initiative & Self-Direction',
  'Collaboration',
  'Adaptability',
  'Integrity',
  'Innovation',
  'Growth Mindset',
  'Accountability',
  'Customer Focus'
].sort()

const genderOptions = [
  'Male',
  'Female',
  'Non-binary',
  'Transgender Male',
  'Transgender Female',
  'Prefer not to say'
]

const technicalSkillsOptions = [
  'Adobe Creative Suite',
  'Adobe XD',
  'Airtable',
  'Amplitude',
  'Applicant Tracking Systems/ATS',
  'Asana',
  'AutoCAD',
  'AWS',
  'Azure',
  'C++',
  'Calendly',
  'CI/CD',
  'ClickUp',
  'Confluence',
  'Docker',
  'Figma',
  'G Suite',
  'Git',
  'GitHub/GitLab',
  'Google Analytics',
  'HRIS Systems',
  'HubSpot',
  'Java',
  'JavaScript',
  'Jenkins',
  'Jira',
  'Kubernetes',
  'Linear',
  'Looker',
  'Microsoft Dynamics',
  'Microsoft Excel',
  'Microsoft PowerPoint',
  'Microsoft SharePoint',
  'Microsoft Teams',
  'Microsoft Word',
  'Miro',
  'Mixpanel',
  'Monday.com',
  'MongoDB',
  'MySQL',
  'NetSuite',
  'Node.js',
  'Notion',
  'Oracle',
  'Oracle ERP',
  'PostgreSQL',
  'Postman',
  'Python',
  'QuickBooks',
  'React.js',
  'Redis',
  'REST APIs',
  'Revit',
  'SAP',
  'Salesforce',
  'ServiceNow',
  'Sketch',
  'Slack',
  'SQL',
  'Tableau',
  'Trello',
  'TypeScript',
  'Unity',
  'Workday',
  'Zendesk',
  'Zoom',
  'Other'
]

const languageOptions = [
  'English',
  'Spanish',
  'Mandarin Chinese',
  'French',
  'German',
  'Japanese',
  'Russian',
  'Portuguese',
  'Arabic',
  'Hindi',
  'Italian',
  'Korean',
  'Dutch',
  'Turkish',
  'Swedish',
  'Polish',
  'Greek',
  'Vietnamese',
  'Hebrew',
  'Thai',
  'Indonesian',
  'Danish',
  'Finnish',
  'Norwegian',
  'Czech'
].sort()

const raceOptions = [
  'Asian',
  'Black',
  'Hispanic/Latino',
  'Middle Eastern/Arab',
  'Native American',
  'Pacific Islander',
  'White',
  'Prefer not to say'
]

const veteranStatusOptions = [
  'Veteran',
  'Active Duty',
  'Reservist',
  'Not A Veteran',
  'Prefer not to say'
]

const sexualOrientationOptions = [
  'Heterosexual/Straight',
  'Gay',
  'Lesbian',
  'Bisexual',
  'Pansexual',
  'Asexual',
  'Queer',
  'Prefer not to say'
]

const disabilityOptions = ['Yes', 'No', 'Prefer not to say']

// Styled Components
// Styled Components

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

  body {
    font-family: 'Open Sans', sans-serif;
  }
`

const softColors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#CAAAE1',
  secondary: '#f6e05e',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0',
  success: '#68d391',
  warning: '#f6ad55',
  danger: '#fc8181',
  info: '#63b3ed',
  icons: '#12C2E8',
  icontext: '#C859FF',
  yellow: '#f6e05e'
}

// Core Layout Components
const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: ${softColors.background};
  color: ${softColors.text};
  font-family: 'Open Sans', sans-serif;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
  height: 100vh;
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`

const FormCard = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background-color: ${softColors.card};
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

// Typography Components
const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: ${softColors.primary};
  margin-bottom: 1.5rem;
  text-align: center;
`

const SubTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${softColors.text};
  margin-bottom: 1.5rem;
`

const SectionTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${softColors.text};
  margin-bottom: 1rem;
  margin-top: 2rem;
`

const Note = styled.p`
  color: ${softColors.textLight};
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 2rem;
`

const HelperText = styled.p`
  color: ${softColors.textLight};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`

// Form Components
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`

const Label = styled.label`
  display: block;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: ${softColors.text};
  font-size: 0.9rem;
`

const RequiredLabel = styled.span`
  color: ${softColors.danger};
  margin-left: 0.25rem;
`

const Input = styled.input`
  width: 80%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid ${softColors.border};
  background-color: ${softColors.card};
  font-size: 1rem;
  color: ${softColors.text};
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: ${softColors.primary};
    box-shadow: 0 0 0 2px ${softColors.primary}33;
  }

  &::placeholder {
    color: ${softColors.textLight};
  }

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid ${softColors.border};
  background-color: ${softColors.card};
  font-size: 1rem;
  color: ${softColors.text};
  transition: all 0.2s;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${softColors.primary};
    box-shadow: 0 0 0 2px ${softColors.primary}33;
  }
`

// Grid and Layout Components
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Section = styled.div`
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid ${softColors.border};
`

// Checkbox and Radio Components
const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background-color: ${softColors.background};
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 0.1rem;

  &:hover {
    background-color: ${softColors.border};
  }

  @media (max-width: 768px) {
    font-size: 0.65rem;
    padding: 0.5rem;
  }
`

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 0.75rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  accent-color: ${softColors.primary};
`

const RadioGroup = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: all 0.2s;

  &:hover {
    background-color: ${softColors.background};
  }

  input[type='radio'] {
    accent-color: ${softColors.primary};
    width: 1.25rem;
    height: 1.25rem;
  }
`

// Button Components
const Button = styled.button`
  padding: 0.875rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  background-color: ${softColors.primary};
  color: white;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${softColors.icontext};
    transform: translateY(-1px);
  }

  &:disabled {
    background-color: ${softColors.border};
    cursor: not-allowed;
    transform: none;
  }
  @media (max-width: 768px) {
    padding: 0.5rem 0.875rem;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2rem;
`

const AddButton = styled(Button)`
  background-color: ${softColors.primary};

  &:hover {
    background-color: white;
    color: ${softColors.primary};
    font-weight: 700;
    border: 1px solid ${softColors.primary};
  }
`

const RemoveButton = styled(Button)`
  background-color: ${softColors.danger};

  &:hover {
    background-color: ${softColors.danger}dd;
  }
`

// Collapsible Components
const CollapsibleSection = styled.div`
  margin-bottom: 1.5rem;
  border: 1px solid ${softColors.border};
  border-radius: 0.5rem;
  overflow: hidden;
`

const CollapsibleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: ${softColors.background};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${softColors.border};
  }
`

const CollapsibleContent = styled.div`
  padding: ${(props) => (props.isOpen ? '1rem' : '0')};
  max-height: ${(props) => (props.isOpen ? 'none' : '0')};
  overflow-y: ${(props) => (props.isOpen ? 'auto' : 'hidden')};
  transition: all 0.3s ease-in-out;
  background-color: ${softColors.card};

  /* Add a subtle scrollbar style for better UX */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${softColors.background};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${softColors.border};
    border-radius: 4px;

    &:hover {
      background: ${softColors.textLight};
    }
  }

  /* Style for Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${softColors.border} ${softColors.background};
`

// Multi-select Components
const MultiSelect = styled.div`
  border: 1px solid ${softColors.border};
  border-radius: 0.5rem;
  padding: 1rem;
  max-height: 300px;
  overflow-y: auto;
  background-color: ${softColors.card};
  @media (max-width: 768px) {
    padding: 0.25rem;
  }
`

const BubbleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`

const Bubble = styled.div`
  background-color: ${(props) =>
    props.selected ? softColors.primary : softColors.background};
  color: ${(props) => (props.selected ? 'white' : softColors.text)};
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.875rem;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transition: all 0.2s;

  &:hover {
    background-color: ${(props) =>
      props.selected ? softColors.icontext : softColors.border};
  }
`

// File Upload Components
const DropzoneContainer = styled.div`
  border: 2px dashed ${softColors.border};
  border-radius: 0.5rem;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    border-color: ${softColors.primary};
    background-color: ${softColors.background};
  }
`

const FilePreview = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${softColors.background};
`

const PreviewImage = styled.img`
  max-width: 150px;
  max-height: 150px;
  border-radius: 0.5rem;
  margin: 0.5rem 0;
`

const FileInput = styled.input`
  display: none;
`

// Error Message Components
const ErrorMessage = styled.div`
  color: ${softColors.danger};
  background-color: ${softColors.danger}11;
  border: 1px solid ${softColors.danger};
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
  font-size: 0.875rem;
`

// Link Components
const LinkInput = styled(Input)`
  padding-left: 2.5rem;
  background-image: url('/link-icon.svg');
  background-repeat: no-repeat;
  background-position: 0.75rem center;
  background-size: 1rem;
`

// Progress Components
const ProgressBar = styled.div`
  width: 100%;
  height: 0.5rem;
  background-color: ${softColors.border};
  border-radius: 1rem;
  margin: 2rem 0;
  overflow: hidden;
`

const ProgressFill = styled.div`
  height: 100%;
  width: ${(props) => (props.progress / 12) * 100}%;
  background-color: ${softColors.primary};
  transition: width 0.3s ease;
`

const WorkHistoryCard = styled.div`
  border: 1px solid ${softColors.border};
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: ${softColors.background};
`

const FileUploadArea = styled.div`
  border: 2px dashed ${softColors.border};
  border-radius: 0.5rem;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  background-color: ${(props) =>
    props.isDragging ? softColors.background : 'transparent'};

  &:hover {
    border-color: ${softColors.primary};
    background-color: ${softColors.background};
  }
`

const FadeInFormGroup = styled(FormGroup)`
  animation: fadeIn 0.5s ease-in;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

// Add this Tooltip styled component for the tooltip behavior
const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
`

const TooltipText = styled.span`
  visibility: hidden;
  width: 240px;
  background-color: ${softColors.text};
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 0.5rem;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the text */
  left: 50%;
  margin-left: -120px;
  opacity: 0;
  transition: opacity 0.3s;

  /* Arrow */
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${softColors.text} transparent transparent transparent;
  }
`

const Textarea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid ${softColors.border};
  background-color: ${softColors.card};
  font-size: 1rem;
  color: ${softColors.text};
  transition: all 0.2s;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: ${softColors.primary};
    box-shadow: 0 0 0 2px ${softColors.primary}33;
  }

  &::placeholder {
    color: ${softColors.textLight};
  }
`

const IntroText = styled.div`
  color: ${softColors.text};
  line-height: 1.6;
  margin-bottom: 2rem;
  padding: 1.5rem;
  background-color: ${softColors.background};
  border-radius: 0.5rem;

  strong {
    color: ${softColors.primary};
  }
`

const MultiSelectContainer = styled.div`
  border: 1px solid ${softColors.border};
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: ${softColors.card};
  max-height: 200px;
  overflow-y: auto;
`

const SelectedCount = styled.span`
  color: ${softColors.textLight};
  font-size: 0.875rem;
  margin-top: 0.5rem;
  display: block;
`

const AnimatedFormGroup = styled(FormGroup)`
  max-height: ${(props) => (props.isVisible ? '500px' : '0')};
  opacity: ${(props) => (props.isVisible ? '1' : '0')};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  margin-bottom: ${(props) => (props.isVisible ? '1.5rem' : '0')};
`

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid ${softColors.border};
  background-color: ${softColors.card};
  font-size: 1rem;
  color: ${softColors.text};
  transition: all 0.2s;
  resize: vertical;
  min-height: 100px;
  font-family: inherit;

  &:focus {
    outline: none;
    border-color: ${softColors.primary};
    box-shadow: 0 0 0 2px ${softColors.primary}33;
  }

  &::placeholder {
    color: ${softColors.textLight};
  }
`

// JobseekerIntakeForm Component
const JobseekerIntake = () => {
  // State
  const [currentStep, setCurrentStep] = useState(1)
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [openSections, setOpenSections] = useState({})
  const autocompleteRef = useRef(null)
  const [workHistory, setWorkHistory] = useState([
    { title: '', employer: '', start_date: '', end_date: '', present: false }
  ])
  const [workEmail, setWorkEmail] = useState('')
  const [blockedCompanies, setBlockedCompanies] = useState([])

  const { isLoaded } = useLoadScript({
    googleMapsApiKey,
    libraries: googleLibraries
  })

  const [formData, setFormData] = useState({
    // Personal Info
    date_of_birth: '',
    pronouns: '',
    customPronouns: '',
    street_address: '',
    city: '',
    state: '',
    zip_code: '',
    phone: '',

    // Work History
    is_athlete: 'no',
    athletic_history: [],
    blocked_companies: [],
    work_history: [
      {
        title: '',
        employer: '',
        start_date: '',
        end_date: '',
        industry: [],
        company_type: [],
        job_function: [],
        other_job_function: ''
      }
    ],

    // Education
    highest_education: '',
    education_history: [
      {
        institution: '',
        degree_type: '',
        field_of_study: '',
        graduation_date: ''
      }
    ],
    // Experience
    years_experience: '',
    current_level: '',
    has_startup_experience: 'no',
    technical_skills: [],
    strengths: [],
    work_preference: '',
    job_search_challenge: '',
    culture_preferences: [],
    languages: [],
    industries: [],

    // Social Links
    social_links: {
      linkedin: '',
      gondola: '',
      portfolio: '',
      github: '',
      additional1: '',
      additional2: ''
    },

    // Files
    photo: null,
    photo_preview: null,
    resume: null,

    // Additional Info
    about: '',
    gender: '',
    race: [],
    sexual_orientation: '',
    veteran_status: '',
    disability_status: '',
    accommodation_needed: '',
    accommodation_details: '',
    opt_out: false
  })

  const [isLoading, setIsLoading] = useState(false)

  const toggleSection = (sectionId) => {
    setOpenSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId]
    }))
  }

  // Handlers
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleWorkHistoryChange = (index, field, value) => {
    const newWorkHistory = [...formData.work_history]

    if (field === 'present') {
      newWorkHistory[index] = {
        ...newWorkHistory[index],
        present: value,
        end_date: value ? null : newWorkHistory[index].end_date
      }
    } else {
      newWorkHistory[index] = {
        ...newWorkHistory[index],
        [field]: value === '' ? null : value
      }
    }

    setFormData({
      ...formData,
      work_history: newWorkHistory
    })
  }

  const addWorkHistory = () => {
    if (formData.work_history.length < 4) {
      setFormData({
        ...formData,
        work_history: [
          ...formData.work_history,
          {
            title: '',
            employer: '',
            start_date: '',
            end_date: '',
            industry: [],
            company_type: [],
            job_function: [],
            other_job_function: '',
            other_company_type: '',
            present: false,
            work_email: ''
          }
        ]
      })
    }
  }

  const removeWorkHistory = (index) => {
    const newWorkHistory = formData.work_history.filter((_, i) => i !== index)
    setFormData({
      ...formData,
      work_history: newWorkHistory
    })
  }

  const handleAthleteHistoryChange = (index, field, value) => {
    const newAthleteHistory = [...formData.athletic_history]
    newAthleteHistory[index] = {
      ...newAthleteHistory[index],
      [field]: value
    }
    setFormData({
      ...formData,
      athletic_history: newAthleteHistory
    })
  }

  const addAthleteHistory = () => {
    setFormData({
      ...formData,
      athletic_history: [
        ...formData.athletic_history,
        {
          sport: '',
          league: '',
          team: '',
          position: '',
          start_date: '',
          end_date: '',
          achievements: []
        }
      ]
    })
  }

  const handleAboutChange = (e) => {
    const value = e.target.value
    if (value.length <= 500) {
      setFormData({ ...formData, about: value })
    }
  }
  const removeAthleteHistory = (index) => {
    const newAthleteHistory = formData.athletic_history.filter(
      (_, i) => i !== index
    )
    setFormData({
      ...formData,
      athletic_history: newAthleteHistory
    })
  }

  const handleEducationHistoryChange = (index, field, value) => {
    const newEducationHistory = [...formData.education_history]
    newEducationHistory[index] = {
      ...newEducationHistory[index],
      [field]: value
    }
    setFormData({
      ...formData,
      education_history: newEducationHistory
    })
  }

  const addEducation = () => {
    if (formData.education_history.length < 3) {
      setFormData({
        ...formData,
        education_history: [
          ...formData.education_history,
          {
            institution: '',
            degree_type: '',
            field_of_study: '',
            graduation_date: '' // Add graduation_date field
          }
        ]
      })
    }
  }

  const removeEducation = (index) => {
    const newEducationHistory = formData.education_history.filter(
      (_, i) => i !== index
    )
    setFormData({
      ...formData,
      education_history: newEducationHistory
    })
  }

  const handlePresentToggle = (index) => {
    const updatedWorkHistory = [...workHistory]
    updatedWorkHistory[index].present = !updatedWorkHistory[index].present
    setWorkHistory(updatedWorkHistory)
  }

  const handleWorkEmailChange = (e) => {
    const email = e.target.value
    setWorkEmail(email)
    if (email.includes('@')) {
      const domain = email.split('@')[1]
      setBlockedCompanies([...blockedCompanies, domain])
    }
  }

  const handleIndustryChange = (industry) => {
    const newIndustries = formData.industries.includes(industry)
      ? formData.industries.filter((i) => i !== industry)
      : [...formData.industries, industry]

    const newSubcategories = { ...formData.subcategories }
    if (!newIndustries.includes(industry)) {
      delete newSubcategories[industry]
    }

    setFormData({
      ...formData,
      industries: newIndustries,
      subcategories: newSubcategories
    })
  }

  const handleSubcategoryChange = (industry, subcategory) => {
    const currentSubcategories = formData.subcategories[industry] || []
    const newSubcategories = currentSubcategories.includes(subcategory)
      ? currentSubcategories.filter((s) => s !== subcategory)
      : [...currentSubcategories, subcategory]

    setFormData({
      ...formData,
      subcategories: {
        ...formData.subcategories,
        [industry]: newSubcategories
      }
    })
  }

  const handleStrengthChange = (strength) => {
    setFormData((prev) => {
      const currentStrengths = [...prev.strengths]

      if (currentStrengths.includes(strength)) {
        return {
          ...prev,
          strengths: currentStrengths.filter((s) => s !== strength)
        }
      } else if (currentStrengths.length < 3) {
        return {
          ...prev,
          strengths: [...currentStrengths, strength]
        }
      }
      return prev
    })
  }

  // Add these new handler functions
  const handleCulturePreferenceChange = (preference) => {
    const newPreferences = formData.culture_preferences.includes(preference)
      ? formData.culture_preferences.filter((p) => p !== preference)
      : [...formData.culture_preferences]

    if (
      !formData.culture_preferences.includes(preference) &&
      newPreferences.length < 3
    ) {
      newPreferences.push(preference)
    }

    setFormData({
      ...formData,
      culture_preferences: newPreferences
    })
  }

  const handleLanguageChange = (language) => {
    const newLanguages = formData.languages.includes(language)
      ? formData.languages.filter((l) => l !== language)
      : [...formData.languages, language]

    setFormData({
      ...formData,
      languages: newLanguages
    })
  }

  const handleFileUpload = (type, file) => {
    const sanitizeFileName = (filename) =>
      filename.replace(/[^a-zA-Z0-9-_.]/g, '_')

    if (!auth.currentUser) {
      alert('You must be logged in to upload files.')
      return
    }

    if (type === 'photo' && file) {
      const validExtensions = ['jpg', 'jpeg', 'png', 'gif']
      const fileExtension = file.name.split('.').pop().toLowerCase()

      if (
        !file.type.startsWith('image/') ||
        !validExtensions.includes(fileExtension)
      ) {
        alert('Please upload a valid image file.')
        return
      }

      if (file.size > 2 * 1024 * 1024) {
        alert('File size exceeds 2MB. Please upload a smaller file.')
        return
      }

      const reader = new FileReader()
      reader.onloadend = async () => {
        try {
          const sanitizedFileName = sanitizeFileName(file.name)
          const photoPath = `profile-photos/${
            auth.currentUser.uid
          }/${Date.now()}.${sanitizedFileName.split('.').pop()}`
          const photoRef = ref(storage, photoPath)

          await uploadBytes(photoRef, file)
          const photoUrl = await getDownloadURL(photoRef)

          setFormData({
            ...formData,
            photo: file,
            photo_preview: reader.result,
            photo_url: photoUrl
          })
        } catch (error) {
          console.error('Error uploading photo:', error.message, error.stack)
          alert('Failed to upload photo. Please try again.')
        }
      }

      reader.onerror = () => {
        console.error('Error reading file:', reader.error)
        alert('Failed to read the file. Please try again.')
      }

      reader.readAsDataURL(file)
    } else if (type === 'resume' && file) {
      const validExtensions = ['pdf', 'doc', 'docx']
      const fileExtension = file.name.split('.').pop().toLowerCase()

      if (
        !(
          file.type === 'application/pdf' ||
          file.type === 'application/msword' ||
          file.type ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) ||
        !validExtensions.includes(fileExtension)
      ) {
        alert('Please upload a valid PDF or Word document.')
        return
      }

      if (file.size > 5 * 1024 * 1024) {
        alert('File size exceeds 5MB. Please upload a smaller file.')
        return
      }

      const reader = new FileReader()
      reader.onloadend = async () => {
        try {
          const sanitizedFileName = sanitizeFileName(file.name)
          const resumePath = `resumes/${
            auth.currentUser.uid
          }/${Date.now()}.${sanitizedFileName.split('.').pop()}`
          const resumeRef = ref(storage, resumePath)

          await uploadBytes(resumeRef, file)
          const resumeUrl = await getDownloadURL(resumeRef)

          setFormData({
            ...formData,
            resume: file,
            resume_url: resumeUrl
          })
        } catch (error) {
          console.error('Error uploading resume:', error.message, error.stack)
          alert('Failed to upload resume. Please try again.')
        }
      }

      reader.onerror = () => {
        console.error('Error reading file:', reader.error)
        alert('Failed to read the file. Please try again.')
      }

      reader.readAsDataURL(file)
    } else {
      alert(
        `Please upload a valid ${type === 'photo' ? 'image' : 'document'} file.`
      )
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace()
      if (place && place.formatted_address) {
        setFormData({
          ...formData,
          address: place.formatted_address
        })
      }
    }
  }

  const handleDrop = (type, e) => {
    e.preventDefault()
    e.stopPropagation()
    const file = e.dataTransfer.files[0]
    handleFileUpload(type, file)
  }

  const nextStep = () => {
    setCurrentStep((curr) => Math.min(curr + 1, 11))
  }

  const prevStep = () => {
    setCurrentStep((curr) => Math.max(curr - 1, 1))
  }

  const handleMultiSelectChange = (index, field, value) => {
    const updatedWorkHistory = [...formData.work_history]
    const selectedOptions = updatedWorkHistory[index][field] || []

    if (selectedOptions.includes(value)) {
      updatedWorkHistory[index][field] = selectedOptions.filter(
        (option) => option !== value
      )
    } else {
      updatedWorkHistory[index][field] = [...selectedOptions, value]
    }

    setFormData({ ...formData, work_history: updatedWorkHistory })
  }

  const handleTopLevelMultiSelectChange = (field, value) => {
    const selectedOptions = formData[field] || []

    // Toggle selection
    const updatedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((option) => option !== value)
      : [...selectedOptions, value]

    setFormData({ ...formData, [field]: updatedOptions })
  }

  const formatUrl = (url) => {
    if (!url) return null
    const trimmedUrl = url.trim()
    if (trimmedUrl === '') return null

    // Don't add https:// if it already has a protocol
    if (trimmedUrl.startsWith('http://') || trimmedUrl.startsWith('https://')) {
      return trimmedUrl
    }

    return `https://${trimmedUrl}`
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Move to the next step if not on the last step
    if (currentStep < 11) {
      nextStep()
      return
    }

    // Ensure required files are uploaded
    if (!formData.photo || !formData.resume) {
      alert('Please upload both a profile photo and resume')
      return
    }

    setIsLoading(true)
    let photoUrl = null
    let resumeUrl = null

    try {
      // Photo Upload Handling
      if (formData.photo) {
        const photoPath = `profile-photos/${
          auth.currentUser.uid
        }/${Date.now()}.${formData.photo.name.split('.').pop()}`
        const photoRef = ref(storage, photoPath)
        await uploadBytes(photoRef, formData.photo)
        photoUrl = await getDownloadURL(photoRef)
      }

      // Resume Upload Handling
      if (formData.resume) {
        const resumePath = `resumes/${
          auth.currentUser.uid
        }/${Date.now()}.${formData.resume.name.split('.').pop()}`
        const resumeRef = ref(storage, resumePath)
        await uploadBytes(resumeRef, formData.resume)
        resumeUrl = await getDownloadURL(resumeRef)
      }

      // Get work emails for blocked companies
      const blockedEmails = formData.work_history
        .filter((job) => job.work_email)
        .map((job) => job.work_email.split('@')[1])

      // Prepare data for Firestore
      const profileData = {
        intake_completed: true,
        demographics_completed: !formData.opt_out,

        // Personal Info & Location
        date_of_birth: formData.date_of_birth || null,
        pronouns:
          formData.pronouns === 'other'
            ? formData.customPronouns
            : formData.pronouns,

        address: formData.address || null, // Google Places address
        street_address: formData.street_address || null,
        city: formData.city || null,
        state: formData.state || null,
        zip_code: formData.zip_code || null,
        phone: formData.phone || null,

        // Work History & Blocked Companies
        blocked_companies: [
          ...new Set([...formData.blocked_companies, ...blockedEmails])
        ],
        work_history: formData.work_history.map((job) => ({
          title: job.title || null,
          employer: job.employer || null,
          start_date: job.start_date || null,
          end_date: job.end_date || null,
          present: job.present || false,
          work_email: job.work_email || null,
          industry: job.industry || [],
          company_type: job.company_type || [],
          job_function: job.job_function || [],
          other_job_function: job.job_function?.includes('Other (specify)')
            ? job.other_job_function
            : null,

          other_company_type: job.other_company_type || null
        })),

        // Athletic History
        athlete_status: formData.is_athlete === 'yes',
        athletic_history: formData.athletic_history.map((history) => ({
          sport: history.sport || null,
          league: history.league || null,
          team: history.team || null,
          position: history.position || null,
          start_date: history.start_date || null,
          end_date: history.end_date || null,
          achievements: history.achievements || []
        })),

        // Education
        highest_education: formData.highest_education || null,
        education_history: (formData.education_history || []).map((edu) => ({
          institution: edu.institution || null,
          degree_type: edu.degree_type || null,
          field_of_study: edu.field_of_study || null,
          graduation_date: edu.graduation_date || null
        })),

        // Experience & Skills
        years_experience: formData.years_experience || null,
        current_level: formData.current_level || null,
        startup_experience: formData.has_startup_experience === 'yes',
        technical_skills: formData.technical_skills || [],
        other_technical_skill: formData.other_technical_skill || null,

        // Industry Experience
        industries: formData.industries || [],
        subcategories: formData.subcategories || {},

        // Preferences and Strengths
        top_strengths: formData.strengths || [], // Map strengths directly
        work_preference: formData.work_preference || null, // Changed from preferred_telework_policy
        job_search_challenge: formData.job_search_challenge || null,
        culture_preferences: formData.culture_preferences || [], // Changed from company_culture_preferences
        languages: formData.languages || [],

        // Social Links
        social_links: {
          linkedin: formData.social_links?.linkedin || null,
          gondola: formData.social_links?.gondola || null,
          portfolio: formData.social_links?.portfolio || null,
          github: formData.social_links?.github || null,
          additional1: formData.social_links?.additional1 || null,
          additional2: formData.social_links?.additional2 || null
        },
        // Demographics
        gender: formData.gender || null,
        race: formData.race || [],
        sexual_orientation: formData.sexual_orientation || null,
        veteran_status: formData.veteran_status || null,
        disability_status: formData.disability_status || null,
        accommodation_needed: formData.accommodation_needed || null,
        accommodation_details: formData.accommodation_details || null,

        // Files and About
        about: formData.about || null,
        profile_picture: photoUrl || null,
        resume_url: resumeUrl || null,

        // Additional Info and Metadata
        block_current_employer: formData.block_current_employer || false,
        plan_type: 'freetrial',
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),

        // Extra fields that might be useful
        last_updated_by: auth.currentUser.uid,
        profile_completion_date: serverTimestamp()
      }

      // Log the profile data before submission (for debugging)

      // Submit to Firestore
      await createOrUpdateJobseekerProfile(auth.currentUser.uid, profileData)

      // Log success

      // Navigate to dashboard
      navigate('/jobseeker-dashboard')
    } catch (error) {
      console.error('Error in handleSubmit:', error)
      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }
  const handleRaceChange = (option) => {
    setFormData((prev) => {
      if (option === 'Prefer not to say') {
        return { ...prev, race: prev.race.includes(option) ? [] : [option] }
      }

      let newRace = prev.race.includes(option)
        ? prev.race.filter((item) => item !== option)
        : [...prev.race, option]

      newRace = newRace.filter(
        (item) => option === 'Prefer not to say' || item !== 'Prefer not to say'
      )

      return { ...prev, race: newRace }
    })
  }

  // Render step functions
  const renderPersonalInfo = () => (
    <Section>
      <SectionTitle>Personal Information</SectionTitle>

      <FormGroup>
        <Label htmlFor="pronouns">
          Pronouns <RequiredLabel>*</RequiredLabel>
        </Label>
        <Select
          id="pronouns"
          name="pronouns"
          value={formData.pronouns}
          onChange={(e) => {
            const value = e.target.value
            setFormData({
              ...formData,
              pronouns: value,
              // Clear custom pronouns if 'other' is not selected
              customPronouns: value !== 'other' ? '' : formData.customPronouns
            })
          }}
          required
        >
          <option value="">Select your pronouns</option>
          {pronounOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>

        {formData.pronouns === 'other' && (
          <FadeInFormGroup>
            <Input
              type="text"
              placeholder="Please specify your pronouns"
              value={formData.customPronouns || ''}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  customPronouns: e.target.value
                })
              }
              style={{ marginTop: '1rem' }}
              required
            />
          </FadeInFormGroup>
        )}
      </FormGroup>

      <FormGroup>
        <Label
          htmlFor="address"
          style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
        >
          Address <RequiredLabel>*</RequiredLabel>
          <TooltipContainer>
            <Info
              size={16}
              color={softColors.icontext}
              style={{ cursor: 'help' }}
            />
            <TooltipText className="tooltip-text">
              By providing your address, we'll connect you to relevant
              opportunities near you. Your full address will not be shown to
              employers
            </TooltipText>
          </TooltipContainer>
        </Label>
        {isLoaded ? (
          <Autocomplete
            onLoad={(ref) => (autocompleteRef.current = ref)}
            onPlaceChanged={handlePlaceChanged}
          >
            <Input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
              placeholder="Enter your address"
              required
            />
          </Autocomplete>
        ) : (
          <Input
            type="text"
            id="address"
            name="address"
            placeholder="Loading address field..."
            disabled
          />
        )}
      </FormGroup>

      <FormGroup>
        <Label htmlFor="phone">
          Phone Number <RequiredLabel>*</RequiredLabel>
        </Label>
        <Input
          type="tel"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="123-456-7890"
          required
        />
      </FormGroup>
    </Section>
  )

  const renderWorkHistory = () => (
    <Section>
      <SectionTitle>Professional Background</SectionTitle>

      <FormGroup>
        <Label>
          Are you currently or were you formerly a collegiate or professional
          athlete?
          <RequiredLabel>*</RequiredLabel>
        </Label>
        <RadioGroup>
          <RadioLabel>
            <input
              type="radio"
              name="is_athlete"
              value="yes"
              checked={formData.is_athlete === 'yes'}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  is_athlete: e.target.value,
                  athletic_history: e.target.value === 'yes' ? [{}] : []
                })
              }}
            />
            Yes
          </RadioLabel>
          <RadioLabel>
            <input
              type="radio"
              name="is_athlete"
              value="no"
              checked={formData.is_athlete === 'no'}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  is_athlete: e.target.value,
                  athletic_history: []
                })
              }}
            />
            No
          </RadioLabel>
        </RadioGroup>
      </FormGroup>

      {formData.is_athlete === 'yes' && (
        <>
          <SubTitle>Athletic History</SubTitle>
          {formData.athletic_history.map((history, index) => (
            <CollapsibleSection key={index}>
              <CollapsibleHeader
                onClick={() => toggleSection(`athletic-${index}`)}
              >
                <Label>
                  {history.sport || `Athletic History ${index + 1}`}
                </Label>
                {openSections[`athletic-${index}`] ? (
                  <ChevronUp />
                ) : (
                  <ChevronDown />
                )}
              </CollapsibleHeader>
              <CollapsibleContent isOpen={openSections[`athletic-${index}`]}>
                <FormGroup>
                  <Label>
                    Sport <RequiredLabel>*</RequiredLabel>
                  </Label>
                  <Input
                    type="text"
                    value={history.sport || ''}
                    onChange={(e) =>
                      handleAthleteHistoryChange(index, 'sport', e.target.value)
                    }
                    placeholder="What sport did/do you participate in?"
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label>
                    League <RequiredLabel>*</RequiredLabel>
                  </Label>
                  <Select
                    value={history.league || ''}
                    onChange={(e) =>
                      handleAthleteHistoryChange(
                        index,
                        'league',
                        e.target.value
                      )
                    }
                    required
                  >
                    <option value="">Select league</option>
                    {leagues.map((league) => (
                      <option key={league} value={league}>
                        {league}
                      </option>
                    ))}
                  </Select>
                </FormGroup>

                {/* Continue with other athletic history fields... */}
                {index > 0 && (
                  <RemoveButton
                    type="button"
                    onClick={() => removeAthleteHistory(index)}
                  >
                    Remove Athletic History
                  </RemoveButton>
                )}
              </CollapsibleContent>
            </CollapsibleSection>
          ))}

          <AddButton type="button" onClick={addAthleteHistory}>
            Add Another Athletic History
          </AddButton>
        </>
      )}

      <SubTitle>Work History</SubTitle>
      {formData.work_history.map((job, index) => (
        <CollapsibleSection key={index}>
          <CollapsibleHeader onClick={() => toggleSection(`work-${index}`)}>
            <Label>{job.title || `Job ${index + 1}`}</Label>
            {openSections[`work-${index}`] ? <ChevronUp /> : <ChevronDown />}
          </CollapsibleHeader>
          <CollapsibleContent isOpen={openSections[`work-${index}`]}>
            <GridContainer>
              <FormGroup>
                <Label>
                  Job Title <RequiredLabel>*</RequiredLabel>
                </Label>
                <Input
                  type="text"
                  value={job.title}
                  onChange={(e) =>
                    handleWorkHistoryChange(index, 'title', e.target.value)
                  }
                  placeholder="Job Title"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Employer <RequiredLabel>*</RequiredLabel>
                </Label>
                <Input
                  type="text"
                  value={job.employer}
                  onChange={(e) =>
                    handleWorkHistoryChange(index, 'employer', e.target.value)
                  }
                  placeholder="Employer"
                  required
                />
              </FormGroup>
            </GridContainer>

            <GridContainer>
              <FormGroup>
                <Label>
                  Start Date <RequiredLabel>*</RequiredLabel>
                </Label>
                <Input
                  type="date"
                  value={job.start_date}
                  style={{ fontFamily: 'Inter' }}
                  onChange={(e) =>
                    handleWorkHistoryChange(index, 'start_date', e.target.value)
                  }
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>End Date</Label>
                <Input
                  type="date"
                  value={job.end_date}
                  style={{ fontFamily: 'Inter' }}
                  onChange={(e) =>
                    handleWorkHistoryChange(index, 'end_date', e.target.value)
                  }
                  placeholder="Leave blank if current"
                  disabled={job.present}
                />
                <CheckboxLabel>
                  <Checkbox
                    checked={job.present || false}
                    onChange={() =>
                      handleWorkHistoryChange(index, 'present', !job.present)
                    }
                  />
                  <span>Present</span>
                </CheckboxLabel>
              </FormGroup>
            </GridContainer>

            {job.present && (
              <FadeInFormGroup>
                <Label>🔒 Privacy Options for Current Employer</Label>
                <HelperText>
                  Would you like to hide your profile from your current
                  employer? Enter your work email below to prevent them from
                  seeing your profile on Arena.
                </HelperText>
                <Input
                  type="email"
                  placeholder="Work email"
                  value={job.work_email || ''}
                  onChange={(e) =>
                    handleWorkHistoryChange(index, 'work_email', e.target.value)
                  }
                />
                <HelperText>
                  Why we need your work email: We use your work email domain to
                  automatically block access from our current employers using
                  the same email domain.
                  <br />
                  Important: While we take steps to protect your privacy, we
                  cannot guarantee complete privacy.
                </HelperText>
              </FadeInFormGroup>
            )}

            {/* Industry Multi-Select */}
            <CollapsibleSection>
              <CollapsibleHeader
                onClick={() => toggleSection(`industry-${index}`)}
              >
                <Label>Industry</Label>
                {openSections[`industry-${index}`] ? (
                  <ChevronUp />
                ) : (
                  <ChevronDown />
                )}
              </CollapsibleHeader>
              <CollapsibleContent isOpen={openSections[`industry-${index}`]}>
                <MultiSelect>
                  {[
                    'Entertainment',
                    'Fashion',
                    'Gaming',
                    'Health/Wellness',
                    'Media',
                    'Music',
                    'Sports',
                    'TV/Film'
                  ].map((industry) => (
                    <CheckboxLabel key={industry}>
                      <Checkbox
                        checked={job.industry?.includes(industry) || false}
                        onChange={() =>
                          handleMultiSelectChange(index, 'industry', industry)
                        }
                      />
                      <span>{industry}</span>
                    </CheckboxLabel>
                  ))}
                </MultiSelect>
              </CollapsibleContent>
            </CollapsibleSection>

            {/* Company Type Multi-Select */}
            <CollapsibleSection>
              <CollapsibleHeader
                onClick={() => toggleSection(`companyType-${index}`)}
              >
                <Label>Company Type</Label>
                {openSections[`companyType-${index}`] ? (
                  <ChevronUp />
                ) : (
                  <ChevronDown />
                )}
              </CollapsibleHeader>
              <CollapsibleContent isOpen={openSections[`companyType-${index}`]}>
                <MultiSelect>
                  {[
                    'Agency',
                    'Apparel Manufacturer',
                    'Audio/Podcasting',
                    'Broadcast Network',
                    'College/University',
                    'Collegiate Athletics',
                    'Department Stores/Retailers',
                    'Digital Media',
                    'DTC Brands',
                    'E-Commerce',
                    'Esports',
                    'Fast Fashion',
                    'Food/Beverage',
                    'Footwear',
                    'Gambling/Casino',
                    'Gym/Training',
                    'Jewelry/Accessories',
                    'Live Events',
                    'Luxury Fashion',
                    'Music Label',
                    'Nonprofit',
                    'Pro League',
                    'Pro Team',
                    'Publishing/Print Media',
                    'Recording Studio/Production',
                    'Social Media',
                    'Startup',
                    'Streaming Platform',
                    'Studios/Productions',
                    'Tech/Analytics',
                    'Theme Parks',
                    'Venue/Stadium',
                    'Video Games/AR/VR',
                    'Youth Sports',
                    'Other (specify)'
                  ].map((type) => (
                    <CheckboxLabel key={type}>
                      <Checkbox
                        checked={job.company_type?.includes(type) || false}
                        onChange={() =>
                          handleMultiSelectChange(index, 'company_type', type)
                        }
                      />
                      <span>{type}</span>
                    </CheckboxLabel>
                  ))}
                </MultiSelect>
                {job.company_type?.includes('Other (specify)') && (
                  <Input
                    type="text"
                    placeholder="Specify other company type"
                    onChange={(e) =>
                      handleWorkHistoryChange(
                        index,
                        'other_company_type',
                        e.target.value
                      )
                    }
                  />
                )}
              </CollapsibleContent>
            </CollapsibleSection>

            {/* Job Function Multi-Select */}
            <CollapsibleSection>
              <CollapsibleHeader
                onClick={() => toggleSection(`jobFunction-${index}`)}
              >
                <Label>Job Function</Label>
                {openSections[`jobFunction-${index}`] ? (
                  <ChevronUp />
                ) : (
                  <ChevronDown />
                )}
              </CollapsibleHeader>
              <CollapsibleContent isOpen={openSections[`jobFunction-${index}`]}>
                <MultiSelect>
                  {[
                    'Accounting',
                    'Account Management',
                    'Advertising',
                    'Animation/Visual Effects',
                    'Athlete',
                    'Athletic Training/Medical',
                    'Broadcasting/On-Air Talent',
                    'Business Development',
                    'Casting',
                    'Celebrity/Athlete Representation',
                    'Choreography',
                    'Communications',
                    'Community Relations/Philanthropy',
                    'Creative/Design',
                    'Customer Experience',
                    'Cybersecurity',
                    'Data Analytics',
                    'Diversity, Equity & Inclusion',
                    'Editorial/Journalism',
                    'Events/Hospitality',
                    'Facility/Venue Operations',
                    'Fan Engagement',
                    'Finance',
                    'Human Resources',
                    'Information Technology',
                    'Instructor/Teacher',
                    'Legal/Compliance',
                    'Licensing/Merchandise',
                    'Marketing',
                    'Mental Health/Wellness',
                    'Nutrition/Dietician',
                    'Partnerships/Sponsorships',
                    'Performer',
                    'Photo/Video',
                    'Player Development',
                    'Private Equity/Venture Capital',
                    'Production',
                    'Product Management',
                    'Public Relations',
                    'Referee/Umpire',
                    'Retail Buying',
                    'Sales',
                    'Scouting/Player Personnel',
                    'Security',
                    'Social Media/Content Creation',
                    'Software Engineering',
                    'Sports Coach',
                    'Stage Management',
                    'Strategy',
                    'Operations',
                    'Supply Chain/Inventory',
                    'Team Operations',
                    'User Experience',
                    'Voice/Acting Coach',
                    'Web Development',
                    'Other (specify)'
                  ].map((functionRole) => (
                    <CheckboxLabel key={functionRole}>
                      <Checkbox
                        checked={
                          job.job_function?.includes(functionRole) || false
                        }
                        onChange={() =>
                          handleMultiSelectChange(
                            index,
                            'job_function',
                            functionRole
                          )
                        }
                      />
                      <span>{functionRole}</span>
                    </CheckboxLabel>
                  ))}
                </MultiSelect>

                {job.job_function?.includes('Other (specify)') && (
                  <FadeInFormGroup>
                    <Input
                      type="text"
                      placeholder="Please specify your job function"
                      value={job.other_job_function || ''}
                      onChange={(e) =>
                        handleWorkHistoryChange(
                          index,
                          'other_job_function',
                          e.target.value
                        )
                      }
                      style={{ marginTop: '1rem' }}
                      required
                    />
                  </FadeInFormGroup>
                )}
              </CollapsibleContent>
            </CollapsibleSection>

            {index > 0 && (
              <RemoveButton
                type="button"
                onClick={() => removeWorkHistory(index)}
              >
                Remove Job
              </RemoveButton>
            )}
          </CollapsibleContent>
        </CollapsibleSection>
      ))}

      {formData.work_history.length < 4 && (
        <AddButton type="button" onClick={addWorkHistory}>
          Add Another Job
        </AddButton>
      )}
    </Section>
  )

  const renderEducation = () => (
    <>
      <FormGroup>
        <Label>Highest Level of Education</Label>
        <Select
          name="highest_education"
          value={formData.highest_education}
          onChange={handleChange}
          required
        >
          <option value="">Select education level</option>
          {educationLevels.map((level) => (
            <option key={level} value={level}>
              {level}
            </option>
          ))}
        </Select>
      </FormGroup>

      {formData.education_history.map((edu, index) => (
        <WorkHistoryCard key={index}>
          <FormGroup>
            <Label>Institution Name</Label>
            <Input
              type="text"
              value={edu.institution}
              onChange={(e) =>
                handleEducationHistoryChange(
                  index,
                  'institution',
                  e.target.value
                )
              }
              placeholder="Institution name"
            />
          </FormGroup>

          <GridContainer>
            <FormGroup>
              <Label>Degree Type</Label>
              <Input
                type="text"
                value={edu.degree_type}
                onChange={(e) =>
                  handleEducationHistoryChange(
                    index,
                    'degree_type',
                    e.target.value
                  )
                }
                placeholder="e.g., BA, BS, MA"
              />
            </FormGroup>

            <FormGroup>
              <Label>Field of Study</Label>
              <Input
                type="text"
                value={edu.field_of_study}
                onChange={(e) =>
                  handleEducationHistoryChange(
                    index,
                    'field_of_study',
                    e.target.value
                  )
                }
                placeholder="Major/Concentration"
              />
            </FormGroup>

            <FormGroup>
              <Label>Graduation Date</Label>
              <Input
                type="month"
                value={edu.graduation_date || ''}
                onChange={(e) =>
                  handleEducationHistoryChange(
                    index,
                    'graduation_date',
                    e.target.value
                  )
                }
                placeholder="Select graduation date"
              />
            </FormGroup>
          </GridContainer>

          {index > 0 && (
            <RemoveButton type="button" onClick={() => removeEducation(index)}>
              Remove Education
            </RemoveButton>
          )}
        </WorkHistoryCard>
      ))}

      {formData.education_history.length < 3 && (
        <AddButton type="button" onClick={addEducation}>
          Add Another Education
        </AddButton>
      )}
    </>
  )
  const renderExperience = () => (
    <>
      <FormGroup>
        <Label>Years of Work Experience</Label>
        <Select
          name="years_experience"
          value={formData.years_experience || ''}
          onChange={handleChange}
          required
        >
          <option value="">Select experience level</option>
          {experienceLevels.map((level) => (
            <option key={level} value={level}>
              {level}
            </option>
          ))}
        </Select>
      </FormGroup>

      <FormGroup>
        <Label>Current Career Level</Label>
        <Select
          name="current_level"
          value={formData.current_level || ''}
          onChange={handleChange}
        >
          <option value="">Select current level</option>
          {currentLevels.map((level) => (
            <option key={level} value={level}>
              {level}
            </option>
          ))}
        </Select>
      </FormGroup>

      <FormGroup>
        <Label>Do you have startup experience?</Label>
        <RadioGroup>
          <RadioLabel>
            <input
              type="radio"
              name="has_startup_experience"
              value="yes"
              checked={formData.has_startup_experience === 'yes'}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  has_startup_experience: e.target.value
                }))
              }
            />
            <span>Yes</span>
          </RadioLabel>
          <RadioLabel>
            <input
              type="radio"
              name="has_startup_experience"
              value="no"
              checked={formData.has_startup_experience === 'no'}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  has_startup_experience: e.target.value
                }))
              }
            />
            <span>No</span>
          </RadioLabel>
        </RadioGroup>
      </FormGroup>
    </>
  )

  const renderGetToKnowYou = () => (
    <>
      <FormGroup>
        <Label>
          Select Your Top Three Strengths{' '}
          <span style={{ color: colors.error }}>*</span>
        </Label>
        <MultiSelect>
          <CheckboxGroup>
            {strengths.map((strength) => (
              <CheckboxLabel key={strength}>
                <Checkbox
                  checked={formData.strengths.includes(strength)}
                  onChange={() => handleStrengthChange(strength)}
                  disabled={
                    !formData.strengths.includes(strength) &&
                    formData.strengths.length >= 3
                  }
                />
                <span>{strength}</span>
              </CheckboxLabel>
            ))}
          </CheckboxGroup>
        </MultiSelect>
        <HelperText>Selected: {formData.strengths.length}/3</HelperText>
      </FormGroup>

      <FormGroup>
        <Label>
          How do you prefer to work?{' '}
          <span style={{ color: colors.error }}>*</span>
        </Label>
        <Select
          name="work_preference"
          value={formData.work_preference}
          onChange={handleChange}
          required
        >
          <option value="">Select work preference</option>
          {workPreferences.map((pref) => (
            <option key={pref} value={pref}>
              {pref}
            </option>
          ))}
        </Select>
      </FormGroup>

      <FadeInFormGroup>
        <Label>
          What has been the #1 most challenging part of your job search so far?{' '}
          <span style={{ color: colors.error }}>*</span>
        </Label>
        <Select
          name="job_search_challenge"
          value={formData.job_search_challenge}
          onChange={handleChange}
          required
        >
          <option value="">Select a challenge</option>
          {jobSearchChallenges.map((challenge) => (
            <option key={challenge.value} value={challenge.value}>
              {challenge.label}
            </option>
          ))}
        </Select>
      </FadeInFormGroup>
    </>
  )

  const renderCultureAndLanguages = () => (
    <>
      <FormGroup>
        <Label>
          Select up to three options that best reflect the core values you look
          for in an employer.
          <span style={{ color: colors.error }}>*</span>
        </Label>
        <MultiSelect>
          <CheckboxGroup>
            {culturePreferenceOptions.map((preference) => (
              <CheckboxLabel key={preference}>
                <Checkbox
                  checked={formData.culture_preferences.includes(preference)}
                  onChange={() => handleCulturePreferenceChange(preference)}
                  disabled={
                    !formData.culture_preferences.includes(preference) &&
                    formData.culture_preferences.length >= 3
                  }
                />
                <span>{preference}</span>
              </CheckboxLabel>
            ))}
          </CheckboxGroup>
        </MultiSelect>
        <HelperText>
          Selected: {formData.culture_preferences.length}/3
        </HelperText>
      </FormGroup>

      <FormGroup>
        <Label>
          Languages You Speak <span style={{ color: colors.error }}>*</span>
        </Label>
        <MultiSelect>
          <CheckboxGroup>
            {languageOptions.map((language) => (
              <CheckboxLabel key={language}>
                <Checkbox
                  checked={formData.languages.includes(language)}
                  onChange={() => handleLanguageChange(language)}
                />
                <span>{language}</span>
              </CheckboxLabel>
            ))}
          </CheckboxGroup>
        </MultiSelect>
      </FormGroup>
    </>
  )

  const renderPersonalLinks = () => (
    <>
      <FormGroup>
        <Label>LinkedIn Profile</Label>
        <LinkInput
          type="text"
          name="linkedin"
          value={formData.social_links.linkedin}
          onChange={(e) =>
            setFormData({
              ...formData,
              social_links: {
                ...formData.social_links,
                linkedin: e.target.value
              }
            })
          }
        />
      </FormGroup>
      <FormGroup>
        <Label>Gondola Profile</Label>
        <LinkInput
          type="text"
          name="gondola"
          value={formData.social_links.gondola}
          onChange={(e) =>
            setFormData({
              ...formData,
              social_links: {
                ...formData.social_links,
                gondola: e.target.value
              }
            })
          }
        />
      </FormGroup>

      <FormGroup>
        <Label>Portfolio Website</Label>
        <LinkInput
          type="text"
          name="portfolio"
          value={formData.social_links.portfolio}
          onChange={(e) =>
            setFormData({
              ...formData,
              social_links: {
                ...formData.social_links,
                portfolio: e.target.value
              }
            })
          }
        />
      </FormGroup>

      <FormGroup>
        <Label>GitHub Profile</Label>
        <LinkInput
          type="text"
          name="github"
          value={formData.social_links.github}
          onChange={(e) =>
            setFormData({
              ...formData,
              social_links: { ...formData.social_links, github: e.target.value }
            })
          }
        />
      </FormGroup>

      <FormGroup>
        <Label>Additional Website 1</Label>
        <LinkInput
          type="text"
          name="additional1"
          value={formData.social_links.additional1}
          onChange={(e) =>
            setFormData({
              ...formData,
              social_links: {
                ...formData.social_links,
                additional1: e.target.value
              }
            })
          }
        />
      </FormGroup>

      <FormGroup>
        <Label>Additional Website 2</Label>
        <LinkInput
          type="text"
          name="additional2"
          value={formData.social_links.additional2}
          onChange={(e) =>
            setFormData({
              ...formData,
              social_links: {
                ...formData.social_links,
                additional2: e.target.value
              }
            })
          }
        />
      </FormGroup>
    </>
  )

  const renderUploads = () => (
    <>
      <FormGroup>
        <Label>
          Profile Photo <span style={{ color: colors.error }}>*</span>
        </Label>
        <FileUploadArea
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop('photo', e)}
          onClick={() => document.getElementById('photo-upload').click()}
          isDragging={false}
        >
          <p>Drag and drop your photo here, or click to select</p>
          <HelperText>
            Accepted formats: JPG, PNG, GIF (Max size: 5MB)
          </HelperText>
          {formData.photo_preview && (
            <PreviewImage src={formData.photo_preview} alt="Preview" />
          )}
        </FileUploadArea>
        <FileInput
          id="photo-upload"
          type="file"
          accept="image/*"
          onChange={(e) => handleFileUpload('photo', e.target.files[0])}
        />
      </FormGroup>

      <FormGroup>
        <Label>
          Resume <span style={{ color: colors.error }}>*</span>
        </Label>
        <FileUploadArea
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop('resume', e)}
          onClick={() => document.getElementById('resume-upload').click()}
          isDragging={false}
        >
          <p>Drag and drop your resume here, or click to select</p>
          <HelperText>
            Accepted formats: PDF, DOC, DOCX (Max size: 10MB)
          </HelperText>
          {formData.resume && <p>Selected file: {formData.resume.name}</p>}
        </FileUploadArea>
        <FileInput
          id="resume-upload"
          type="file"
          accept=".pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          onChange={(e) => handleFileUpload('resume', e.target.files[0])}
        />
      </FormGroup>
    </>
  )

  const renderDemographics = () => (
    <>
      <Title>Demographics Information</Title>

      <Section>
        <FormGroup>
          <Label>
            What is your gender identity? <RequiredLabel>*</RequiredLabel>
          </Label>
          <Select
            value={formData.gender}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, gender: e.target.value }))
            }
            required
          >
            <option value="">Select gender identity</option>
            {genderOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </FormGroup>
      </Section>

      <Section>
        <FormGroup>
          <Label>
            What is your racial/ethnic background? (Select all that apply){' '}
            <RequiredLabel>*</RequiredLabel>
          </Label>
          <MultiSelectContainer>
            {raceOptions.map((option) => (
              <CheckboxLabel key={option}>
                <input
                  type="checkbox"
                  checked={formData.race.includes(option)}
                  onChange={() => handleRaceChange(option)}
                  disabled={
                    option !== 'Prefer not to say' &&
                    formData.race.includes('Prefer not to say')
                  }
                  required={formData.race.length === 0}
                />
                {option}
              </CheckboxLabel>
            ))}
          </MultiSelectContainer>
          <SelectedCount>{formData.race.length} selected</SelectedCount>
        </FormGroup>
      </Section>

      <Section>
        <FormGroup>
          <Label>
            What is your sexual orientation? <RequiredLabel>*</RequiredLabel>
          </Label>
          <Select
            value={formData.sexual_orientation}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                sexual_orientation: e.target.value
              }))
            }
            required
          >
            <option value="">Select sexual orientation</option>
            {sexualOrientationOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </FormGroup>
      </Section>

      <Section>
        <FormGroup>
          <Label>
            What is your veteran status? <RequiredLabel>*</RequiredLabel>
          </Label>
          <Select
            value={formData.veteran_status}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                veteran_status: e.target.value
              }))
            }
            required
          >
            <option value="">Select veteran status</option>
            {veteranStatusOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </FormGroup>
      </Section>

      <Section>
        <FormGroup>
          <Label>
            Do you identify as a person with a disability?{' '}
            <RequiredLabel>*</RequiredLabel>
          </Label>
          <Select
            value={formData.disability_status}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                disability_status: e.target.value,
                ...(e.target.value !== 'Yes' && {
                  accommodation_needed: '',
                  accommodation_details: ''
                })
              }))
            }
            required
          >
            <option value="">Select option</option>
            {disabilityOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </FormGroup>

        {formData.disability_status === 'Yes' && (
          <AnimatedFormGroup isVisible>
            <Label>
              Do you require any accommodations in an interview process?{' '}
              <RequiredLabel>*</RequiredLabel>
            </Label>
            <RadioGroup>
              <RadioLabel>
                <input
                  type="radio"
                  name="accommodation"
                  value="yes"
                  checked={formData.accommodation_needed === 'yes'}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      accommodation_needed: e.target.value,
                      ...(e.target.value === 'no' && {
                        accommodation_details: ''
                      })
                    }))
                  }
                  required
                />
                Yes
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name="accommodation"
                  value="no"
                  checked={formData.accommodation_needed === 'no'}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      accommodation_needed: e.target.value,
                      accommodation_details: ''
                    }))
                  }
                  required
                />
                No
              </RadioLabel>
            </RadioGroup>

            {formData.accommodation_needed === 'yes' && (
              <AnimatedFormGroup isVisible>
                <Label>
                  Please describe any accommodations you need:{' '}
                  <RequiredLabel>*</RequiredLabel>
                </Label>
                <TextArea
                  value={formData.accommodation_details || ''}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      accommodation_details: e.target.value
                    }))
                  }
                  placeholder="Please describe any specific accommodations that would help you in an interview process (e.g., accessible building entrance, specialized communication tools)"
                  rows={4}
                  required
                />
              </AnimatedFormGroup>
            )}
          </AnimatedFormGroup>
        )}
      </Section>
    </>
  )

  const renderAboutMe = () => (
    <Section>
      <SectionTitle>About Me</SectionTitle>
      <FormGroup>
        <Label htmlFor="about">
          Share a brief bio about yourself to employers who view your profile
        </Label>
        <Textarea
          id="about"
          name="about"
          rows="5"
          style={{ fontFamily: 'Inter' }}
          value={formData.about || ''}
          onChange={(e) => setFormData({ ...formData, about: e.target.value })}
          placeholder="Write a short bio about yourself..."
        />
        <HelperText>Max 500 characters</HelperText>
      </FormGroup>
    </Section>
  )
  const renderTechnicalSkills = () => (
    <Section>
      <FormGroup>
        <Label>Select Your Technical Skills</Label>
        <MultiSelect>
          <CheckboxGroup>
            {technicalSkillsOptions.map((skill) => (
              <CheckboxLabel key={skill}>
                <Checkbox
                  checked={formData.technical_skills?.includes(skill) || false}
                  onChange={() =>
                    handleTopLevelMultiSelectChange('technical_skills', skill)
                  }
                />

                <span>{skill}</span>
              </CheckboxLabel>
            ))}
          </CheckboxGroup>
          {formData.technical_skills?.includes('Other') && (
            <Input
              type="text"
              placeholder="Specify other technical skills"
              value={formData.other_technical_skill || ''}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  other_technical_skill: e.target.value
                })
              }
            />
          )}
        </MultiSelect>
      </FormGroup>
    </Section>
  )

  // Step 10: Initial Demographics Opt-In
  const renderStep10 = () => (
    <>
      <Title>Demographic Information</Title>
      <IntroText>
        Why we ask these demographic questions: Arena is committed to promoting
        equity in sports, media, and entertainment. Your responses help
        employers understand their applicant pools and create more inclusive
        workplaces for all.
        <br />
        <br />
        Your demographic information may help employers find your profile. Every
        question has a "prefer not to say" option. Your responses will not
        affect your chances of getting matched for opportunities.
        <br />
        <br />
        Note: Your answers will not be displayed on your public profile.
      </IntroText>
    </>
  )

  // Step 11: Personal Demographics
  const renderStep11 = () => (
    <>
      <Title>Personal Demographics</Title>
      <Section>
        <FormGroup>
          <Label>What is your gender identity?</Label>
          <Select
            value={formData.gender}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, gender: e.target.value }))
            }
          >
            <option value="">Select gender identity</option>
            {genderOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </FormGroup>
      </Section>

      <Section>
        <FormGroup>
          <Label>
            What is your racial/ethnic background? (Select all that apply)
          </Label>
          <MultiSelectContainer>
            {raceOptions.map((option) => (
              <CheckboxLabel key={option}>
                <input
                  type="checkbox"
                  checked={formData.race.includes(option)}
                  onChange={() => handleRaceChange(option)}
                  disabled={
                    option !== 'Prefer not to say' &&
                    formData.race.includes('Prefer not to say')
                  }
                />
                {option}
              </CheckboxLabel>
            ))}
          </MultiSelectContainer>
          <SelectedCount>{formData.race.length} selected</SelectedCount>
        </FormGroup>
      </Section>

      <Section>
        <FormGroup>
          <Label>What is your sexual orientation?</Label>
          <Select
            value={formData.sexual_orientation}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                sexual_orientation: e.target.value
              }))
            }
          >
            <option value="">Select sexual orientation</option>
            {sexualOrientationOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        </FormGroup>
      </Section>
    </>
  )

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return renderPersonalInfo()
      case 2:
        return renderWorkHistory()
      case 3:
        return renderEducation()
      case 4:
        return renderExperience()
      case 5:
        return renderGetToKnowYou()
      case 6:
        return (
          <>
            {renderCultureAndLanguages()}
            {renderTechnicalSkills()}
          </>
        )

      case 7:
        return renderPersonalLinks()
      case 8:
        return renderAboutMe()
      case 9:
        return renderUploads()
      case 10:
        return renderStep10()
      case 11:
        return renderDemographics()
      default:
        return null
    }
  }

  return (
    <Container>
      <GlobalStyle />
      <NavWrapper>
        <JobSeekerNav />
      </NavWrapper>
      <MainContent>
        <FormCard>
          <Title>Let's Build Your Profile..</Title>
          <ProgressBar>
            <ProgressFill progress={currentStep} />
          </ProgressBar>
          <Note>Step {currentStep} of 11</Note>

          <Form onSubmit={handleSubmit}>
            {renderStep()}

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

            <ButtonGroup>
              {currentStep > 1 && (
                <Button type="button" onClick={prevStep}>
                  Previous
                </Button>
              )}
              <Button type="submit" disabled={isLoading}>
                {currentStep === 11
                  ? isLoading
                    ? 'Submitting...'
                    : 'Submit'
                  : 'Next'}
              </Button>
            </ButtonGroup>
          </Form>
        </FormCard>
      </MainContent>
    </Container>
  )
}

export default JobseekerIntake
