// src/models/coupon.js

import { db } from '../firebase'
import { collection, doc, setDoc, getDoc, Timestamp } from 'firebase/firestore'

// Function to create initial coupon codes
export const initializeCoupons = async () => {
  try {
    // Arena30 Coupon
    await setDoc(doc(db, 'coupons', 'arena30'), {
      code: 'ARENA30',
      expirationDate: Timestamp.fromDate(new Date('2024-11-05')), // One year from now
      trialDays: 30,
      jobPostLimit: -1, // -1 means unlimited
      planType: 'premium_trial',
      description: '30 days unlimited jobs trial',
      isFixedEndDate: false,
      isActive: true,
      createdAt: Timestamp.now()
    })

    // ArenaLaunch Coupon
    await setDoc(doc(db, 'coupons', 'arenalaunch'), {
      code: 'ARENALAUNCH',
      expirationDate: Timestamp.fromDate(new Date('2023-11-12')),
      fixedEndDate: Timestamp.fromDate(new Date('2023-11-26')),
      jobPostLimit: 5,
      planType: 'launch_trial',
      description: 'Launch offer - 5 jobs until Nov 26',
      isFixedEndDate: true,
      isActive: true,
      createdAt: Timestamp.now()
    })
  } catch (error) {
    console.error('Error initializing coupons:', error)
    throw error
  }
}

// Function to validate a coupon code
export const validateCoupon = async (code) => {
  try {
    const couponRef = doc(db, 'coupons', code.toLowerCase())
    const couponDoc = await getDoc(couponRef)

    if (!couponDoc.exists()) {
      return { isValid: false, error: 'Invalid coupon code' }
    }

    const couponData = couponDoc.data()
    const now = new Date()

    if (!couponData.isActive) {
      return { isValid: false, error: 'This coupon code is no longer active' }
    }

    if (now > couponData.expirationDate.toDate()) {
      return { isValid: false, error: 'This coupon has expired' }
    }

    return {
      isValid: true,
      ...couponData
    }
  } catch (error) {
    console.error('Error validating coupon:', error)
    return { isValid: false, error: 'Error validating coupon' }
  }
}

export const getTrialDetails = async (couponCode) => {
  // Handle case with no coupon code
  if (!couponCode) {
    const now = new Date()
    return {
      trialEnd: Timestamp.fromDate(
        new Date(now.getTime() + 14 * 24 * 60 * 60 * 1000)
      ),
      jobPostLimit: 1,
      planType: 'trial',
      couponUsed: null
    }
  }

  try {
    // Don't modify the coupon code case - use it exactly as entered
    const couponRef = doc(db, 'coupons', couponCode)
    const couponSnap = await getDoc(couponRef)

    if (!couponSnap.exists()) {
      // Try uppercase version as fallback
      const uppercaseCouponRef = doc(db, 'coupons', couponCode.toUpperCase())
      const uppercaseCouponSnap = await getDoc(uppercaseCouponRef)

      if (!uppercaseCouponSnap.exists()) {
        const error = new Error('Coupon not found')
        error.code = 'coupon/not-found'
        throw error
      }

      // If we found it with uppercase, use that data
      return processCouponData(
        uppercaseCouponSnap.data(),
        couponCode.toUpperCase()
      )
    }

    // Process the coupon data with original case
    return processCouponData(couponSnap.data(), couponCode)
  } catch (error) {
    console.error('Error in getTrialDetails:', error)
    if (error.code === 'coupon/not-found' || error.code === 'coupon/expired') {
      throw error
    }
    const genericError = new Error('Error validating coupon')
    genericError.code = 'coupon/error'
    throw genericError
  }
}

// Helper function to process coupon data
const processCouponData = (couponData, couponCode) => {
  const now = new Date()
  if (now > couponData.expirationDate.toDate()) {
    const error = new Error('Coupon is expired')
    error.code = 'coupon/expired'
    throw error
  }

  // Calculate trial end based on isFixedEndDate flag
  let trialEnd
  if (couponData.isFixedEndDate) {
    trialEnd = couponData.fixedEndDate
  } else {
    trialEnd = Timestamp.fromDate(
      new Date(now.getTime() + couponData.trialDays * 24 * 60 * 60 * 1000)
    )
  }

  const trialDetails = {
    trialEnd,
    jobPostLimit: couponData.jobPostLimit,
    planType: 'trial',
    couponUsed: couponCode // Use the actual code that was found
  }

  return trialDetails
}
