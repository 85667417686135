import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { updateJobApplication } from '../../models/JobApplication'
import {
  Mail,
  MapPin,
  Linkedin,
  Github,
  FileText,
  GraduationCap,
  Briefcase,
  TrophyIcon,
  ArrowLeft,
  LineChart,
  Users,
  Globe,
  Clock,
  Upload,
  Heart,
  X
} from 'lucide-react'
import EmployerNav from './EmployerNav'
import { getJobseekerProfile } from '../../models/JobSeekerProfile'
import { getUserById } from '../../models/User'
import { auth, db } from '../../firebase'
import { doc, getDoc } from 'firebase/firestore'
import { getJobApplication } from '../../models/JobApplication'

import {
  addPipeline,
  addJobSeekerToPipeline,
  getPipelinesFromFirestore
} from '../../models/EmployerProfile'

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
`

const NavbarWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
`

const PageContainer = styled.div`
  flex-grow: 1;
  background-color: #f3f4f6;
  overflow-y: auto;
  padding: 1rem;
`

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 30px auto;
  padding: 2rem;
  position: relative;
`

const TopActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
  z-index: 30;
`

const BackButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #4b5563;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: #1f2937;
  }

  svg {
    margin-right: 0.5rem;
  }
`

const ApplicationStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
`

const ApplicationStatusBadge = styled.select`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1em;
  padding-right: 2.5rem;

  ${({ status }) => {
    switch (status) {
      case 'reviewed':
        return `
          background-color: #EAE2F8;
          color: #805AD5;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23805AD5' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
        `
      case 'shortlisted':
        return `
          background-color: #FFEDD5;
          color: #DD6B20;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23DD6B20' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
        `
      case 'interview':
        return `
          background-color: #FEFCBF;
          color: #D69E2E;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23D69E2E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
        `
      case 'offer':
        return `
          background-color: #F0FFF4;
          color: #38A169;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2338A169' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
        `
      case 'rejected':
        return `
          background-color: #FFF5F5;
          color: #E53E3E;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23E53E3E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
        `
      default:
        return `
          background-color: #EDF2F7;
          color: #4A5568;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%234A5568' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
        `
    }
  }}

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }
`

const ApplicationInfo = styled.div`
  font-size: 0.875rem;
  color: #64748b;
`

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    display: none;
  }
`

const ToggleLabel = styled.label`
  margin-right: 0.5rem;
  font-size: 1rem;
  color: #4b5563;
`

const ToggleSwitch = styled.div`
  position: relative;
  width: 40px;
  height: 20px;
  background-color: ${(props) => (props.checked ? '#10B981' : '#E5E7EB')};
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`

const ToggleSlider = styled.div`
  position: absolute;
  top: 2px;
  left: ${(props) => (props.checked ? '22px' : '2px')};
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: left 0.3s ease;
`

const CoverPhoto = styled.div`
  height: 250px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 8px 8px 0 0;
  position: relative;
`

const ProfileContent = styled.div`
  display: flex;
  margin-top: -5rem;
  position: relative;
  z-index: 20;
`

const MainContent = styled.div`
  width: 65%;
  padding-right: 2rem;
  margin-left: 20px;
`

const Sidebar = styled.div`
  width: 35%;
  margin-right: 30px;
`

const ProfileCard = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
`
const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`

const ProfilePicture = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 1rem;
  border: 4px solid white;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
`

const ProfileInfo = styled.div``

const ProfileName = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
`

const ProfileTitle = styled.p`
  font-size: 1.1rem;
  color: #4b5563;
  margin-top: -3px;
`

const SectionTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 0;
  color: #333;
`

const WorkHistoryItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #f9fafb;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;
`

const WorkDetails = styled.div`
  flex-grow: 1;
  font-size: 1rem;
`

const DateRange = styled.p`
  font-size: 0.95rem;
  color: #6b7280;
  margin-bottom: 0.5rem;
`

const PillSection = styled.div`
  margin: 0.75rem 0;
`

const PillLabel = styled.div`
  font-size: 0.85rem;
  color: #666;
  margin-bottom: 0.3rem;
  font-weight: 500;
`

const PillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
  margin: 0.5rem 0;
`

const SmallPill = styled.span`
  display: inline-block;
  background-color: #caaae1;
  color: white;
  border-radius: 15px;
  padding: 4px 8px;
  font-size: 0.8rem;
  margin: 3px;
  opacity: 0.9;
`

const PortfolioContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 0 1rem;
`

const PortfolioScroller = styled.div`
  display: flex;
  gap: 1.5rem;
  overflow-x: auto;
  padding: 1rem 0;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const PortfolioItem = styled.div`
  min-width: 250px;
  background: white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const PortfolioImageContainer = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  border-radius: 0.375rem;
  overflow: hidden;
  background-color: #f3f4f6;
  margin-bottom: 1rem;
`

const PortfolioImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
`

const PortfolioTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #caaae1;
  text-decoration: underline;
`

const Link = styled.a`
  display: flex;
  align-items: center;
  color: #4b5563;
  text-decoration: none;
  margin-bottom: 0.5rem;

  &:hover {
    color: #1f2937;
  }

  svg {
    margin-right: 0.5rem;
  }
`

const ResumePageContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  margin-top: -12rem;
  background-color: #f3f4f6;
  padding: 1rem;
  margin-left: -12px;
  position: relative;
`

const ResumeViewer = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
`

const ResumeFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 90%;
  max-width: 500px;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1rem;
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
  background: ${(props) => (props.checked ? '#f8f4fb' : 'white')};

  &:hover {
    background: #f8f4fb;
  }
`

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;

  ${(props) =>
    props.variant === 'secondary'
      ? `
    background: #f1f5f9;
    color: #64748b;
    border: none;

    &:hover {
      background: #e2e8f0;
    }
  `
      : `
    background: #dc2626;
    color: white;
    border: none;

    &:hover {
      background: #b91c1c;
    }
  `}
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
`
const DetailParagraph = styled.p`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  color: #4b5563;
  font-size: 0.875rem;
  line-height: 1.25rem;

  svg {
    margin-right: 0.5rem;
    flex-shrink: 0;
  }
`

const HeartButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 1rem;
  padding: 0.5rem;
  color: ${(props) => (props.isInPipeline ? '#ef4444' : '#6b7280')};
  transition: all 0.2s ease;

  &:hover {
    color: #ef4444;
    transform: scale(1.1);
  }
`

const PipelineModal = styled(Modal)`
  max-width: 400px;
`

const PipelineList = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const PipelineOption = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #f9fafb;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: #f3f4f6;
  }

  ${(props) =>
    props.selected &&
    `
    background-color: #caaae1;
    color: white;

    &:hover {
      background-color: #b799d4;
    }
  `}
`

const CreateNewPipeline = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border-top: 1px solid #e5e7eb;
`

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.875rem;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 2px rgba(202, 170, 225, 0.1);
  }
`

const JobSeekerProfileView = () => {
  const { jobseekerId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [profile, setProfile] = useState(null)
  const [user, setUser] = useState(null)
  const [showResume, setShowResume] = useState(false)
  const [resumeUrl, setResumeUrl] = useState('')
  const [showPipelineModal, setShowPipelineModal] = useState(false)
  const [pipelines, setPipelines] = useState({})
  const [selectedPipeline, setSelectedPipeline] = useState('')
  const [newPipelineName, setNewPipelineName] = useState('')
  const [isCreatingNew, setIsCreatingNew] = useState(false)
  const [showRejectionModal, setShowRejectionModal] = useState(false)
  const [rejectionReason, setRejectionReason] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [applicationData, setApplicationData] = useState(
    location.state?.applicationData
  )

  useEffect(() => {
    const fetchData = async () => {
      if (jobseekerId) {
        const profileData = await getJobseekerProfile(jobseekerId)
        setProfile(profileData)
        setResumeUrl(profileData?.resume_url || '')

        const userData = await getUserById(jobseekerId)
        setUser(userData)
      }
    }
    fetchData()
  }, [jobseekerId])

  useEffect(() => {
    const fetchApplicationData = async () => {
      if (!applicationData && location.state?.applicationId) {
        try {
          const fetchedApplicationData = await getJobApplication(
            location.state.applicationId
          )
          setApplicationData(fetchedApplicationData)
        } catch (error) {
          console.error('Error fetching application data:', error)
        }
      }
    }

    fetchApplicationData()
  }, [applicationData, location.state?.applicationId])

  useEffect(() => {
    const fetchPipelines = async () => {
      const user = auth.currentUser
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid))
          const employerId = userDoc.data()?.employer_id || user.uid
          const fetchedPipelines = await getPipelinesFromFirestore(employerId)
          console.log('Fetched pipelines:', fetchedPipelines)
          setPipelines(fetchedPipelines)
        } catch (error) {
          console.error('Error fetching pipelines:', error)
        }
      }
    }

    fetchPipelines()
  }, [])
  // Format date helpers
  const formatDate = (dateString) => {
    if (!dateString) return 'Present'
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
  }

  const formatDateMonthYear = (dateString) => {
    if (!dateString) return ''
    const [year, month] = dateString.split('-')
    return `${new Date(year, month - 1).toLocaleString('en-US', {
      month: 'short'
    })} ${year}`
  }

  // Event handlers
  const handleToggle = () => {
    setShowResume(!showResume)
  }

  const handleBackClick = () => {
    navigate(-1)
  }

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value
    if (!applicationData) return

    try {
      await updateJobApplication(applicationData.id, { status: newStatus })
      setApplicationData((prev) => ({ ...prev, status: newStatus }))
    } catch (error) {
      console.error('Error updating application status:', error)
      alert('Failed to update application status')
    }
  }

  const handleRejection = async () => {
    if (!rejectionReason) {
      alert('Please select a reason for rejection')
      return
    }

    try {
      await updateJobApplication(applicationData.applicationId, {
        status: 'rejected',
        rejection_reasoning: rejectionReason
      })
      location.state.applicationData.status = 'rejected'
      setShowRejectionModal(false)
    } catch (error) {
      console.error('Error rejecting application:', error)
      alert('Failed to reject application')
    }
  }

  const handleHeartClick = () => {
    setShowPipelineModal(true)
  }

  const handleAddToPipeline = async () => {
    const user = auth.currentUser
    if (!user) {
      alert('Please sign in to add to pipeline')
      return
    }

    if (!selectedPipeline && !newPipelineName) {
      alert('Please select or create a pipeline')
      return
    }

    setIsLoading(true)
    try {
      // Get current user's employer profile to get employerId
      const userDoc = await getDoc(doc(db, 'users', user.uid))
      const employerId = userDoc.data()?.employer_id || user.uid

      const pipelineName = newPipelineName || selectedPipeline

      if (newPipelineName) {
        await addPipeline(employerId, newPipelineName)
      }

      await addJobSeekerToPipeline(employerId, pipelineName, jobseekerId)

      const updatedPipelines = await getPipelinesFromFirestore(employerId)
      setPipelines(updatedPipelines)

      setShowPipelineModal(false)
      setNewPipelineName('')
      setSelectedPipeline('')
      setIsCreatingNew(false)
    } catch (error) {
      console.error('Error adding to pipeline:', error)
      alert(`Failed to add to pipeline: ${error.message}`)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <PageWrapper>
      <NavbarWrapper>
        <EmployerNav />
      </NavbarWrapper>
      <PageContainer>
        <ContentContainer>
          <TopActionsContainer>
            <BackButton onClick={handleBackClick}>
              <ArrowLeft size={16} /> Back
            </BackButton>

            {applicationData && (
              <ApplicationStatusContainer>
                <ApplicationStatusBadge
                  value={applicationData.status}
                  onChange={handleStatusChange}
                  status={applicationData.status}
                >
                  <option value="reviewed">Reviewed</option>
                  <option value="shortlisted">Shortlisted</option>
                  <option value="interview">Interview</option>
                  <option value="offer">Offer</option>
                  <option value="rejected">Rejected</option>
                </ApplicationStatusBadge>
              </ApplicationStatusContainer>
            )}
          </TopActionsContainer>

          <ToggleContainer>
            <HeartButton onClick={handleHeartClick}>
              <Heart size={20} />
            </HeartButton>
            <ToggleLabel>
              {showResume ? 'Back to Profile' : 'View Resume'}
            </ToggleLabel>
            <ToggleSwitch checked={showResume} onClick={handleToggle}>
              <ToggleSlider checked={showResume} />
            </ToggleSwitch>
          </ToggleContainer>

          <CoverPhoto src="/images/cover-photo.png.jpg" />

          {!showResume ? (
            <ProfileContent>
              <MainContent>
                {/* Profile Card */}
                <ProfileCard>
                  <ProfileHeader>
                    <ProfilePicture
                      src={profile?.profile_picture || 'default-profile.png'}
                    />
                    <ProfileInfo>
                      <ProfileName>
                        {user?.first_name} {user?.last_name}
                        {profile?.pronouns && (
                          <span
                            style={{
                              fontSize: '0.85rem',
                              color: '#6B7280',
                              marginLeft: '0.5rem'
                            }}
                          >
                            ({profile.pronouns})
                          </span>
                        )}
                      </ProfileName>
                      <ProfileTitle>
                        {profile?.work_history?.[0]?.title || 'Student'}
                      </ProfileTitle>
                    </ProfileInfo>
                  </ProfileHeader>

                  <SectionTitle>About Me</SectionTitle>
                  <p>{profile?.about || 'No about section provided.'}</p>
                </ProfileCard>

                {/* Work History */}
                <ProfileCard>
                  <SectionTitle>Work History</SectionTitle>
                  {profile?.work_history?.map((job, index) => (
                    <WorkHistoryItem key={index}>
                      <IconContainer>
                        <Briefcase size={20} />
                      </IconContainer>
                      <WorkDetails>
                        <h4>
                          {job.title || 'Title not provided'} at{' '}
                          {job.employer || 'Employer not provided'}
                        </h4>
                        <DateRange>
                          {formatDate(job.start_date)} -{' '}
                          {formatDate(job.end_date)}
                        </DateRange>

                        {/* Industry Pills */}
                        {job.industry?.length > 0 && (
                          <PillSection>
                            <PillLabel>Industry</PillLabel>
                            <PillContainer>
                              {job.industry.map((ind) => (
                                <SmallPill key={ind}>{ind}</SmallPill>
                              ))}
                            </PillContainer>
                          </PillSection>
                        )}

                        {/* Company Type Pills */}
                        {job.company_type?.length > 0 && (
                          <PillSection>
                            <PillLabel>Company Type</PillLabel>
                            <PillContainer>
                              {job.company_type.map((type) => (
                                <SmallPill key={type}>{type}</SmallPill>
                              ))}
                            </PillContainer>
                          </PillSection>
                        )}

                        {/* Job Function Pills */}
                        {job.job_function?.length > 0 && (
                          <PillSection>
                            <PillLabel>Job Function</PillLabel>
                            <PillContainer>
                              {job.job_function.map((func) => (
                                <SmallPill key={func}>
                                  {func === 'Other (specify)'
                                    ? job.other_job_function || 'Other'
                                    : func}
                                </SmallPill>
                              ))}
                            </PillContainer>
                          </PillSection>
                        )}
                      </WorkDetails>
                    </WorkHistoryItem>
                  ))}
                </ProfileCard>

                {/* Athletic History */}
                {profile?.athletic_history?.length > 0 && (
                  <ProfileCard>
                    <SectionTitle>Athletic Experience</SectionTitle>
                    {profile.athletic_history.map((history, index) => (
                      <WorkHistoryItem key={index}>
                        <IconContainer>
                          <TrophyIcon size={20} />
                        </IconContainer>
                        <WorkDetails>
                          <h4>
                            {history.sport || ''} | {history.position || ''} |{' '}
                            {history.team || ''}
                          </h4>
                          <p>{history.league || 'League not provided'}</p>
                          <DateRange>
                            {formatDateMonthYear(history.start_date)} -{' '}
                            {history.currentTeam
                              ? 'Present'
                              : formatDateMonthYear(history.end_date)}
                          </DateRange>
                          {history.achievements && (
                            <p>{history.achievements}</p>
                          )}
                        </WorkDetails>
                      </WorkHistoryItem>
                    ))}
                  </ProfileCard>
                )}

                {/* Education */}
                <ProfileCard>
                  <SectionTitle>Education</SectionTitle>
                  {profile?.education_history?.map((edu, index) => (
                    <WorkHistoryItem key={index}>
                      <IconContainer>
                        <GraduationCap size={20} />
                      </IconContainer>
                      <WorkDetails>
                        <h4>
                          {edu.degree_type} in {edu.field_of_study}
                        </h4>
                        <p>{edu.institution}</p>
                        {edu.graduation_date && (
                          <DateRange>
                            {formatDateMonthYear(edu.graduation_date)}
                          </DateRange>
                        )}
                      </WorkDetails>
                    </WorkHistoryItem>
                  ))}
                </ProfileCard>

                {/* Technical Skills */}
                {profile?.technical_skills?.length > 0 && (
                  <ProfileCard>
                    <SectionTitle>Technical Skills</SectionTitle>
                    <PillContainer>
                      {profile.technical_skills.map((skill) => (
                        <SmallPill key={skill}>
                          {skill === 'Other'
                            ? profile?.other_technical_skill || 'Other'
                            : skill}
                        </SmallPill>
                      ))}
                    </PillContainer>
                  </ProfileCard>
                )}

                {/* Top Strengths */}
                {profile?.top_strengths?.length > 0 && (
                  <ProfileCard>
                    <SectionTitle>Top Strengths</SectionTitle>
                    <PillContainer>
                      {profile.top_strengths.map((strength) => (
                        <SmallPill key={strength}>{strength}</SmallPill>
                      ))}
                    </PillContainer>
                  </ProfileCard>
                )}

                {/* Portfolio */}
                {profile?.portfolio_profile_links?.length > 0 && (
                  <ProfileCard>
                    <SectionTitle>Portfolio</SectionTitle>
                    <PortfolioContainer>
                      <PortfolioScroller>
                        {profile.portfolio_profile_links.map((item, index) => (
                          <PortfolioItem key={index}>
                            <a
                              href={item.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: 'none' }}
                            >
                              <PortfolioImageContainer>
                                <PortfolioImage src={item.imageUrl} />
                              </PortfolioImageContainer>
                              <PortfolioTitle>{item.title}</PortfolioTitle>
                            </a>
                          </PortfolioItem>
                        ))}
                      </PortfolioScroller>
                    </PortfolioContainer>
                  </ProfileCard>
                )}
              </MainContent>

              {/* Sidebar */}
              <Sidebar>
                {/* Contact Card */}
                <ProfileCard>
                  <SectionTitle>Contact</SectionTitle>
                  <DetailParagraph>
                    <IconContainer>
                      <Mail size={18} />
                    </IconContainer>
                    {user?.email || 'No email provided'}
                  </DetailParagraph>
                  <DetailParagraph>
                    <IconContainer>
                      <MapPin size={18} />
                    </IconContainer>
                    {profile?.city && profile?.state
                      ? `${profile.city}, ${profile.state}`
                      : 'Location not provided'}
                  </DetailParagraph>
                </ProfileCard>

                {/* Additional Info Card */}
                <ProfileCard>
                  <SectionTitle>Additional Info</SectionTitle>
                  <DetailParagraph>
                    <IconContainer>
                      <Globe size={18} />
                    </IconContainer>
                    Languages:{' '}
                    {profile?.languages?.join(', ') || 'Not specified'}
                  </DetailParagraph>
                  <DetailParagraph>
                    <IconContainer>
                      <Clock size={18} />
                    </IconContainer>
                    Experience: {profile?.years_experience || 'Not specified'}
                  </DetailParagraph>
                  <DetailParagraph>
                    <IconContainer>
                      <LineChart size={18} />
                    </IconContainer>
                    Startup Experience:{' '}
                    {profile?.startup_experience ? 'Yes' : 'No'}
                  </DetailParagraph>
                  <DetailParagraph>
                    <IconContainer>
                      <Users size={18} />
                    </IconContainer>
                    Job Level: {profile?.current_level || 'Not specified'}
                  </DetailParagraph>
                </ProfileCard>

                {/* Links Card */}
                <ProfileCard>
                  <SectionTitle>Links</SectionTitle>
                  {profile?.linkedin && (
                    <Link
                      href={
                        profile.linkedin.startsWith('http')
                          ? profile.linkedin
                          : `https://${profile.linkedin}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Linkedin size={18} />
                      LinkedIn
                    </Link>
                  )}
                  {profile?.github && (
                    <Link
                      href={
                        profile.github.startsWith('http')
                          ? profile.github
                          : `https://${profile.github}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Github size={18} />
                      GitHub
                    </Link>
                  )}
                  {profile?.portfolio && (
                    <Link
                      href={
                        profile.portfolio.startsWith('http')
                          ? profile.portfolio
                          : `https://${profile.portfolio}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FileText size={18} />
                      Portfolio
                    </Link>
                  )}
                  {profile?.personal_website && (
                    <Link
                      href={
                        profile.personal_website.startsWith('http')
                          ? profile.personal_website
                          : `https://${profile.personal_website}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Globe size={18} />
                      Personal Website
                    </Link>
                  )}
                </ProfileCard>
              </Sidebar>
            </ProfileContent>
          ) : (
            // Resume View
            <ResumePageContainer>
              <ResumeViewer>
                {resumeUrl ? (
                  <ResumeFrame
                    src={`${resumeUrl}#toolbar=0&navpanes=0&scrollbar=0`}
                    title="Resume Viewer"
                  />
                ) : (
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      color: '#6b7280'
                    }}
                  >
                    <Upload size={48} />
                    <p style={{ marginTop: '1rem' }}>No resume uploaded yet</p>
                  </div>
                )}
              </ResumeViewer>
            </ResumePageContainer>
          )}

          {/* Rejection Modal */}
          {showRejectionModal && (
            <>
              <Overlay onClick={() => setShowRejectionModal(false)} />
              <Modal>
                <h2
                  style={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginBottom: '1rem'
                  }}
                >
                  Select Rejection Reason
                </h2>
                <RadioGroup>
                  {[
                    'Position filled internally',
                    'Not enough experience',
                    'Too much experience',
                    'Salary expectations too high',
                    'Requires visa sponsorship',
                    'Based outside of country',
                    'Not willing to relocate',
                    'Position closed/not hired'
                  ].map((reason) => (
                    <RadioLabel
                      key={reason}
                      checked={rejectionReason === reason}
                    >
                      <input
                        type="radio"
                        name="rejectionReason"
                        value={reason}
                        checked={rejectionReason === reason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                      />
                      {reason}
                    </RadioLabel>
                  ))}
                </RadioGroup>
                <ButtonContainer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setShowRejectionModal(false)
                      setRejectionReason('')
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleRejection}>Confirm Rejection</Button>
                </ButtonContainer>
              </Modal>
            </>
          )}
        </ContentContainer>
        {showPipelineModal && (
          <>
            <Overlay onClick={() => setShowPipelineModal(false)} />
            <PipelineModal>
              <h2
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  marginBottom: '1rem'
                }}
              >
                Add to Pipeline
              </h2>

              <PipelineList>
                {Object.keys(pipelines).map((pipelineName) => (
                  <PipelineOption
                    key={pipelineName}
                    selected={selectedPipeline === pipelineName}
                    onClick={() => {
                      setSelectedPipeline(pipelineName)
                      setIsCreatingNew(false)
                      setNewPipelineName('')
                    }}
                  >
                    {pipelineName}
                  </PipelineOption>
                ))}
              </PipelineList>

              <CreateNewPipeline>
                <label>
                  <input
                    type="radio"
                    checked={isCreatingNew}
                    onChange={() => {
                      setIsCreatingNew(true)
                      setSelectedPipeline('')
                    }}
                  />{' '}
                  Create New Pipeline
                </label>

                {isCreatingNew && (
                  <Input
                    type="text"
                    value={newPipelineName}
                    onChange={(e) => setNewPipelineName(e.target.value)}
                    placeholder="Enter pipeline name"
                  />
                )}
              </CreateNewPipeline>

              <ButtonContainer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowPipelineModal(false)
                    setNewPipelineName('')
                    setSelectedPipeline('')
                    setIsCreatingNew(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleAddToPipeline}
                  style={{ backgroundColor: '#caaae1' }}
                >
                  Add to Pipeline
                </Button>
              </ButtonContainer>
            </PipelineModal>
          </>
        )}
      </PageContainer>
    </PageWrapper>
  )
}
export default JobSeekerProfileView
