import React, { useMemo } from 'react'
import styled from 'styled-components'
import { BarChart2 } from 'lucide-react'

const TableCard = styled.div`
  background-color: ${(props) => props.theme.card || '#ffffff'};
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
  padding: 1.5rem;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`

const TableTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  color: ${(props) => props.theme.text || '#2d3748'};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
`

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
`

const Th = styled.th`
  text-align: ${(props) => props.align || 'left'};
  padding: 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${(props) => props.theme.textLight || '#718096'};
  border-bottom: 1px solid ${(props) => props.theme.border || '#e2e8f0'};
`

const Td = styled.td`
  padding: 1rem;
  font-size: 0.875rem;
  color: ${(props) => props.theme.text || '#2d3748'};
  border-bottom: 1px solid ${(props) => props.theme.border || '#e2e8f0'};
  text-align: ${(props) => props.align || 'left'};
`

const Tr = styled.tr`
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${(props) => props.theme.background || '#f0f4f8'};
  }
`

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.border || '#e2e8f0'};
  border-radius: 9999px;
  height: 0.5rem;
`

const ProgressBar = styled.div`
  height: 0.5rem;
  border-radius: 9999px;
  background-color: #c859ff;
  width: ${(props) => props.width}%;
  transition: width 0.3s ease;
`

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #12c2e8;
  color: white;
  margin-right: 0.5rem;
`

const JobFunctionsTable = ({ jobSeekers }) => {
  const jobFunctionStats = useMemo(() => {
    const stats = {}
    let totalCount = 0

    jobSeekers.forEach((seeker) => {
      if (seeker.work_history && seeker.work_history.length > 0) {
        seeker.work_history.forEach((work) => {
          if (work.job_function && Array.isArray(work.job_function)) {
            work.job_function.forEach((function_name) => {
              stats[function_name] = (stats[function_name] || 0) + 1
              totalCount++
            })
          }
        })
      }
    })

    return Object.entries(stats)
      .map(([name, count]) => ({
        name,
        count,
        percentage: ((count / totalCount) * 100).toFixed(1)
      }))
      .sort((a, b) => b.count - a.count)
  }, [jobSeekers])

  return (
    <TableCard>
      <TableHeader>
        <TableTitle>
          <IconWrapper>
            <BarChart2 size={16} />
          </IconWrapper>
          Job Functions Distribution
        </TableTitle>
      </TableHeader>
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <Th>Job Function</Th>
              <Th align="right">Count</Th>
              <Th align="right">Percentage</Th>
              <Th>Distribution</Th>
            </tr>
          </thead>
          <tbody>
            {jobFunctionStats.map((stat) => (
              <Tr key={stat.name}>
                <Td>{stat.name}</Td>
                <Td align="right">{stat.count}</Td>
                <Td align="right">{stat.percentage}%</Td>
                <Td>
                  <ProgressBarContainer>
                    <ProgressBar width={stat.percentage} />
                  </ProgressBarContainer>
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </TableCard>
  )
}

export default JobFunctionsTable
