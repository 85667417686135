import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { db } from '../../firebase'
import {
  collection,
  getDocs,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  serverTimestamp,
  Timestamp
} from 'firebase/firestore'

const Card = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
`

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 14px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    outline: none;
    border-color: #8b5cf6;
  }
`

const Button = styled.button`
  background-color: #8b5cf6;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0;
  margin-right: 10px;
  font-weight: 500;
  transition: background-color 0.2s;

  &:hover {
    background-color: #7c3aed;
  }

  &:disabled {
    background-color: rgba(139, 92, 246, 0.5);
    cursor: not-allowed;
  }
`

const CouponCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
`

const CouponInfo = styled.div`
  flex: 1;

  h3 {
    margin: 0 0 10px 0;
    color: white;
  }

  p {
    margin: 5px 0;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
  }
`

const DeleteButton = styled(Button)`
  background-color: #ef4444;
  &:hover {
    background-color: #dc2626;
  }
`

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
  color: white;
  font-size: 14px;

  option {
    background: #1f2937;
    color: white;
  }
`

const ErrorMessage = styled.div`
  color: #ef4444;
  background: rgba(239, 68, 68, 0.1);
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
`

const Title = styled.h2`
  color: white;
  margin-bottom: 20px;
  font-weight: 500;
`

const Label = styled.label`
  color: white;
  display: block;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 14px;
`

const CouponsTab = () => {
  const [coupons, setCoupons] = useState([])
  const [newCoupon, setNewCoupon] = useState({
    code: '',
    expirationDate: '',
    isFixedEndDate: false,
    fixedEndDate: '',
    trialDays: 14,
    jobPostLimit: 1
  })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  const fetchCoupons = async () => {
    setLoading(true)
    setError('')
    try {
      const couponsRef = collection(db, 'coupons')
      const couponsSnapshot = await getDocs(couponsRef)

      const couponsList = couponsSnapshot.docs.map((doc) => {
        return {
          code: doc.id, // Make sure we're storing the document ID as code
          ...doc.data()
        }
      })
      setCoupons(couponsList)
    } catch (err) {
      console.error('Error in fetchCoupons:', err)
      setError(`Error fetching coupons: ${err.message}`)
    } finally {
      setLoading(false)
    }
  }

  const initializeCoupons = async () => {
    try {
      // Create Arena30 coupon
      await setDoc(doc(db, 'coupons', 'ARENA30'), {
        code: 'ARENA30',
        expirationDate: Timestamp.fromDate(new Date('2024-11-05')),
        isFixedEndDate: false,
        trialDays: 30,
        jobPostLimit: -1,
        createdAt: serverTimestamp()
      })

      // Create ArenaLaunch coupon
      await setDoc(doc(db, 'coupons', 'ARENALAUNCH'), {
        code: 'ARENALAUNCH',
        expirationDate: Timestamp.fromDate(new Date('2023-11-12')),
        isFixedEndDate: true,
        fixedEndDate: Timestamp.fromDate(new Date('2023-11-26')),
        jobPostLimit: 5,
        createdAt: serverTimestamp()
      })
    } catch (err) {
      console.error('Error creating initial coupons:', err)
      throw err
    }
  }

  useEffect(() => {
    fetchCoupons()
  }, [])

  const handleCreateCoupon = async (e) => {
    e.preventDefault()
    setError('')
    try {
      if (!newCoupon.code || !newCoupon.expirationDate) {
        setError('Please fill in all required fields')
        return
      }

      const code = newCoupon.code.toUpperCase()

      const expirationDate = Timestamp.fromDate(
        new Date(newCoupon.expirationDate)
      )
      let trialEnd = null

      if (newCoupon.isFixedEndDate && newCoupon.fixedEndDate) {
        trialEnd = Timestamp.fromDate(new Date(newCoupon.fixedEndDate))
      }

      const couponData = {
        code,
        expirationDate,
        isFixedEndDate: newCoupon.isFixedEndDate,
        fixedEndDate: trialEnd,
        trialDays: parseInt(newCoupon.trialDays),
        jobPostLimit: parseInt(newCoupon.jobPostLimit),
        createdAt: serverTimestamp()
      }

      await setDoc(doc(db, 'coupons', code), couponData)

      setNewCoupon({
        code: '',
        expirationDate: '',
        isFixedEndDate: false,
        fixedEndDate: '',
        trialDays: 14,
        jobPostLimit: 1
      })

      await fetchCoupons()
    } catch (err) {
      console.error('Error in handleCreateCoupon:', err)
      setError(`Error creating coupon: ${err.message}`)
    }
  }

  const handleDeleteCoupon = async (couponCode) => {
    if (!couponCode) {
      console.error('No coupon code provided for deletion')
      setError('No coupon code provided for deletion')
      return
    }

    if (
      window.confirm(
        `Are you sure you want to delete the coupon: ${couponCode}?`
      )
    ) {
      try {
        setError('')

        const couponRef = doc(db, 'coupons', couponCode)
        const couponDoc = await getDoc(couponRef)

        if (!couponDoc.exists()) {
          throw new Error(`Coupon with code ${couponCode} not found`)
        }

        await deleteDoc(couponRef)

        // Update local state immediately
        setCoupons((prevCoupons) =>
          prevCoupons.filter((coupon) => coupon.code !== couponCode)
        )
      } catch (err) {
        console.error('Error deleting coupon:', err)
        setError(`Error deleting coupon: ${err.message}`)
      }
    }
  }

  if (loading)
    return (
      <Card>
        <Title>Loading coupons...</Title>
      </Card>
    )

  return (
    <div>
      <Card>
        <Title>Create New Coupon</Title>
        <form onSubmit={handleCreateCoupon}>
          <Label>Coupon Code</Label>
          <Input
            placeholder="Enter coupon code"
            value={newCoupon.code}
            onChange={(e) =>
              setNewCoupon({ ...newCoupon, code: e.target.value })
            }
            required
          />

          <Label>Coupon Expiration Date</Label>
          <Input
            type="date"
            value={newCoupon.expirationDate}
            onChange={(e) =>
              setNewCoupon({ ...newCoupon, expirationDate: e.target.value })
            }
            required
          />

          <Label>Trial Type</Label>
          <Select
            value={newCoupon.isFixedEndDate}
            onChange={(e) =>
              setNewCoupon({
                ...newCoupon,
                isFixedEndDate: e.target.value === 'true'
              })
            }
          >
            <option value={false}>Trial Duration</option>
            <option value={true}>Fixed End Date</option>
          </Select>

          {newCoupon.isFixedEndDate ? (
            <>
              <Label>Fixed Trial End Date</Label>
              <Input
                type="date"
                value={newCoupon.fixedEndDate}
                onChange={(e) =>
                  setNewCoupon({ ...newCoupon, fixedEndDate: e.target.value })
                }
                required
              />
            </>
          ) : (
            <>
              <Label>Trial Duration (days)</Label>
              <Input
                type="number"
                min="1"
                value={newCoupon.trialDays}
                onChange={(e) =>
                  setNewCoupon({ ...newCoupon, trialDays: e.target.value })
                }
                required
              />
            </>
          )}

          <Label>Job Post Limit</Label>
          <Input
            type="number"
            min="-1"
            placeholder="Enter -1 for unlimited"
            value={newCoupon.jobPostLimit}
            onChange={(e) =>
              setNewCoupon({ ...newCoupon, jobPostLimit: e.target.value })
            }
            required
          />

          <Button type="submit">Create Coupon</Button>
        </form>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Card>

      <Title>Active Coupons</Title>
      {coupons.length === 0 ? (
        <Card>No coupons found</Card>
      ) : (
        coupons.map((coupon) => (
          <CouponCard key={coupon.code || coupon.id}>
            <CouponInfo>
              <h3>{coupon.code}</h3>
              <div
                style={{ fontSize: '12px', color: '#666', marginBottom: '8px' }}
              >
                Document ID: {coupon.code} {/* Debug info */}
              </div>
              <p>
                Expires:{' '}
                {new Date(
                  coupon.expirationDate.seconds * 1000
                ).toLocaleDateString()}
              </p>
              {coupon.isFixedEndDate ? (
                <p>
                  Trial ends on:{' '}
                  {new Date(
                    coupon.fixedEndDate.seconds * 1000
                  ).toLocaleDateString()}
                </p>
              ) : (
                <p>Trial duration: {coupon.trialDays} days</p>
              )}
              <p>
                Job post limit:{' '}
                {coupon.jobPostLimit === -1 ? 'Unlimited' : coupon.jobPostLimit}
              </p>
            </CouponInfo>
            <DeleteButton
              onClick={() => handleDeleteCoupon(coupon.code)}
              title={`Delete coupon: ${coupon.code}`} // Debug info
            >
              Delete
            </DeleteButton>
          </CouponCard>
        ))
      )}
    </div>
  )
}

export default CouponsTab
