export const MATCH_WEIGHTS = {
  skills: {
    weight: 0.25,
    required: 0.6,
    preferred: 0.4,
    technical: 0.5,
    recommended: 0.3
  },
  experience: {
    weight: 0.15,
    years: 0.5,
    level: 0.5
  },
  education: {
    weight: 0.1
  },
  location: {
    weight: 0.1,
    remote: 1.0,
    hybrid: 0.7,
    onsite: 0.4
  },
  industry: {
    weight: 0.3,
    direct: 0.7,
    related: 0.5
  },
  compensation: {
    weight: 0.01
  }
}

export const EDUCATION_LEVELS = {
  'High School': 1,
  Associate: 2,
  Bachelor: 3,
  Master: 4,
  PhD: 5,
  Other: 1
}

export const CAREER_LEVELS = {
  Internship: 1,
  'Entry Level': 2,
  'Mid Level': 3,
  'Senior Level': 4,
  Manager: 5,
  Director: 6,
  Executive: 7
}

const Match = (job, jobSeekerProfile, employerProfile) => {
  if (!jobSeekerProfile) return 0

  const skillsScore = calculateSkillsMatch(job, jobSeekerProfile)
  const experienceScore = calculateExperienceMatch(job, jobSeekerProfile)
  const educationScore = calculateEducationMatch(job, jobSeekerProfile)
  const locationScore = calculateLocationMatch(job, jobSeekerProfile)
  const industryScore = calculateIndustryMatch(
    job,
    jobSeekerProfile,
    employerProfile
  )
  const compensationScore = calculateCompensationMatch(job, jobSeekerProfile)

  const totalScore =
    skillsScore * MATCH_WEIGHTS.skills.weight +
    experienceScore * MATCH_WEIGHTS.experience.weight +
    educationScore * MATCH_WEIGHTS.education.weight +
    locationScore * MATCH_WEIGHTS.location.weight +
    industryScore * MATCH_WEIGHTS.industry.weight +
    compensationScore * MATCH_WEIGHTS.compensation.weight

  return Math.round(totalScore * 100)
}

const calculateSkillsMatch = (job, profile) => {
  const profileSkills = new Set(
    [
      ...(profile.technical_skills || []),
      ...(profile.other_technical_skills || [])
    ]
      .filter((s) => typeof s === 'string')
      .map((s) => s.toLowerCase())
  )

  if (!profileSkills.size) return 0

  // Match against technical_skills
  const technicalSkills = new Set(
    (job.technical_skills || [])
      .filter((s) => typeof s === 'string')
      .map((s) => s.toLowerCase())
  )

  // Match against recommended_skills
  const recommendedSkills = new Set(
    (job.recommended_skills || [])
      .filter((s) => typeof s === 'string')
      .map((s) => s.toLowerCase())
  )

  // Parse required skills from requirements text
  const requiredSkills =
    job.requirements
      ?.toLowerCase()
      .split(/[\n.,]/)
      .map((s) => s.trim())
      .filter(Boolean) || []

  // Parse preferred skills from nice_to_haves text
  const preferredSkills =
    job.nice_to_haves
      ?.toLowerCase()
      .split(/[\n.,]/)
      .map((s) => s.trim())
      .filter(Boolean) || []

  let technicalScore = 0
  let recommendedScore = 0
  let requiredScore = 1
  let preferredScore = 1

  if (technicalSkills.size > 0) {
    const matches = [...technicalSkills].filter((skill) =>
      [...profileSkills].some(
        (pSkill) => skill.includes(pSkill) || pSkill.includes(skill)
      )
    ).length
    technicalScore = matches / technicalSkills.size
  }

  if (recommendedSkills.size > 0) {
    const matches = [...recommendedSkills].filter((skill) =>
      [...profileSkills].some(
        (pSkill) => skill.includes(pSkill) || pSkill.includes(skill)
      )
    ).length
    recommendedScore = matches / recommendedSkills.size
  }

  if (requiredSkills.length > 0) {
    const matches = requiredSkills.filter((req) =>
      [...profileSkills].some((skill) => req.includes(skill))
    ).length
    requiredScore = matches / requiredSkills.length
  }

  if (preferredSkills.length > 0) {
    const matches = preferredSkills.filter((pref) =>
      [...profileSkills].some((skill) => pref.includes(skill))
    ).length
    preferredScore = matches / preferredSkills.length
  }

  return Math.min(
    1,
    technicalScore * MATCH_WEIGHTS.skills.technical +
      recommendedScore * MATCH_WEIGHTS.skills.recommended +
      requiredScore * MATCH_WEIGHTS.skills.required +
      preferredScore * MATCH_WEIGHTS.skills.preferred
  )
}

const calculateExperienceMatch = (job, profile) => {
  const profileYears = parseInt(profile.years_experience?.split('-')[0]) || 0
  const requiredYears =
    parseInt(job.requirements?.match(/(\d+)\+?\s*years?/)?.[1]) || 0

  let yearsScore =
    requiredYears === 0 ? 1 : Math.min(2, profileYears / requiredYears)

  const jobLevel = CAREER_LEVELS[job.level] || 1
  const profileLevel =
    CAREER_LEVELS[profile.current_level] || CAREER_LEVELS['Executive']

  const levelScore = profileLevel >= jobLevel ? 1 : profileLevel / jobLevel

  return Math.min(
    1,
    yearsScore * MATCH_WEIGHTS.experience.years +
      levelScore * MATCH_WEIGHTS.experience.level
  )
}

const calculateEducationMatch = (job, profile) => {
  if (!job.required_education) return 1

  const requiredLevel = EDUCATION_LEVELS[job.required_education] || 1
  const profileLevel = EDUCATION_LEVELS[profile.highest_education] || 1

  return profileLevel >= requiredLevel ? 1 : profileLevel / requiredLevel
}

const calculateLocationMatch = (job, profile) => {
  if (!job.work_policy || !profile.location) return 1

  if (
    profile.work_preference?.toLowerCase().includes('remote') &&
    job.work_policy.toLowerCase() === 'remote'
  ) {
    return 1
  }

  switch (job.work_policy.toLowerCase()) {
    case 'remote':
      return 1
    case 'hybrid':
      return profile.location === job.location
        ? 1
        : MATCH_WEIGHTS.location.hybrid
    default:
      return profile.location === job.location
        ? 1
        : MATCH_WEIGHTS.location.onsite
  }
}
const calculateIndustryMatch = (job, profile, employerProfile) => {
  // Industry Matching
  const seekerIndustries = new Set(
    [...(profile.industries || [])]
      .filter((i) => typeof i === 'string')
      .map((i) => i.toLowerCase())
  )

  const workHistoryIndustries = new Set(
    (profile.work_history || [])
      .flatMap((job) => job.industry || [])
      .filter((i) => typeof i === 'string')
      .map((i) => i.toLowerCase())
  )

  const employerIndustry = employerProfile?.industry
    ? typeof employerProfile.industry === 'string'
      ? [employerProfile.industry]
      : employerProfile.industry
    : []

  const industryMatches = new Set(
    [...employerIndustry]
      .filter((i) => typeof i === 'string')
      .map((i) => i.toLowerCase())
  )

  // Subindustry to Company Type Matching
  const employerSubindustry = employerProfile?.subindustry
    ? typeof employerProfile.subindustry === 'string'
      ? [employerProfile.subindustry]
      : employerProfile.subindustry
    : []

  const seekerCompanyTypes = new Set(
    (profile.work_history || [])
      .flatMap((job) => job.company_type || [])
      .filter((t) => typeof t === 'string')
      .map((t) => t.toLowerCase())
  )

  const subindustryMatches = new Set(
    [...employerSubindustry]
      .filter((s) => typeof s === 'string')
      .map((s) => s.toLowerCase())
  )

  // If no matching data is available
  if (!industryMatches.size && !subindustryMatches.size) return 0
  if (
    !seekerIndustries.size &&
    !workHistoryIndustries.size &&
    !seekerCompanyTypes.size
  )
    return 0

  // Calculate Industry Score
  const industryScore =
    industryMatches.size > 0
      ? [...industryMatches].filter(
          (ind) => seekerIndustries.has(ind) || workHistoryIndustries.has(ind)
        ).length / industryMatches.size
      : 0

  // Calculate Subindustry/Company Type Score
  const subindustryScore =
    subindustryMatches.size > 0
      ? [...subindustryMatches].filter((sub) => seekerCompanyTypes.has(sub))
          .length / subindustryMatches.size
      : 0

  // Weight the scores
  const weightedScore =
    industryScore * MATCH_WEIGHTS.industry.direct +
    subindustryScore * MATCH_WEIGHTS.industry.related

  return Math.min(1, weightedScore)
}
const calculateCompensationMatch = (job, profile) => {
  if (
    !job.salary?.range_start ||
    !job.salary?.range_end ||
    !profile.minimum_salary
  ) {
    return 1
  }

  const jobMax = job.salary.range_end
  const jobMin = job.salary.range_start
  const minRequired = profile.minimum_salary

  if (jobMax < minRequired) return 0
  if (jobMin >= minRequired) return 1

  return 0.7
}

export default Match
