// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyA3J_3s90AyXkh9_8fUcScAglMsjVKIZ0Q',
  authDomain: 'arena-40a49.firebaseapp.com',
  projectId: 'arena-40a49',
  storageBucket: 'arena-40a49.firebasestorage.app',
  messagingSenderId: '1027013689039',
  appId: '1:1027013689039:web:1ff65934b0ba1c77ba01ba',
  measurementId: 'G-4BKKPNZYKZ'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)
const functions = getFunctions(app)

export { app, analytics, auth, db, storage, functions }
