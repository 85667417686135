import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const PageContainer = styled.div`
  flex-grow: 1;
  background-color: #f8f9fa;
  overflow-y: auto;
`

const ContentContainer = styled.div`
  flex: 1;
  padding: 1rem 3rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const CardContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const Card = styled.div`
  width: 300px; // Set a fixed width for consistency
  padding: 1rem;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: all 0.3s ease;
  flex: 1;
  border: 2px solid #6b46c1;

  &:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    width: 90%; // Full width on mobile for better stacking
    margin-bottom: 1rem;
  }
`

const TableTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
`

const MostPopularTag = styled.span`
  position: absolute;
  border-radius: 0px 0px 0px 8px;
  top: 0px;
  right: 0px;
  background-color: #6b46c1;
  color: #ffffff;
  padding: 8px 12px;
  font-size: 12px;
  font-weight: bold;
  width: 5rem;
`

const BulletPoint = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.6;
  font-size: 9pt;

  svg {
    margin-right: 10px;
  }
`

const CardDescription = styled.p`
  font-size: 14px;
  color: #6b46c1;
  margin-bottom: 16px;
  height: 30px;
`

const CardPrice = styled.p`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 10px;
`

const CardButton = styled.button`
  background-color: #6b46c1;
  color: #ffffff;
  font-weight: bold;
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
`

const BulletContainerTitle = styled.h3`
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`

const BulletPointContainer = styled.div`
  text-align: left;
  padding: 10px;
`

export default function EmployerPricing() {
  const [selectedPlan, setSelectedPlan] = useState(null)

  const handlePlanClick = (plan) => {
    setSelectedPlan(plan)
  }

  const handleOpenUpgradeLink = () => {
    window.open('https://meetings.hubspot.com/parul-khosla/schedule-a-demo')
  }

  return (
    <PageWrapper>
      <PageContainer>
        <ContentContainer>
          <CardContainer>
            {/* Free Trial */}
            <Card>
              <TableTitle>Starter</TableTitle>
              <CardDescription>No credit card required</CardDescription>
              <CardPrice>Free for 14 days</CardPrice>
              <CardButton
                onClick={() =>
                  window.open('https://app.arenatalent.com', '_blank')
                }
              >
                Start Free Trial
              </CardButton>
              <BulletPointContainer>
                <BulletContainerTitle>Includes:</BulletContainerTitle>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />{' '}
                  <strong style={{ marginRight: '4px' }}>1</strong> recruiter
                  seat
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />{' '}
                  <strong style={{ marginRight: '4px' }}>1</strong> job slot
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Employer profile
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Direct messaging
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Applicant tracking
                </BulletPoint>
                <BulletPoint
                  style={{
                    backgroundColor: '#E9D9F1',
                    padding: '5px',
                    borderRadius: '4px',
                    marginLeft: '-4px'
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} /> AI applicant matches
                </BulletPoint>
                <BulletPoint
                  style={{
                    backgroundColor: '#E9D9F1',
                    padding: '5px',
                    borderRadius: '4px',
                    marginLeft: '-4px'
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} /> AI candidate sourcing
                  tool
                </BulletPoint>
                <BulletPoint
                  style={{
                    backgroundColor: '#E9D9F1',
                    padding: '5px',
                    borderRadius: '4px',
                    marginLeft: '-4px'
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} /> Real-time candidate
                  insights
                </BulletPoint>
              </BulletPointContainer>
            </Card>

            {/* Premium Plan */}
            <Card onClick={() => handlePlanClick('premium')}>
              <MostPopularTag>Most Flexible</MostPopularTag>
              <TableTitle>Premium</TableTitle>
              <CardDescription>For focused hiring teams</CardDescription>
              <CardPrice>
                $750<span style={{ fontSize: '16px' }}>/month</span>
              </CardPrice>
              <CardButton onClick={handleOpenUpgradeLink}>
                Book a Demo
              </CardButton>
              <BulletPointContainer>
                <BulletContainerTitle>Includes:</BulletContainerTitle>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />{' '}
                  <strong style={{ marginRight: '4px' }}>2</strong> recruiter
                  seats
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />{' '}
                  <strong style={{ marginRight: '4px' }}>5</strong> rotating job
                  slots
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Employer profile
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Direct messaging
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Applicant tracking
                </BulletPoint>
                <BulletPoint
                  style={{
                    backgroundColor: '#E9D9F1',
                    padding: '5px',
                    borderRadius: '4px',
                    marginLeft: '-4px'
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} /> AI applicant matches
                </BulletPoint>
                <BulletPoint
                  style={{
                    backgroundColor: '#E9D9F1',
                    padding: '5px',
                    borderRadius: '4px',
                    marginLeft: '-4px'
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} /> AI candidate sourcing
                  tool
                </BulletPoint>
                <BulletPoint
                  style={{
                    backgroundColor: '#E9D9F1',
                    padding: '5px',
                    borderRadius: '4px',
                    marginLeft: '-4px'
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} /> Real-time candidate
                  insights
                </BulletPoint>
                <BulletPoint
                  style={{
                    backgroundColor: '#E9D9F1',
                    padding: '5px',
                    borderRadius: '4px',
                    marginLeft: '-4px'
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} /> Dedicated account
                  support
                </BulletPoint>
              </BulletPointContainer>
            </Card>

            {/* Enterprise Plan */}
            <Card
              onClick={() => handlePlanClick('enterprise')}
              style={{
                border: '2px solid #6b46c1',
                padding: 'calc(1rem - 1px)' // Compensates for border width
              }}
            >
              <TableTitle>Enterprise</TableTitle>
              <CardDescription>
                For growing & scaling organizations
              </CardDescription>
              <CardPrice>Contact for pricing</CardPrice>
              <CardButton onClick={handleOpenUpgradeLink}>
                Book a Demo
              </CardButton>
              <BulletPointContainer>
                <BulletContainerTitle>Includes:</BulletContainerTitle>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />{' '}
                  <strong style={{ marginRight: '4px' }}>Unlimited</strong>{' '}
                  recruiter seats
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} />{' '}
                  <strong style={{ marginRight: '4px' }}>Unlimited</strong> job
                  slots
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Employer profile
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Direct messaging
                </BulletPoint>
                <BulletPoint>
                  <FontAwesomeIcon icon={faCheckCircle} /> Applicant tracking
                </BulletPoint>
                <BulletPoint
                  style={{
                    backgroundColor: '#E9D9F1',
                    padding: '5px',
                    borderRadius: '4px',
                    marginLeft: '-4px'
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} /> AI applicant matches
                </BulletPoint>
                <BulletPoint
                  style={{
                    backgroundColor: '#E9D9F1',
                    padding: '5px',
                    borderRadius: '4px',
                    marginLeft: '-4px'
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} /> AI candidate sourcing
                  tool
                </BulletPoint>
                <BulletPoint
                  style={{
                    backgroundColor: '#E9D9F1',
                    padding: '5px',
                    borderRadius: '4px',
                    marginLeft: '-4px'
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} /> Real-time candidate
                  insights
                </BulletPoint>
                <BulletPoint
                  style={{
                    backgroundColor: '#E9D9F1',
                    padding: '5px',
                    borderRadius: '4px',
                    marginLeft: '-4px'
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} /> Dedicated account
                  support
                </BulletPoint>
                <BulletPoint
                  style={{
                    backgroundColor: '#E9D9F1',
                    padding: '5px',
                    borderRadius: '4px',
                    marginLeft: '-4px'
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} /> Custom ATS
                  Integration
                </BulletPoint>
                <BulletPoint
                  style={{
                    backgroundColor: '#E9D9F1',
                    padding: '5px',
                    borderRadius: '4px',
                    marginLeft: '-4px'
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} /> Featured jobs on
                  platform
                </BulletPoint>
                <BulletPoint
                  style={{
                    backgroundColor: '#E9D9F1',
                    padding: '5px',
                    borderRadius: '4px',
                    marginLeft: '-4px'
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} /> Featured jobs on
                  social media
                </BulletPoint>
                <BulletPoint
                  style={{
                    backgroundColor: '#E9D9F1',
                    padding: '5px',
                    borderRadius: '4px',
                    marginLeft: '-4px'
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} /> Access to recruiting
                  events
                </BulletPoint>
              </BulletPointContainer>
            </Card>
          </CardContainer>
        </ContentContainer>
      </PageContainer>
    </PageWrapper>
  )
}
