import React, { useEffect, useState, useCallback, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import EmployerNav from './EmployerNav'
import { useNavigate } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import {
  createOrUpdateEmployerProfile,
  uploadCompanyLogo,
  uploadCompanyVideo,
  checkVideoDuration
} from '../../models/EmployerProfile'
import { auth } from '../../firebase'
import { ChevronDown, ChevronUp, Upload, Video, X } from 'lucide-react'

const MAX_VIDEO_DURATION = 240 // 4 minutes in seconds
const MAX_VIDEO_SIZE = 100 * 1024 * 1024 // 100MB in bytes
const MAX_LOGO_SIZE = 5 * 1024 * 1024 // 5MB in bytes

// At the top of your file
const industries = ['Sports', 'Media', 'Entertainment', 'Fashion', 'Technology']

const allSubcategories = {
  Sports: [
    'Professional Sports',
    'College Sports',
    'E-Sports',
    'Sports Technology',
    'Sports Marketing',
    'Sports Management',
    'Sports Medicine',
    'Fitness',
    'Outdoor Sports',
    'Recreational Sports',
    'Sports Analytics',
    'Youth Sports',
    'Athlete Representation',
    'Stadium Operations',
    'Event Management',
    'Sports Sponsorships',
    'Sports Broadcasting',
    'Fan Engagement',
    'Team Operations'
  ],
  Media: [
    'Broadcast Media',
    'Digital Media',
    'Publishing',
    'Advertising',
    'Journalism',
    'Social Media',
    'Streaming Services',
    'Podcasting',
    'Film Journalism',
    'Investigative Reporting',
    'Public Relations',
    'Content Creation',
    'Media Buying',
    'Media Planning',
    'News Production',
    'Multimedia Journalism',
    'Interactive Media',
    'Community Management',
    'Influencer Marketing'
  ],
  Entertainment: [
    'Film Production',
    'Music',
    'Gaming',
    'Live Events',
    'Theater',
    'Animation',
    'Virtual Reality',
    'Theme Parks',
    'Television Production',
    'Documentary Filmmaking',
    'Concert Promotion',
    'Talent Management',
    'Celebrity Management',
    'Film Distribution',
    'Cinematography',
    'Sound Design',
    'Set Design',
    'Voice Acting',
    'Content Distribution',
    'Entertainment Law',
    'Reality TV',
    'Music Production',
    'Film Scoring',
    'Comedy'
  ],
  Fashion: [
    'Luxury Fashion',
    'Streetwear',
    'Sportswear',
    'Fashion Technology',
    'Sustainable Fashion',
    'Accessories',
    'Cosmetics',
    'Fashion Media',
    'Apparel Design',
    'Textile Design',
    'Retail Fashion',
    'Fashion Merchandising',
    'Fashion Photography',
    'Fashion Blogging',
    'Runway Shows',
    'Fashion Buying',
    'E-commerce Fashion',
    'Fashion Consulting',
    'Pattern Making',
    'Fashion Illustration',
    'Costume Design',
    'Footwear Design',
    'Fashion PR',
    'Jewelry Design'
  ],
  Technology: ['Media Tech', 'Entertainment Tech', 'Fashion Tech']
}

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

  body {
    font-family: 'Open Sans', sans-serif;
  }
`

const softColors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#CAAAE1',
  secondary: '#f6e05e',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0',
  success: '#68d391',
  warning: '#f6ad55',
  danger: '#fc8181',
  info: '#63b3ed',
  icons: '#12C2E8',
  icontext: '#C859FF',
  yellow: '#f6e05e'
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: ${softColors.background};
  color: ${softColors.text};
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem;
  overflow-y: scroll;
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`

const Card = styled.div`
  background-color: ${softColors.card};
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    padding: 0.5rem 0.75rem;
  }
`

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: ${softColors.text};
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const SubTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: ${softColors.text};
`

const Note = styled.p`
  color: ${softColors.icontext};
  font-size: 1rem;
  margin-bottom: 2rem;
`

const FormGroup = styled.div`
  margin-bottom: 2rem;
`

const Label = styled.label`
  display: block;
  margin-bottom: 0.75rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: ${softColors.text};
`

const RequiredLabel = styled.span`
  color: red;
  margin-left: 0.25rem;
`

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid ${softColors.border};
  background-color: ${softColors.card};
  font-size: 1rem;
  color: ${softColors.text};
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: ${softColors.primary};
    border: 2px solid ${softColors.primary};
  }
`

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid ${softColors.border};
  background-color: ${softColors.card};
  font-size: 1rem;
  color: ${softColors.text};
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: ${softColors.primary};
  }
`

const BubbleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
`

const Bubble = styled.div`
  background-color: ${(props) =>
    props.selected ? softColors.primary : softColors.background};
  color: ${(props) => (props.selected ? softColors.card : softColors.text)};
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  &:hover {
    background-color: ${(props) =>
      props.selected ? softColors.primary : softColors.border};
  }
`

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  background-color: ${softColors.primary};
  color: white;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${softColors.icontext};
  }

  &:disabled {
    background-color: ${softColors.border};
    cursor: not-allowed;
  }
`

const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`

const CollapsibleSection = styled.div`
  margin-bottom: 1.5rem;
`

const CollapsibleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.2rem;
  padding-left: 0.5rem;
  background-color: ${softColors.background};
  border-radius: 0.5rem;
`

const CollapsibleContent = styled.div`
  padding: 1rem;
  background-color: ${softColors.card};
  border-radius: 0 0 0.5rem 0.5rem;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`

const ErrorMessage = styled.div`
  color: ${softColors.danger};
  margin-top: 1rem;
  padding: 1rem;
  background-color: #fff1f0;
  border-radius: 0.5rem;
  border: 1px solid ${softColors.danger};
`

const DropzoneContainer = styled.div`
  border: 2px dashed ${softColors.border};
  border-radius: 0.5rem;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.2s;

  &:hover {
    border-color: ${softColors.primary};
  }
`

const DropzoneText = styled.p`
  color: ${softColors.textLight};
  margin-bottom: 1rem;
`

const UploadIcon = styled(Upload)`
  color: ${softColors.primary};
  margin-bottom: 1rem;
`

const FilePreview = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`

const FilePreviewImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 1rem;
  border-radius: 0.25rem;
`

const FilePreviewName = styled.span`
  color: ${softColors.text};
`

const VideoPreview = styled.video`
  max-width: 100%;
  max-height: 400px;
  margin-top: 1rem;
  border-radius: 0.5rem;
`

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${softColors.border};
  border-radius: 2px;
  margin-top: 1rem;
`

const ProgressFill = styled.div`
  height: 100%;
  background-color: ${softColors.primary};
  border-radius: 2px;
  transition: width 0.3s ease;
  width: ${(props) => props.progress}%;
`

const StatusMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  color: ${(props) => (props.error ? softColors.danger : softColors.text)};
  font-size: 0.875rem;
`
const RemoveButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: ${softColors.danger};
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: #e53e3e;
  }
`
const VideoContainer = styled.div`
  position: relative;
  margin-top: 1rem;
`

const StyledTextarea = styled.textarea`
  width: 95%;
  padding: 0.75rem;
  border: 1px solid ${softColors.border};
  border-radius: 0.5rem;
  min-height: 150px;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  color: ${softColors.text};
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border: 2px solid #caaae1;
  }

  &::placeholder {
    color: ${softColors.textLight};
  }
`

const EmployerIntakeForm = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [formData, setFormData] = useState({
    company_email: '',
    company_phone: '',
    company_address: '',
    company_website: '',
    company_linkedin: '',
    company_facebook: '',
    additional_url: '',
    full_time_employees: '',
    expected_full_time_hires: '',
    expected_part_time_hires: '',
    hiring_goals: {},
    hiring_challenges: [],
    candidate_qualities: [],
    company_logo: null,
    other_hiring_goals: '',
    hiring_challenges_other: '',
    candidate_qualities_other: '',
    company_description: '',
    work_from_home_policy: '',
    core_values: [],
    video: null,
    videoUrl: null,
    industry: [],
    sub_industry: []
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [openSections, setOpenSections] = useState({})
  const navigate = useNavigate()
  const [uploadProgress, setUploadProgress] = useState(0)
  const [uploadStatus, setUploadStatus] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const videoRef = useRef(null)

  const toggleSection = (section) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }))
  }

  const sanitizeFilename = (filename) => {
    return filename.replace(/\s+/g, '_').toLowerCase()
  }

  const handleBubbleSelect = (category, item) => {
    setFormData((prevData) => {
      if (category === 'hiring_goals') {
        const updatedHiringGoals = { ...prevData.hiring_goals }
        if (!updatedHiringGoals[item.category]) {
          updatedHiringGoals[item.category] = []
        }
        const index = updatedHiringGoals[item.category].indexOf(item.value)
        if (index > -1) {
          updatedHiringGoals[item.category] = updatedHiringGoals[
            item.category
          ].filter((i) => i !== item.value)
        } else {
          updatedHiringGoals[item.category] = [
            ...updatedHiringGoals[item.category],
            item.value
          ]
        }
        return { ...prevData, hiring_goals: updatedHiringGoals }
      } else {
        const updatedCategory = Array.isArray(prevData[category])
          ? [...prevData[category]]
          : []
        const index = updatedCategory.indexOf(item)
        if (index > -1) {
          updatedCategory.splice(index, 1)
        } else {
          updatedCategory.push(item)
        }
        return { ...prevData, [category]: updatedCategory }
      }
    })
  }

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]

      // Create a new File object with sanitized name
      const sanitizedFile = new File([file], sanitizeFilename(file.name), {
        type: file.type,
        lastModified: file.lastModified
      })

      setFormData((prevData) => ({
        ...prevData,
        company_logo: sanitizedFile
      }))
      setErrorMessage('')
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false
  })

  const {
    getRootProps: getRootVideoProps,
    getInputProps: getVideoInputProps,
    isDragActive: isVideoDragActive
  } = useDropzone({
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0]

        try {
          setIsUploading(true)
          setUploadStatus('Checking file...')
          setUploadProgress(0)

          // Validate file size
          if (file.size > MAX_VIDEO_SIZE) {
            throw new Error('Video file must be less than 100MB')
          }

          // Check duration
          await checkVideoDuration(file)
          setUploadProgress(20)

          // Upload video
          setUploadStatus('Uploading video...')
          const videoUrl = await uploadCompanyVideo(file, (progress) => {
            setUploadProgress(20 + progress * 0.8)
          })

          setFormData((prev) => ({
            ...prev,
            video: file,
            videoUrl: videoUrl
          }))

          setUploadStatus('Upload complete!')
          setUploadProgress(100)
          setErrorMessage('')
        } catch (error) {
          setUploadStatus(
            "There's an error uploading your video. Please try again or email support@arenatalent.com"
          )
          setErrorMessage(
            "There's an error uploading your video. Please try again or email support@arenatalent.com"
          )
          setUploadProgress(0)
        } finally {
          setIsUploading(false)
        }
      }
    },
    accept: {
      'video/*': ['.mp4', '.mov', '.avi']
    },
    multiple: false
  })

  const checkVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video')
      video.preload = 'metadata'

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src)
        if (video.duration > MAX_VIDEO_DURATION) {
          reject('Video must be less than 4 minutes long')
        } else {
          resolve(video.duration)
        }
      }

      video.onerror = () => {
        reject('Error verifying video duration')
      }

      video.src = URL.createObjectURL(file)
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (!formData.company_logo) {
        setErrorMessage('Please upload a company logo')
        return
      }

      if (formData.company_logo.size > MAX_LOGO_SIZE) {
        setErrorMessage('Logo file size exceeds 5MB limit')
        return
      }

      // Upload logo
      let logoUrl = null
      if (formData.company_logo) {
        logoUrl = await uploadCompanyLogo(formData.company_logo, (progress) => {
          setUploadProgress(progress)
        })
      }

      // Prepare profile data
      const profileData = { ...formData }
      delete profileData.company_logo
      delete profileData.video

      if (logoUrl) {
        profileData.company_logo_url = logoUrl
      }

      // Create/update profile
      await createOrUpdateEmployerProfile(profileData)

      // Navigate to dashboard
      navigate('/employer-dashboard')
    } catch (error) {
      console.error('Error updating profile:', error)
      setErrorMessage(
        'Uh oh something went wrong. Please reach out to support@arenatalent.com'
      )
    }
  }

  const renderSlide = () => {
    switch (currentSlide) {
      case 0:
        return (
          <>
            <FormGroup>
              <Label htmlFor="company_website">
                Company Website <RequiredLabel>*</RequiredLabel>
              </Label>
              <Input
                type="url"
                id="company_website"
                name="company_website"
                value={formData.company_website}
                onChange={(e) =>
                  setFormData({ ...formData, company_website: e.target.value })
                }
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="company_linkedin">
                Company LinkedIn <RequiredLabel>*</RequiredLabel>
              </Label>
              <Input
                type="url"
                id="company_linkedin"
                name="company_linkedin"
                value={formData.company_linkedin}
                onChange={(e) =>
                  setFormData({ ...formData, company_linkedin: e.target.value })
                }
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="additional_url">Additional URL</Label>
              <Input
                type="url"
                id="additional_url"
                name="additional_url"
                value={formData.additional_url}
                onChange={(e) =>
                  setFormData({ ...formData, additional_url: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="company_facebook">Additional URL</Label>
              <Input
                type="url"
                id="company_facebook"
                name="company_facebook"
                value={formData.company_facebook}
                onChange={(e) =>
                  setFormData({ ...formData, company_facebook: e.target.value })
                }
              />
            </FormGroup>
          </>
        )
      case 1:
        return (
          <>
            <FormGroup>
              <Label htmlFor="full_time_employees">
                How Many Full-Time Employees Does Your Company Have?{' '}
                <RequiredLabel>*</RequiredLabel>
              </Label>
              <Select
                id="full_time_employees"
                name="full_time_employees"
                value={formData.full_time_employees}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    full_time_employees: e.target.value
                  })
                }
                required
              >
                <option value="">Select an option</option>
                <option value="1-10">1-10</option>
                <option value="11-50">11-50</option>
                <option value="51-200">51-200</option>
                <option value="201-500">201-500</option>
                <option value="500+">500+</option>
              </Select>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="expected_full_time_hires">
                How many full-time roles do you expect to hire for in the next
                year? <RequiredLabel>*</RequiredLabel>
              </Label>
              <Select
                id="expected_full_time_hires"
                name="expected_full_time_hires"
                value={formData.expected_full_time_hires}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    expected_full_time_hires: e.target.value
                  })
                }
                required
              >
                <option value="">Select an option</option>
                <option value="0">0</option>
                <option value="1-5">1-5</option>
                <option value="5-10">5-10</option>
                <option value="10-20">10-20</option>
                <option value="20-50">20-50</option>
                <option value="50-80">50-80</option>
                <option value="80-100">80-100</option>
                <option value="100+">100+</option>
              </Select>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="expected_part_time_hires">
                How many part-time, seasonal, or internship roles do you expect
                to hire for in the next year? <RequiredLabel>*</RequiredLabel>
              </Label>
              <Select
                id="expected_part_time_hires"
                name="expected_part_time_hires"
                value={formData.expected_part_time_hires}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    expected_part_time_hires: e.target.value
                  })
                }
                required
              >
                <option value="">Select an option</option>
                <option value="0">0</option>
                <option value="1-10">1-10</option>
                <option value="10-20">10-20</option>
                <option value="20-40">20-40</option>
                <option value="40-60">40-60</option>
                <option value="60-100">60-100</option>
                <option value="100-150">100-150</option>
                <option value="150+">150+</option>
              </Select>
            </FormGroup>
          </>
        )
      case 2:
        const hiringGoals = {
          'Diverse candidates': [
            'Female/Non-Binary',
            'Black',
            'Asian',
            'Hispanic/Latino',
            'Indigenous',
            'Native Hawaiian/Pacific Islander',
            'LGBTQIA+',
            'Veteran',
            'People With Disabilities'
          ],
          Seniority: [
            'Students',
            'Entry-Level',
            'Mid-Level',
            'Senior Executive'
          ],
          'Job Functions': [
            'AI/Tech/Engineering',
            'Business Development',
            'Communications/PR',
            'Content/Journalism',
            'Data Analytics',
            'Finance/Accounting',
            'Gaming/Esports',
            'Human Resources/DEIB',
            'Legal',
            'Marketing',
            'Philanthropy',
            'Production/Creative',
            'Sales',
            'Strategy/Operations',
            'Talent Management',
            'Other (tell us)'
          ],
          'Job Types': [
            'Full-Time',
            'Part-Time/Seasonal',
            'Freelance/Contract',
            'Intern'
          ]
        }

        return (
          <>
            <SubTitle>
              What are your hiring goals? (Select all that apply)
              <RequiredLabel>*</RequiredLabel>
            </SubTitle>
            {Object.entries(hiringGoals).map(([category, items]) => (
              <CollapsibleSection key={category}>
                <CollapsibleHeader onClick={() => toggleSection(category)}>
                  <Label style={{ marginBottom: '0px' }}>{category}</Label>
                  {openSections[category] ? <ChevronUp /> : <ChevronDown />}
                </CollapsibleHeader>
                <CollapsibleContent isOpen={openSections[category]}>
                  <BubbleWrapper>
                    {items.map((item) => (
                      <Bubble
                        key={item}
                        selected={formData.hiring_goals[category]?.includes(
                          item
                        )}
                        onClick={() =>
                          handleBubbleSelect('hiring_goals', {
                            category,
                            value: item
                          })
                        }
                      >
                        {item}
                        {formData.hiring_goals[category]?.includes(item) &&
                          ' ✓'}
                      </Bubble>
                    ))}
                  </BubbleWrapper>
                </CollapsibleContent>
              </CollapsibleSection>
            ))}
            <FormGroup>
              <Label>Other hiring goals</Label>
              <Input
                type="text"
                placeholder="Specify other hiring goals"
                value={formData.other_hiring_goals || ''}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    other_hiring_goals: e.target.value
                  }))
                }
              />
            </FormGroup>
          </>
        )
      case 3:
        const hiringChallenges = [
          'ATS inefficiencies',
          'Attracting diverse talent',
          'Employer branding',
          'Finding quality talent',
          'Lack of industry data',
          'Limited access to desired talent',
          'Low budget',
          'Low resources',
          'Offer rejections',
          'Salary transparency',
          'Sourcing talent',
          'Time-to-hire',
          'Volume of applications',
          'Other (tell us)'
        ]

        return (
          <>
            {/* Industry & Sub-Industry Selection */}
            <SubTitle>Industry & Sub-Industries</SubTitle>
            <SubTitle>Industries</SubTitle>
            <BubbleWrapper>
              {industries.map((industry) => (
                <Bubble
                  key={industry}
                  selected={formData.industry.includes(industry)}
                  onClick={() => {
                    const updatedIndustries = formData.industry.includes(
                      industry
                    )
                      ? formData.industry.filter((ind) => ind !== industry)
                      : [...formData.industry, industry]
                    setFormData((prevData) => ({
                      ...prevData,
                      industry: updatedIndustries,
                      // Filter sub-industries to only include those for selected industries
                      sub_industry: prevData.sub_industry.filter((subInd) =>
                        updatedIndustries.some((ind) =>
                          allSubcategories[ind].includes(subInd)
                        )
                      )
                    }))
                  }}
                >
                  {industry}
                  {formData.industry.includes(industry) && ' ✓'}
                </Bubble>
              ))}
            </BubbleWrapper>

            <SubTitle>Company Type</SubTitle>
            <BubbleWrapper>
              {formData.industry
                .flatMap((industry) => allSubcategories[industry] || [])
                .map((subCategory) => (
                  <Bubble
                    key={subCategory}
                    selected={formData.sub_industry.includes(subCategory)}
                    onClick={() =>
                      handleBubbleSelect('sub_industry', subCategory)
                    }
                  >
                    {subCategory}
                    {formData.sub_industry.includes(subCategory) && ' ✓'}
                  </Bubble>
                ))}
            </BubbleWrapper>
            <SubTitle>
              What are your current challenges in the hiring process?
              <RequiredLabel>*</RequiredLabel>
            </SubTitle>
            <BubbleWrapper>
              {hiringChallenges.map((challenge) => (
                <Bubble
                  key={challenge}
                  selected={formData.hiring_challenges?.includes(challenge)}
                  onClick={() =>
                    handleBubbleSelect('hiring_challenges', challenge)
                  }
                >
                  {challenge}
                </Bubble>
              ))}
            </BubbleWrapper>

            {formData.hiring_challenges.includes('Other (tell us)') && (
              <Input
                type="text"
                placeholder="Specify other challenges"
                value={formData.hiring_challenges_other || ''}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    hiring_challenges_other: e.target.value
                  }))
                }
              />
            )}
          </>
        )
      case 4:
        return (
          <>
            <SubTitle>Tell us about your company</SubTitle>
            <FormGroup>
              <Label htmlFor="company_description">
                Company Description <RequiredLabel>*</RequiredLabel>
              </Label>
              <StyledTextarea
                id="company_description"
                value={formData.company_description}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    company_description: e.target.value
                  })
                }
                placeholder="Tell us about your company..."
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="work_from_home_policy">
                Work From Home Policy <RequiredLabel>*</RequiredLabel>
              </Label>
              <Select
                id="work_from_home_policy"
                value={formData.work_from_home_policy}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    work_from_home_policy: e.target.value
                  })
                }
                required
              >
                <option value="">Select a policy</option>
                <option value="remote">Remote</option>
                <option value="in_office">In Office</option>
                <option value="hybrid">Hybrid</option>
              </Select>
            </FormGroup>

            <FormGroup>
              <Label>
                Company Core Values (Select up to 3){' '}
                <RequiredLabel>*</RequiredLabel>
              </Label>
              <BubbleWrapper>
                {[
                  'Innovation',
                  'Integrity',
                  'Collaboration',
                  'Excellence',
                  'Customer Focus',
                  'Diversity & Inclusion',
                  'Accountability',
                  'Sustainability',
                  'Growth Mindset',
                  'Work-Life Balance',
                  'Transparency',
                  'Empowerment'
                ].map((value) => (
                  <Bubble
                    key={value}
                    selected={formData.core_values?.includes(value)}
                    onClick={() => {
                      if (
                        !formData.core_values?.includes(value) &&
                        formData.core_values?.length >= 3
                      ) {
                        return
                      }
                      setFormData((prev) => ({
                        ...prev,
                        core_values: prev.core_values?.includes(value)
                          ? prev.core_values.filter((v) => v !== value)
                          : [...(prev.core_values || []), value]
                      }))
                    }}
                    disabled={
                      formData.core_values?.length >= 3 &&
                      !formData.core_values.includes(value)
                    }
                  >
                    {value}
                  </Bubble>
                ))}
              </BubbleWrapper>
            </FormGroup>
          </>
        )
      case 5:
        return (
          <>
            <SubTitle>Upload Company Video</SubTitle>
            <Note>Share a video about your company (max 4 minutes)</Note>
            <FormGroup>
              <DropzoneContainer {...getRootVideoProps()}>
                <input {...getVideoInputProps()} accept="video/*" />
                <Video size={48} color={softColors.primary} />
                {isVideoDragActive ? (
                  <DropzoneText>Drop your video here ...</DropzoneText>
                ) : (
                  <DropzoneText>
                    Drag 'n' drop your company video here, or click to select
                  </DropzoneText>
                )}
                <DropzoneText>
                  (Maximum duration: 4 minutes, Maximum size: 100MB)
                </DropzoneText>
              </DropzoneContainer>

              {isUploading && (
                <>
                  <ProgressBar>
                    <ProgressFill progress={uploadProgress} />
                  </ProgressBar>
                  <StatusMessage>{uploadStatus}</StatusMessage>
                </>
              )}

              {formData.videoUrl && !isUploading && (
                <VideoContainer>
                  <VideoPreview
                    ref={videoRef}
                    src={formData.videoUrl}
                    controls
                    preload="metadata"
                  />
                  <RemoveButton
                    type="button"
                    onClick={(e) => {
                      e.preventDefault()
                      setFormData((prev) => ({
                        ...prev,
                        video: null,
                        videoUrl: null
                      }))
                    }}
                    aria-label="Remove video"
                  >
                    <X size={16} />
                  </RemoveButton>
                </VideoContainer>
              )}

              {uploadStatus && uploadStatus.startsWith('Error') && (
                <StatusMessage error>{uploadStatus}</StatusMessage>
              )}
            </FormGroup>
          </>
        )
      case 6:
        return (
          <>
            <SubTitle>
              Upload Company Logo <RequiredLabel>*</RequiredLabel>
            </SubTitle>
            <FormGroup>
              <DropzoneContainer {...getRootProps()}>
                <input {...getInputProps()} />
                <UploadIcon size={48} />
                {isDragActive ? (
                  <DropzoneText>Drop the logo here ...</DropzoneText>
                ) : (
                  <DropzoneText>
                    Drag 'n' drop your logo here, or click to select a file
                  </DropzoneText>
                )}
                <DropzoneText>
                  (Accepted formats: PNG, JPG, GIF. Max size: 5MB)
                </DropzoneText>
              </DropzoneContainer>
              {formData.company_logo && (
                <FilePreview>
                  <FilePreviewImage
                    src={URL.createObjectURL(formData.company_logo)}
                    alt="Logo preview"
                  />
                  <FilePreviewName>
                    {formData.company_logo.name}
                  </FilePreviewName>
                </FilePreview>
              )}
            </FormGroup>
          </>
        )
      default:
        return null
    }
  }

  const requiredFieldsFilled = () => {
    switch (currentSlide) {
      case 0:
        return formData.company_website && formData.company_linkedin
      case 1:
        return (
          formData.full_time_employees &&
          formData.expected_full_time_hires &&
          formData.expected_part_time_hires
        )
      case 4:
        return (
          formData.company_description &&
          formData.work_from_home_policy &&
          formData.core_values?.length > 0 &&
          formData.core_values?.length <= 3
        )
      default:
        return true
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser)
      setLoading(false)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  if (!user) {
    return <div>Please log in to access this page.</div>
  }

  return (
    <Container>
      <NavWrapper>
        <EmployerNav />
      </NavWrapper>
      <MainContent>
        <Card>
          <Title>Complete Your Company Intake</Title>

          <form onSubmit={handleSubmit}>
            {renderSlide()}
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <NavigationButtons>
              {currentSlide > 0 && (
                <Button
                  type="button"
                  onClick={() => setCurrentSlide(currentSlide - 1)}
                >
                  Previous
                </Button>
              )}
              {currentSlide < 6 ? (
                <Button
                  type="button"
                  onClick={() => setCurrentSlide(currentSlide + 1)}
                  disabled={!requiredFieldsFilled()}
                >
                  Next
                </Button>
              ) : (
                <Button type="submit">Submit</Button>
              )}
            </NavigationButtons>
          </form>
        </Card>
      </MainContent>
    </Container>
  )
}

export default EmployerIntakeForm
