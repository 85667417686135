import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { auth } from '../../firebase'
import { getEmployerJobPostingsWithApplicants } from '../../models/JobPosting' // Updated function
import EmployerNav from './EmployerNav'
import { Plus } from 'lucide-react'

// Styled Components
const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f0f4f8;
  color: #2d3748;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem 3rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #e2e8f0;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0.75rem;
  }
`

const PageTitle = styled.h1`
  font-size: 1.875rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 2rem;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #caaae1;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #b38fd1;
  }
`

const JobPostingsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
  @media (max-width: 768px) {
    padding: 2rem 0rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`

const JobCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e2e8f0;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`

const JobTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 1rem;
`

const JobDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #64748b;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`

const SalaryDisplay = styled.div`
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e2e8f0;
  font-weight: 500;
  color: #1e293b;
`

const ApplicantCount = styled.div`
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e2e8f0;
  font-size: 0.875rem;
  color: #64748b;
`

const TabWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`

const Tab = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: transparent;
  color: ${(props) => (props.active ? '#1e293b' : '#64748b')};
  border: none;
  border-bottom: ${(props) =>
    props.active ? '2px solid #C471ED' : '2px solid transparent'};
  font-size: 1rem;
  font-weight: ${(props) => (props.active ? '600' : '500')};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: #1e293b;
  }

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`

const JobPostings = () => {
  const navigate = useNavigate()
  const [jobPostings, setJobPostings] = useState([])
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('active')

  useEffect(() => {
    const fetchJobPostings = async () => {
      try {
        if (!auth.currentUser) {
          navigate('/login')
          return
        }
        const postings = await getEmployerJobPostingsWithApplicants(
          auth.currentUser.uid
        ) // Use updated function
        setJobPostings(postings)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching job postings:', error)
        setLoading(false)
      }
    }

    fetchJobPostings()
  }, [navigate])

  const filteredJobPostings = jobPostings.filter((job) => {
    if (activeTab === 'active') return job.status === 'active'
    if (activeTab === 'hired') return job.status === 'hired'
    if (activeTab === 'deleted') return job.status === 'deleted'
    return true
  })

  const formatSalary = (salary) => {
    if (salary.type === 'unpaid') return 'Unpaid'
    if (salary.type === 'credit') return 'School Credit'

    const formatNumber = (num) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
      }).format(num)

    const range = `${formatNumber(salary.range_start)} - ${formatNumber(
      salary.range_end
    )}`
    return salary.type === 'hourly' ? `${range}/hr` : range + '/year'
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <Container>
      <NavWrapper>
        <EmployerNav />
      </NavWrapper>
      <MainContent>
        <PageTitle>Job Postings</PageTitle>
        <TopBar>
          <div>
            <TabWrapper>
              <Tab
                active={activeTab === 'active'}
                onClick={() => setActiveTab('active')}
              >
                Active Jobs
              </Tab>
              <Tab
                active={activeTab === 'hired'}
                onClick={() => setActiveTab('hired')}
              >
                Hired Jobs
              </Tab>
              <Tab
                active={activeTab === 'deleted'}
                onClick={() => setActiveTab('deleted')}
              >
                Deleted Jobs
              </Tab>
            </TabWrapper>
          </div>
          <Button onClick={() => navigate('/job-post-form')}>
            <Plus size={16} />
            Create New Job
          </Button>
        </TopBar>

        <JobPostingsGrid>
          {filteredJobPostings.map((job) => (
            <JobCard
              key={job.id}
              onClick={() => navigate(`/job-details/${job.id}`)}
            >
              <JobTitle>{job.title}</JobTitle>
              <JobDetail>{job.department}</JobDetail>
              <JobDetail>
                {job.location} • {job.work_policy}
              </JobDetail>
              <JobDetail>
                {job.type.join(', ')} • {job.level}
              </JobDetail>
              <SalaryDisplay>{formatSalary(job.salary)}</SalaryDisplay>
              <ApplicantCount>
                {job.applicant_count} applicant
                {job.applicant_count !== 1 ? 's' : ''}
              </ApplicantCount>
            </JobCard>
          ))}
        </JobPostingsGrid>
      </MainContent>
    </Container>
  )
}

export default JobPostings
