import { db } from '../firebase'
import {
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  query,
  limit,
  where,
  orderBy,
  startAfter,
  getDocs,
  Timestamp
} from 'firebase/firestore'

const USERS_COLLECTION = 'users'
const PROFILE_SUBCOLLECTION = 'profiles'
const JOBSEEKER_PROFILE_DOC = 'jobseeker'

// User data structure
const createUserObject = (userData) => {
  const now = new Date()
  // Default trial period (2 weeks) if no coupon
  const defaultTrialEnd = new Date(now.getTime() + 14 * 24 * 60 * 60 * 1000)

  return {
    email: userData.email,
    firebase_uid: userData.firebase_uid,
    role: userData.role,
    first_name: userData.first_name,
    last_name: userData.last_name,
    company_name: userData.company_name || null,
    employer_id:
      userData.role === 'employerMember'
        ? userData.employer_id
        : userData.firebase_uid,
    // Subscription fields
    subscription: {
      trial_end:
        userData.subscription?.trial_end || Timestamp.fromDate(defaultTrialEnd),
      job_post_limit: userData.subscription?.job_post_limit || 1,
      plan_type: 'trial', // Starts as trial, will become 'expired' after trial_end
      coupon_used: userData.coupon_code || null,
      created_at: serverTimestamp()
    },
    event_code: userData.event_code || null,
    created_at: serverTimestamp(),
    updated_at: serverTimestamp()
  }
}

// Create a new user
export const createUser = async (userData) => {
  try {
    // Get trial details if coupon provided
    const trialDetails = await validateAndGetTrialDetails(userData.coupon_code)

    // Add subscription details to user data
    userData.subscription = trialDetails

    const userRef = doc(collection(db, USERS_COLLECTION), userData.firebase_uid)
    await setDoc(userRef, createUserObject(userData))

    // Log coupon usage if a coupon was used
    if (trialDetails.coupon_used) {
      await setDoc(
        doc(
          db,
          'coupon_usage',
          `${trialDetails.coupon_used}_${userData.firebase_uid}`
        ),
        {
          coupon_code: trialDetails.coupon_used,
          user_id: userData.firebase_uid,
          used_at: serverTimestamp(),
          trial_end: trialDetails.trial_end
        }
      )
    }

    return userRef
  } catch (error) {
    console.error('Error creating user:', error)
    throw error
  }
}

// Get a user by firebase_uid
export const getUserByFirebaseUid = async (firebase_uid) => {
  const userRef = doc(db, USERS_COLLECTION, firebase_uid)
  const userSnap = await getDoc(userRef)
  if (userSnap.exists()) {
    return { id: userSnap.id, ...userSnap.data() }
  } else {
    return null
  }
}

// Get a user by ID
export const getUserById = async (userId) => {
  const userRef = doc(db, USERS_COLLECTION, userId)
  const userSnap = await getDoc(userRef)

  if (userSnap.exists()) {
    return { id: userSnap.id, ...userSnap.data() }
  } else {
    return null
  }
}

// Update a user
export const updateUser = async (firebase_uid, updateData) => {
  const userRef = doc(db, USERS_COLLECTION, firebase_uid)
  await updateDoc(userRef, { ...updateData, updated_at: serverTimestamp() })
}

// Validate coupon and get trial details
export const validateAndGetTrialDetails = async (couponCode) => {
  if (!couponCode) {
    // Default free trial - 2 weeks, 1 job
    const now = new Date()
    return {
      trial_end: Timestamp.fromDate(
        new Date(now.getTime() + 14 * 24 * 60 * 60 * 1000)
      ),
      job_post_limit: 1,
      plan_type: 'trial',
      coupon_used: null
    }
  }

  const couponRef = doc(db, 'coupons', couponCode.toLowerCase())
  const couponSnap = await getDoc(couponRef)

  if (!couponSnap.exists()) {
    throw new Error('Invalid coupon code')
  }

  const couponData = couponSnap.data()
  const now = new Date()

  if (now > couponData.expirationDate.toDate()) {
    throw new Error('Coupon has expired')
  }

  // Calculate trial end based on coupon type
  const trialEnd = couponData.isFixedEndDate
    ? couponData.fixedEndDate
    : Timestamp.fromDate(
        new Date(now.getTime() + couponData.trialDays * 24 * 60 * 60 * 1000)
      )

  return {
    trial_end: trialEnd,
    job_post_limit: couponData.jobPostLimit,
    plan_type: 'trial',
    coupon_used: couponCode.toLowerCase()
  }
}

// Check subscription status
export const checkSubscriptionStatus = async (userId) => {
  const userDoc = await getUserById(userId)
  if (!userDoc || !userDoc.subscription) {
    return {
      canPost: false,
      jobsRemaining: 0,
      status: 'expired',
      message: 'No valid subscription found'
    }
  }

  const now = new Date()
  const subscription = userDoc.subscription

  // Check if trial has expired
  if (now > subscription.trial_end.toDate()) {
    // Update to expired status if not already done
    if (subscription.plan_type !== 'expired') {
      await updateDoc(doc(db, USERS_COLLECTION, userId), {
        'subscription.plan_type': 'expired'
      })
    }
    return {
      canPost: false,
      jobsRemaining: 0,
      status: 'expired',
      message: 'Your trial has expired'
    }
  }

  // Still in trial period
  // For unlimited posts (special coupon codes)
  if (subscription.job_post_limit === -1) {
    return {
      canPost: true,
      jobsRemaining: 'unlimited',
      status: 'trial',
      message: `Trial active until ${subscription.trial_end
        .toDate()
        .toLocaleDateString()}. Unlimited posts available.`
    }
  }

  // For limited posts
  const jobsQuery = query(
    collection(db, 'jobPostings'),
    where('employer_id', '==', userId),
    where('status', '==', 'active')
  )
  const jobsSnapshot = await getDocs(jobsQuery)
  const usedPosts = jobsSnapshot.size

  const remainingPosts = subscription.job_post_limit - usedPosts
  return {
    canPost: remainingPosts > 0,
    jobsRemaining: remainingPosts,
    status: 'trial',
    message: `Trial active until ${subscription.trial_end
      .toDate()
      .toLocaleDateString()}. ${remainingPosts} posts remaining.`
  }
}

// Create an employer profile
export const createEmployerProfile = async (firebase_uid, profileData) => {
  const employerProfileRef = doc(
    db,
    USERS_COLLECTION,
    firebase_uid,
    'profiles',
    'employer'
  )
  await setDoc(employerProfileRef, {
    ...profileData,
    created_at: serverTimestamp(),
    updated_at: serverTimestamp()
  })
}

// Get employers and employer members
export const getEmployers = async () => {
  try {
    const employersQuery = query(
      collection(db, USERS_COLLECTION),
      where('role', 'in', ['employer', 'employerMember'])
    )

    const employersSnapshot = await getDocs(employersQuery)

    const employers = []

    for (const docSnapshot of employersSnapshot.docs) {
      const userData = docSnapshot.data()

      try {
        // Get employer profile
        const profileRef = doc(
          db,
          USERS_COLLECTION,
          docSnapshot.id,
          'profiles',
          'employer'
        )
        const profileSnap = await getDoc(profileRef)
        const profileData = profileSnap.exists() ? profileSnap.data() : {}

        // Use employer_id for consistent tracking
        const employerId = userData.employer_id || docSnapshot.id

        // Query for active job postings
        const jobsQuery = query(
          collection(db, 'jobPostings'),
          where('employer_id', '==', employerId),
          where('status', '==', 'active')
        )

        const jobsSnapshot = await getDocs(jobsQuery)
        const openRoles = jobsSnapshot.size

        // Include employers/members only if profile is complete or has open roles
        if (profileData.intake_completed || openRoles > 0) {
          employers.push({
            id: docSnapshot.id,
            name: userData.company_name || 'Company Name Not Set',
            logo: profileData.company_logo_url || null,
            website: profileData.company_website || null,
            openRoles: openRoles,
            employerId: employerId,
            subscriptionStatus: await checkSubscriptionStatus(employerId)
          })
        }
      } catch (profileError) {
        console.error(
          'Error processing employer/member:',
          docSnapshot.id,
          profileError
        )
      }
    }

    return employers.sort((a, b) => b.openRoles - a.openRoles)
  } catch (error) {
    console.error('Error in getEmployers:', error)
    return []
  }
}

export const getJobSeekers = async (maxResults = 60) => {
  try {
    // Limit the initial query to `maxResults`
    const jobSeekersQuery = query(
      collection(db, USERS_COLLECTION),
      where('role', '==', 'jobseeker'),
      limit(maxResults)
    )

    const jobSeekersSnapshot = await getDocs(jobSeekersQuery)
    const jobSeekers = []

    for (const docSnapshot of jobSeekersSnapshot.docs) {
      const userData = docSnapshot.data()

      // Fetch the job seeker profile from the subcollection
      const jobSeekerProfileRef = doc(
        db,
        USERS_COLLECTION,
        docSnapshot.id,
        PROFILE_SUBCOLLECTION,
        JOBSEEKER_PROFILE_DOC
      )

      const jobSeekerProfileSnap = await getDoc(jobSeekerProfileRef)
      let jobSeekerProfileData = {}

      if (jobSeekerProfileSnap.exists()) {
        jobSeekerProfileData = jobSeekerProfileSnap.data()
      } else {
        console.warn(
          `No profile data found for job seeker ID: ${docSnapshot.id}`
        )
      }

      jobSeekers.push({
        id: docSnapshot.id,
        first_name: userData.first_name || '',
        last_name: userData.last_name || '',
        email: userData.email || '',
        created_at: userData.created_at || null,
        updated_at: userData.updated_at || null,
        role: userData.role || 'jobseeker',
        pronouns: jobSeekerProfileData.pronouns || '', // Explicitly add pronouns
        ...jobSeekerProfileData
      })
    }

    return jobSeekers
  } catch (error) {
    console.error('Error in getJobSeekers:', error)
    return []
  }
}

export const getJobSeekers2 = async (
  maxResults = 60,
  lastDoc = null,
  getAll = false
) => {
  try {
    // First get count of completed profiles
    const completedProfilesQuery = query(
      collection(db, USERS_COLLECTION),
      where('role', '==', 'jobseeker')
    )
    const profilesSnapshot = await getDocs(completedProfilesQuery)
    let completedCount = 0

    // Count profiles with completed intakes
    await Promise.all(
      profilesSnapshot.docs.map(async (doc) => {
        const profileRef = doc(
          db,
          USERS_COLLECTION,
          doc.id,
          PROFILE_SUBCOLLECTION,
          JOBSEEKER_PROFILE_DOC
        )
        const profileSnap = await getDoc(profileRef)
        if (profileSnap.exists() && profileSnap.data().intake_completed) {
          completedCount++
        }
      })
    )

    // Get paginated results
    let jobSeekersQuery
    const baseQuery = [
      where('role', '==', 'jobseeker'),
      orderBy('created_at', 'desc'),
      limit(maxResults)
    ]

    jobSeekersQuery = lastDoc
      ? query(
          collection(db, USERS_COLLECTION),
          ...baseQuery,
          startAfter(lastDoc)
        )
      : query(collection(db, USERS_COLLECTION), ...baseQuery)

    const jobSeekersSnapshot = await getDocs(jobSeekersQuery)
    const lastVisible =
      jobSeekersSnapshot.docs[jobSeekersSnapshot.docs.length - 1]

    // Get and filter completed profiles
    const completedProfiles = await Promise.all(
      jobSeekersSnapshot.docs.map(async (docSnapshot) => {
        const userData = docSnapshot.data()
        const profileRef = doc(
          db,
          USERS_COLLECTION,
          docSnapshot.id,
          PROFILE_SUBCOLLECTION,
          JOBSEEKER_PROFILE_DOC
        )
        const profileSnap = await getDoc(profileRef)
        const profileData = profileSnap.exists() ? profileSnap.data() : {}

        if (!profileData.intake_completed) {
          return null
        }

        return {
          id: docSnapshot.id,
          first_name: userData.first_name || '',
          last_name: userData.last_name || '',
          email: userData.email || '',
          created_at: userData.created_at || null,
          updated_at: userData.updated_at || null,
          role: userData.role || 'jobseeker',
          ...profileData,
          has_profile: profileSnap.exists()
        }
      })
    )

    const jobSeekersWithProfiles = completedProfiles
      .filter(Boolean)
      .sort((a, b) => {
        // Sort by profile picture presence
        if (a.profile_picture && !b.profile_picture) return -1
        if (!a.profile_picture && b.profile_picture) return 1
        // Secondary sort by created_at
        return b.created_at - a.created_at
      })

    return {
      jobSeekers: jobSeekersWithProfiles,
      lastVisible,
      hasMore: !getAll && jobSeekersWithProfiles.length === maxResults,
      totalCount: completedCount
    }
  } catch (error) {
    console.error('Error in getJobSeekers:', error)
    return { jobSeekers: [], lastVisible: null, hasMore: false, totalCount: 0 }
  }
}
export default {
  createUser,
  getUserByFirebaseUid,
  getUserById,
  updateUser,
  createEmployerProfile,
  getEmployers,
  getJobSeekers,
  getJobSeekers2,
  checkSubscriptionStatus,
  validateAndGetTrialDetails
}
