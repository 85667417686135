// src/components/Admin/AdminLogin.js
import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #6366f1 0%, #a855f7 100%);
`

const LoginBox = styled.div`
  padding: 20px;
  background: white;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`

const LoginButton = styled.button`
  width: 100%;
  padding: 10px;
  background: #8b5cf6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: #6d28d9;
  }
`

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9em;
  margin-bottom: 10px;
`

const AdminLogin = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const handleLogin = () => {
    const validAdmins = [
      { username: 'olivia@arenatalent.com', password: 'WelcomeToArena24!' },
      { username: 'parul@arenatalent.com', password: 'WelcomeToArena24!' },
      { username: 'adriene@arenatalent.com', password: 'WelcomeToArena24!' }
    ]

    const isAdminValid = validAdmins.some(
      (admin) => admin.username === username && admin.password === password
    )

    if (isAdminValid) {
      navigate('/admin-dashboard')
    } else {
      setError('Invalid username or password')
    }
  }

  return (
    <Container>
      <LoginBox>
        <h2>Admin Login</h2>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <LoginButton onClick={handleLogin}>Login</LoginButton>
      </LoginBox>
    </Container>
  )
}

export default AdminLogin
