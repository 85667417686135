import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  Plus,
  MoreHorizontal,
  Home,
  Users,
  MessageSquare,
  Settings,
  X,
  GripVertical
} from 'lucide-react'
import { doc, updateDoc, getDoc, arrayRemove } from 'firebase/firestore'
import { auth, db } from '../../firebase'
import {
  getPipelinesFromFirestore,
  addPipeline,
  addJobSeekerToPipeline,
  removeJobSeekerFromPipeline,
  deletePipelineFromFirestore,
  moveJobSeekerBetweenPipelines
} from '../../models/EmployerProfile'
import { getJobseekerProfile } from '../../models/JobSeekerProfile'
import { getUserById } from '../../models/User'
import EmployerNav from './EmployerNav'
import { useNavigate } from 'react-router-dom'

// StrictModeDroppable component
const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true))
    return () => {
      cancelAnimationFrame(animation)
      setEnabled(false)
    }
  }, [])

  if (!enabled) {
    return null
  }

  return <Droppable {...props}>{children}</Droppable>
}

// Color palette
const softColors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#4a90e2',
  secondary: '#f6e05e',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0',
  success: '#68d391',
  warning: '#f6ad55',
  danger: '#fc8181',
  info: '#63b3ed',
  icons: '#12C2E8',
  icontext: '#C859FF',
  yellow: '#f6e05e',
  modalOverlay: 'rgba(0, 0, 0, 0.5)',
  hover: '#f7fafc'
}

// Styled Components
const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: ${softColors.background};
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`

const Header = styled.div`
  padding: 24px;
  background-color: ${softColors.card};
  border-bottom: 1px solid ${softColors.border};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: ${softColors.text};
  margin: 0;
`

const BoardContainer = styled.div`
  display: flex;
  flex: 1;
  overflow-x: auto;
  padding: 24px;
  gap: 24px;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${softColors.background};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${softColors.border};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${softColors.textLight};
  }
`
const List = styled.div`
  background-color: ${softColors.card};
  border-radius: 12px;
  width: 300px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 140px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid ${softColors.border};
`

const ListHeader = styled.div`
  padding: 16px;
  font-weight: 600;
  color: ${softColors.text};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${softColors.border};
`

const ListTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
`

const ListContent = styled.div`
  padding: 16px;
  flex: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${softColors.border};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${softColors.textLight};
  }
`

const DroppableList = styled(ListContent)`
  min-height: 100px;
  background: ${(props) =>
    props.isDraggingOver ? softColors.background : 'transparent'};
  transition: background-color 0.2s ease;
`

const DeleteButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  color: ${softColors.danger};
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.2s ease;
  z-index: 10;

  &:hover {
    transform: scale(1.1);
  }
`

const DraggableCard = styled.div`
  background: ${(props) =>
    props.isDragging ? softColors.background : softColors.card};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;
  box-shadow: ${(props) =>
    props.isDragging
      ? '0 5px 10px rgba(0,0,0,0.15)'
      : '0 1px 3px rgba(0,0,0,0.1)'};
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid ${softColors.border};
  position: relative;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: ${softColors.hover};

    ${DeleteButton} {
      opacity: 1;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const DragHandle = styled(GripVertical)`
  color: ${softColors.textLight};
  opacity: 0.5;
  cursor: grab;
  margin-right: 8px;
  flex-shrink: 0;

  &:hover {
    opacity: 1;
  }
`

const ProfileImage = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-image: url(${(props) => props.src || '/default-profile.png'});
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  border: 2px solid ${softColors.border};
`

const CandidateInfo = styled.div`
  flex: 1;
  overflow: hidden;
`

const CandidateName = styled.div`
  font-weight: 600;
  color: ${softColors.text};
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.95rem;
`

const Pronouns = styled.span`
  color: ${softColors.textLight};
  font-weight: normal;
  font-size: 0.8rem;
`

const CandidateTitle = styled.div`
  color: ${softColors.textLight};
  font-size: 0.85rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const AddListButton = styled.button`
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px dashed ${softColors.border};
  border-radius: 12px;
  color: ${softColors.textLight};
  cursor: pointer;
  padding: 16px;
  width: 300px;
  text-align: center;
  font-size: 14px;
  transition: all 0.2s ease;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover {
    background-color: ${softColors.card};
    border-color: ${softColors.primary};
    color: ${softColors.primary};
  }
`

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid ${softColors.border};
  border-radius: 6px;
  font-size: 14px;
  color: ${softColors.text};
  background: ${softColors.card};

  &:focus {
    outline: none;
    border-color: ${softColors.primary};
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
`

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${softColors.textLight};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover {
    color: ${softColors.danger};
    background-color: rgba(252, 129, 129, 0.1);
  }
`

const EmptyCard = styled.div`
  padding: 24px;
  text-align: center;
  color: ${softColors.textLight};
  font-style: italic;
  background-color: ${softColors.background};
  border-radius: 8px;
  font-size: 0.9rem;
`
export default function Pipelines() {
  const navigate = useNavigate()
  const [pipelines, setPipelines] = useState({})
  const [candidatesData, setCandidatesData] = useState({})
  const [newListName, setNewListName] = useState('')
  const [isAddingList, setIsAddingList] = useState(false)
  const [loading, setLoading] = useState(true)

  const fetchCandidateData = async (candidateId) => {
    try {
      const profileData = await getJobseekerProfile(candidateId)
      const userData = await getUserById(candidateId)
      return {
        ...profileData,
        ...userData
      }
    } catch (error) {
      console.error(`Error fetching data for candidate ${candidateId}:`, error)
      return null
    }
  }

  useEffect(() => {
    const fetchPipelines = async () => {
      setLoading(true)
      try {
        const user = auth.currentUser
        if (user) {
          const fetchedPipelines = await getPipelinesFromFirestore(user.uid)
          setPipelines(fetchedPipelines)

          const candidatesDataMap = {}
          for (const candidates of Object.values(fetchedPipelines)) {
            for (const candidateId of candidates) {
              if (!candidatesDataMap[candidateId]) {
                const data = await fetchCandidateData(candidateId)
                if (data) {
                  candidatesDataMap[candidateId] = data
                }
              }
            }
          }
          setCandidatesData(candidatesDataMap)
        }
      } catch (error) {
        console.error('Error fetching pipelines:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchPipelines()
  }, [])

  const handleAddList = async () => {
    if (newListName.trim()) {
      const user = auth.currentUser
      if (user) {
        try {
          await addPipeline(user.uid, newListName.trim())
          setPipelines((prevPipelines) => ({
            ...prevPipelines,
            [newListName.trim()]: []
          }))
          setNewListName('')
          setIsAddingList(false)
        } catch (error) {
          console.error('Error adding new pipeline:', error)
        }
      }
    }
  }

  const handleDeleteList = async (pipelineName) => {
    if (
      window.confirm(
        `Are you sure you want to delete the "${pipelineName}" pipeline?`
      )
    ) {
      const user = auth.currentUser
      if (user) {
        try {
          await deletePipelineFromFirestore(user.uid, pipelineName)
          setPipelines((prevPipelines) => {
            const newPipelines = { ...prevPipelines }
            delete newPipelines[pipelineName]
            return newPipelines
          })
        } catch (error) {
          console.error('Error deleting pipeline:', error)
        }
      }
    }
  }

  const onDragEnd = async (result) => {
    const { source, destination, draggableId } = result

    if (!destination) return

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return

    try {
      const user = auth.currentUser
      if (!user) return

      const start = source.droppableId
      const finish = destination.droppableId

      // Create new pipelines object
      const newPipelines = { ...pipelines }

      // Remove from source array
      const sourceList = Array.from(pipelines[start])
      sourceList.splice(source.index, 1)
      newPipelines[start] = sourceList

      // Add to destination array
      const destList = Array.from(pipelines[finish])
      destList.splice(destination.index, 0, draggableId)
      newPipelines[finish] = destList

      // Update state optimistically
      setPipelines(newPipelines)

      // Single Firestore update for moving the candidate
      await moveJobSeekerBetweenPipelines(
        user.uid,
        start,
        finish,
        draggableId,
        destination.index
      )
    } catch (error) {
      console.error('Error updating pipelines:', error)
      // Revert on error
      const fetchedPipelines = await getPipelinesFromFirestore(
        auth.currentUser.uid
      )
      setPipelines(fetchedPipelines)
    }
  }

  const handleCardClick = (candidateId) => {
    navigate(`/jobseeker-profile-view/${candidateId}`)
  }
  const handleDeleteCandidate = async (e, pipelineName, candidateId) => {
    e.stopPropagation() // Prevent card click event

    if (
      window.confirm(
        'Are you sure you want to remove this candidate from the pipeline?'
      )
    ) {
      const currentUser = auth.currentUser
      if (!currentUser) {
        alert('No user logged in')
        return
      }

      try {
        // Call the helper function to remove the candidate from the pipeline
        await removeJobSeekerFromPipeline(
          currentUser.uid,
          pipelineName,
          candidateId
        )

        // Update local state to reflect the deletion
        setPipelines((prevPipelines) => ({
          ...prevPipelines,
          [pipelineName]: prevPipelines[pipelineName].filter(
            (id) => id !== candidateId
          )
        }))

        console.log(
          'Successfully removed candidate:',
          candidateId,
          'from pipeline:',
          pipelineName
        )
      } catch (error) {
        console.error('Error removing candidate:', error)
        alert('Failed to remove candidate. Please try again.')

        // Refresh pipelines from Firestore to ensure UI is in sync
        try {
          const fetchedPipelines = await getPipelinesFromFirestore(
            currentUser.uid
          )
          setPipelines(fetchedPipelines)
        } catch (refreshError) {
          console.error('Error refreshing pipelines:', refreshError)
        }
      }
    }
  }

  if (loading) return <div>Loading...</div>

  return (
    <Container>
      <NavWrapper>
        <EmployerNav />
      </NavWrapper>
      <MainContent>
        <Header>
          <Title>Candidate Pipelines</Title>
        </Header>
        <DragDropContext onDragEnd={onDragEnd}>
          <BoardContainer>
            {Object.entries(pipelines).map(([listName, candidates]) => (
              <List key={listName}>
                <ListHeader>
                  <ListTitle>{listName}</ListTitle>
                  <IconButton onClick={() => handleDeleteList(listName)}>
                    <X size={16} />
                  </IconButton>
                </ListHeader>
                <StrictModeDroppable droppableId={listName}>
                  {(provided, snapshot) => (
                    <DroppableList
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      isDraggingOver={snapshot.isDraggingOver}
                    >
                      {candidates.map((candidateId, index) => {
                        const candidateData = candidatesData[candidateId]
                        if (!candidateData) return null

                        const currentJob = candidateData.work_history?.[0]

                        return (
                          <Draggable
                            key={candidateId}
                            draggableId={candidateId}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <DraggableCard
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                isDragging={snapshot.isDragging}
                              >
                                <div {...provided.dragHandleProps}>
                                  <DragHandle size={16} />
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%'
                                  }}
                                  onClick={() => handleCardClick(candidateId)}
                                >
                                  <ProfileImage
                                    src={candidateData.profile_picture}
                                  />
                                  <CandidateInfo>
                                    <CandidateName>
                                      {candidateData.first_name}{' '}
                                      {candidateData.last_name}
                                      {candidateData.pronouns && (
                                        <Pronouns>
                                          ({candidateData.pronouns})
                                        </Pronouns>
                                      )}
                                    </CandidateName>
                                  </CandidateInfo>
                                </div>
                                <DeleteButton
                                  onClick={(e) =>
                                    handleDeleteCandidate(
                                      e,
                                      listName,
                                      candidateId
                                    )
                                  }
                                >
                                  <X size={16} />
                                </DeleteButton>
                              </DraggableCard>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                      {candidates.length === 0 && (
                        <EmptyCard>No candidates in this pipeline</EmptyCard>
                      )}
                    </DroppableList>
                  )}
                </StrictModeDroppable>
              </List>
            ))}

            {isAddingList ? (
              <List>
                <ListHeader>
                  <Input
                    type="text"
                    value={newListName}
                    onChange={(e) => setNewListName(e.target.value)}
                    placeholder="Enter pipeline name"
                    autoFocus
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleAddList()
                      }
                    }}
                  />
                  <IconButton onClick={handleAddList}>
                    <Plus size={16} />
                  </IconButton>
                </ListHeader>
              </List>
            ) : (
              <AddListButton onClick={() => setIsAddingList(true)}>
                <Plus size={16} />
                Add Pipeline
              </AddListButton>
            )}
          </BoardContainer>
        </DragDropContext>
      </MainContent>
    </Container>
  )
}
