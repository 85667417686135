// First, create a new component called ProfileProtection.js
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { auth } from '../../firebase'
import { getEmployerProfile } from '../../models/EmployerProfile'

const ProfileProtection = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [hasCompletedIntake, setHasCompletedIntake] = useState(false)

  useEffect(() => {
    const checkIntakeStatus = async () => {
      try {
        const currentUser = auth.currentUser
        if (!currentUser) {
          setLoading(false)
          return
        }

        const profile = await getEmployerProfile(currentUser.uid)
        setHasCompletedIntake(profile?.intake_completed || false)
      } catch (error) {
        console.error('Error checking intake status:', error)
      } finally {
        setLoading(false)
      }
    }

    checkIntakeStatus()
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  if (!hasCompletedIntake) {
    return <Navigate to="/employer-intake" replace />
  }

  return children
}

export default ProfileProtection
