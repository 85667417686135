import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import {
  Search,
  Save,
  ChevronDown,
  ChevronUp,
  ArrowUpDown,
  X,
  User,
  Heart,
  Plus
} from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import EmployerNav from './EmployerNav'
import { getAllJobSeekerProfiles } from '../../models/JobSeekerProfile'
import { auth } from '../../firebase'
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore'
import { db } from '../../firebase'
import { getJobSeekers2 } from '../../models/JobSeekerProfile'

// Constants
const languageOptions = [
  'English',
  'Spanish',
  'Mandarin Chinese',
  'French',
  'German',
  'Japanese',
  'Russian',
  'Portuguese',
  'Hebrew',
  'Arabic',
  'Hindi',
  'Italian',
  'Korean',
  'Dutch',
  'Turkish',
  'Swedish'
].sort()

const strengths = [
  'Communication',
  'Leadership',
  'Problem Solving',
  'Teamwork',
  'Adaptability',
  'Creativity',
  'Time Management',
  'Critical Thinking',
  'Attention to Detail',
  'Emotional Intelligence',
  'Strategic Planning',
  'Networking',
  'Decision Making',
  'Conflict Resolution',
  'Analytical Skills',
  'Project Management',
  'Negotiation',
  'Public Speaking',
  'Customer Service',
  'Technical Proficiency',
  'Data Analysis',
  'Research',
  'Writing',
  'Multitasking',
  'Sales',
  'Marketing',
  'Budgeting',
  'Coaching',
  'Innovation',
  'Interpersonal Skills'
].sort()

const athleteStatusOptions = [
  'Current Collegiate Athlete',
  'Former Collegiate Athlete',
  'Current Professional Athlete',
  'Former Professional Athlete'
]

const raceOptions = [
  'Asian',
  'Black',
  'Asian',
  'Hispanic',
  'Middle Eastern/Arab',
  'Native American',
  'Pacific Islander',
  'White'
]

const genderOptions = [
  'Male',
  'Female',
  'Non-binary',
  'Transgender Male',
  'Transgender Female'
]

const veteranStatusOptions = ['Veteran', 'Active Duty', 'Reservist']

const industries = ['Sports', 'Media', 'Entertainment', 'Fashion', 'Technology']

const allSubcategories = {
  Sports: [
    'Professional Sports',
    'College Sports',
    'E-Sports',
    'Sports Technology',
    'Sports Marketing',
    'Sports Management',
    'Sports Medicine',
    'Fitness',
    'Outdoor Sports',
    'Recreational Sports',
    'Sports Analytics',
    'Youth Sports',
    'Athlete Representation',
    'Stadium Operations',
    'Event Management',
    'Sports Sponsorships',
    'Sports Broadcasting',
    'Fan Engagement',
    'Team Operations'
  ],
  Media: [
    'Broadcast Media',
    'Digital Media',
    'Publishing',
    'Advertising',
    'Journalism',
    'Social Media',
    'Streaming Services',
    'Podcasting',
    'Film Journalism',
    'Investigative Reporting',
    'Public Relations',
    'Content Creation',
    'Media Buying',
    'Media Planning',
    'News Production',
    'Multimedia Journalism',
    'Interactive Media',
    'Community Management',
    'Influencer Marketing'
  ],
  Entertainment: [
    'Film Production',
    'Music',
    'Gaming',
    'Live Events',
    'Theater',
    'Animation',
    'Virtual Reality',
    'Theme Parks',
    'Television Production',
    'Documentary Filmmaking',
    'Concert Promotion',
    'Talent Management',
    'Celebrity Management',
    'Film Distribution',
    'Cinematography',
    'Sound Design',
    'Set Design',
    'Voice Acting',
    'Content Distribution',
    'Entertainment Law',
    'Reality TV',
    'Music Production',
    'Film Scoring',
    'Comedy'
  ],
  Fashion: [
    'Luxury Fashion',
    'Streetwear',
    'Sportswear',
    'Fashion Technology',
    'Sustainable Fashion',
    'Accessories',
    'Cosmetics',
    'Fashion Media',
    'Apparel Design',
    'Textile Design',
    'Retail Fashion',
    'Fashion Merchandising',
    'Fashion Photography',
    'Fashion Blogging',
    'Runway Shows',
    'Fashion Buying',
    'E-commerce Fashion',
    'Fashion Consulting',
    'Pattern Making',
    'Fashion Illustration',
    'Costume Design',
    'Footwear Design',
    'Fashion PR',
    'Jewelry Design'
  ],
  Technology: ['Media Tech', 'Entertainment Tech', 'Fashion Tech']
}

// Styled Components

const SearchBar = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

const Button = styled.button`
  background-color: #caaae1;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;

  &:hover {
    background-color: #0060df;
  }
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;
`

const NestedFilterContent = styled.div`
  margin-left: 1rem;
  margin-top: 0.5rem;
`

const Checkbox = styled.input`
  margin-right: 0.5rem;
`

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`

const JobSeekerList = styled.div`
  display: grid;
  gap: 1rem;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const SortButton = styled(Button)`
  background-color: white;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: -40px;

  &:hover {
    background-color: #f0f0f0;
  }
`

const SortContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 4rem;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  margin-left: 0.5rem;
  color: #666;

  &:hover {
    color: #333;
  }
`

const MatchPercentage = styled.div`
  color: ${(props) => props.color};
  font-size: 0.9rem;
  font-weight: bold;
  position: relative;
  cursor: help;

  &:hover > div {
    display: block;
  }
`

const TooltipContent = styled.div`
  display: none;
  position: absolute;
  bottom: 100%;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px;
  z-index: 1000;
`
const StrengthsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  max-height: 300px;
  overflow-y: auto;
  padding-right: 0.5rem;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #caaae1;
    border-radius: 4px;
  }
`

const FilterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  max-height: 300px;
  overflow-y: auto;
  padding-right: 0.5rem;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #caaae1;
    border-radius: 4px;
  }
`

const CategoryHeader = styled.div`
  font-weight: 600;
  color: #333;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  grid-column: 1 / -1;
  border-bottom: 1px solid #eee;
`

const FavoriteFilterWrapper = styled.div`
  background-color: #f8f9fa;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #e9ecef;
`

const FavoriteLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  color: #333;

  &:hover {
    color: #000;
  }
`
const FavoriteCount = styled.span`
  background-color: #caaae1;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.8rem;
  margin-left: auto;
`
const PaginationControls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

const PaginationButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #caaae1;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`

const PageIndicator = styled.div`
  font-size: 1rem;
`

///////
const Sidebar = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: fit-content;
  position: sticky;
  top: 2rem;
`

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e2e8f0;
`

const FilterTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: #1a202c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.75rem 0;
  cursor: pointer;
  user-select: none;
  transition: color 0.2s ease;

  &:hover {
    color: #caaae1;
  }

  svg {
    transition: transform 0.2s ease;
  }
`

const FilterSection = styled.div`
  margin-bottom: 0.5rem;
  background-color: white;
  border-radius: 0.5rem;
`

const FilterContent = styled.div`
  max-height: ${(props) => (props.isOpen ? '300px' : '0')};
  overflow-y: ${(props) => (props.isOpen ? 'auto' : 'hidden')};
  transition: all 0.3s ease;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  margin-left: 0.5rem;
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0.25rem 0;
  font-size: 0.875rem;
  color: #4a5568;
  cursor: pointer;
  border-radius: 0.375rem;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f7f7f7;
  }

  input[type='radio'] {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    accent-color: #caaae1;
  }
`

const CheckboxLabel = styled(RadioLabel)`
  input[type='checkbox'] {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    accent-color: #caaae1;
  }
`

const FilterCount = styled.span`
  color: #718096;
  font-size: 0.75rem;
  margin-left: auto;
  background-color: #f7fafc;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
`

const ClearButton = styled.button`
  color: #caaae1;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 0.375rem;

  &:hover {
    background-color: #f7f7f7;
  }
`

const SearchInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }
`

const FilterSearchInput = styled(SearchInput)`
  width: 100%;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
`

const FilterCheckboxLabel = styled(CheckboxLabel)`
  font-size: 0.9rem;
  padding: 0.25rem;

  &:hover {
    background-color: #f8f9fa;
    border-radius: 4px;
  }
`

const StrengthCheckboxLabel = styled(CheckboxLabel)`
  font-size: 0.9rem;
  padding: 0.25rem;

  &:hover {
    background-color: #f8f9fa;
    border-radius: 4px;
  }
`
const JobSeekerCard = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  background-color: white;
  margin-bottom: 1rem;

  &:hover {
    background-color: #f8fafc;
  }
`

const ProfileImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
`

const ProfileImageFallback = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #f1f5f9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  color: #64748b;
`

const JobSeekerInfo = styled.div`
  flex-grow: 1;
`

const JobSeekerNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`

const JobSeekerName = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  color: #1a202c;
  margin: 0;
`

const Pronouns = styled.span`
  font-size: 0.875rem;
  color: #64748b;
`

const JobSeekerDetails = styled.p`
  font-size: 0.875rem;
  color: #4a5568;
  margin: 0;
  margin-bottom: 0.5rem;
  line-height: 1.5;
`

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.75rem;
`

const Tag = styled.span`
  background-color: #e9d8f8;
  color: #805ad5;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
`
const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
  font-family: 'Open Sans', sans-serif;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem 3rem;
  overflow-y: auto;
`

const Header = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 3rem;
`

const FiltersColumn = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: fit-content;
`

const JobSeekersColumn = styled.div`
  min-width: 0; // Prevents overflow
`

const JobSeekersHeader = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
`

const LoadMoreButton = styled(Button)`
  background-color: #caaae1;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  min-width: 200px;
  margin: 20px 0;

  &:hover {
    background-color: #b794d4;
  }

  &:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
`

const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-top: 20px;
`

const LoadingIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #666;
  gap: 10px;
`

const LoadingSpinner = styled.div`
  border: 3px solid #f3f3f3;
  border-top: 3px solid #caaae1;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const LoadingProgress = styled.div`
  text-align: center;
  color: #666;
  margin: 10px 0;
  font-size: 0.9rem;
`

const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin: 8px 0;
`

const ProgressFill = styled.div`
  height: 100%;
  background-color: #caaae1;
  border-radius: 4px;
  width: ${(props) => props.progress}%;
  transition: width 0.3s ease;
`
const LoadingDots = styled.span`
  &::after {
    content: '...';
    animation: dots 1.5s steps(4, end) infinite;
  }

  @keyframes dots {
    0%,
    20% {
      content: '';
    }
    40% {
      content: '.';
    }
    60% {
      content: '..';
    }
    80%,
    100% {
      content: '...';
    }
  }
`
export default function CandidateSourcing() {
  const navigate = useNavigate()

  // Core state
  const [jobSeekers, setJobSeekers] = useState([])
  const [filteredJobSeekers, setFilteredJobSeekers] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  // Pagination state

  const [lastVisible, setLastVisible] = useState(null)
  const [hasMore, setHasMore] = useState(true)

  // Search and filter state
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedLocations, setSelectedLocations] = useState([])
  const [selectedCurrentEmployers, setSelectedCurrentEmployers] = useState([])
  const [selectedCurrentTitles, setSelectedCurrentTitles] = useState([])
  const [selectedWorkExperience, setSelectedWorkExperience] = useState([])
  const [selectedCurrentLevel, setSelectedCurrentLevel] = useState([])
  const [selectedHighestDegree, setSelectedHighestDegree] = useState([])
  const [selectedMajors, setSelectedMajors] = useState([])
  const [selectedUniversities, setSelectedUniversities] = useState([])
  const [selectedHBCU, setSelectedHBCU] = useState(false)
  const [selectedStrengths, setSelectedStrengths] = useState([])
  const [selectedWillingToRelocate, setSelectedWillingToRelocate] =
    useState(null)
  const [selectedGenders, setSelectedGenders] = useState([])
  const [selectedRaces, setSelectedRaces] = useState([])
  const [selectedStartupExperience, setSelectedStartupExperience] = useState([])
  const [selectedLanguages, setSelectedLanguages] = useState([])
  const [selectedAthleteStatus, setSelectedAthleteStatus] = useState([])
  const [selectedVeteranStatus, setSelectedVeteranStatus] = useState([])
  const [selectedIndustries, setSelectedIndustries] = useState([])
  const [selectedSubcategories, setSelectedSubcategories] = useState([])

  // UI state
  const [sortOrder, setSortOrder] = useState('desc')
  const [openFilters, setOpenFilters] = useState({})
  const [openDemographicFilters, setOpenDemographicFilters] = useState({})
  const [locationSearch, setLocationSearch] = useState('')
  const [currentEmployerSearch, setCurrentEmployerSearch] = useState('')
  const [currentTitleSearch, setCurrentTitleSearch] = useState('')
  const [universitySearch, setUniversitySearch] = useState('')
  const [strengthSearch, setStrengthSearch] = useState('')
  const [industrySearch, setIndustrySearch] = useState('')
  const [subcategorySearch, setSubcategorySearch] = useState('')

  // Company-related state
  const [currentUserEmployerId, setCurrentUserEmployerId] = useState(null)
  const [currentUserCompanyId, setCurrentUserCompanyId] = useState(null)
  const [companyEmployerIds, setCompanyEmployerIds] = useState([])
  const [showFavoritedOnly, setShowFavoritedOnly] = useState(false)
  const [favoritedCount, setFavoritedCount] = useState(0)

  // Collection state
  const [favorites, setFavorites] = useState(new Set())
  const [pipeline, setPipeline] = useState(new Set())
  const [totalFetched, setTotalFetched] = useState(0)
  const [totalJobSeekers, setTotalJobSeekers] = useState(0)
  const [loadedCount, setLoadedCount] = useState(0)
  const [completedProfilesCount, setCompletedProfilesCount] = useState(0)

  const [page, setPage] = useState(1)

  const getTotalJobSeekersCount = async () => {
    try {
      const countQuery = query(
        collection(db, 'users'),
        where('role', '==', 'jobseeker')
      )
      const snapshot = await getDocs(countQuery)
      return snapshot.size
    } catch (error) {
      console.error('Error getting total count:', error)
      return 0
    }
  }

  useEffect(() => {
    let mounted = true

    const initializeData = async () => {
      try {
        setLoading(true)
        setError(null)

        // Get user info and total count simultaneously
        const [user, totalCount] = await Promise.all([
          auth.currentUser,
          getTotalJobSeekersCount()
        ])

        if (!user) {
          setError('No authenticated user')
          return
        }

        if (mounted) {
          setTotalJobSeekers(totalCount)
        }

        // Get initial job seekers
        const {
          jobSeekers: initialJobSeekers,
          lastVisible: newLastVisible,
          hasMore: newHasMore
        } = await getJobSeekers2(100)

        if (!initialJobSeekers || !Array.isArray(initialJobSeekers)) {
          setError('Invalid job seekers data received')
          return
        }

        // Get profiles for initial batch
        const profiles = await getAllJobSeekerProfiles(
          initialJobSeekers.map((user) => user.id)
        )

        if (!mounted) return

        // Process and combine user data with profiles
        const combinedData = initialJobSeekers
          .map((user) => {
            const profile = profiles[user.id] || {}

            return {
              // Basic user info
              id: user.id,
              first_name: user.first_name || '',
              last_name: user.last_name || '',
              email: user.email || '',
              created_at: user.created_at || new Date().toISOString(),
              has_profile: !!profiles[user.id],

              // Profile data
              profile_picture: profile.profile_picture || null,
              pronouns: profile.pronouns || null,
              location:
                profile.city && profile.state
                  ? `${profile.city}, ${profile.state}`
                  : null,
              current_employer: profile.current_employer || null,
              current_title: profile.current_title || null,

              // Work history
              work_history: Array.isArray(profile.work_history)
                ? profile.work_history.map((job) => ({
                    title: job.title || '',
                    employer: job.employer || '',
                    start_date: job.start_date || '',
                    end_date: job.end_date || '',
                    present: job.present || false
                  }))
                : [],

              // Experience
              years_experience: profile.years_experience || null,
              current_level: profile.current_level || null,

              // Skills and qualifications
              willing_to_relocate:
                profile.open_to_work?.includes('willing_to_relocate') || false,
              startup_experience: profile.startup_experience || false,
              languages: Array.isArray(profile.languages)
                ? profile.languages
                : [],
              top_strengths: Array.isArray(profile.top_strengths)
                ? profile.top_strengths
                : [],

              // Status
              athlete_status: profile.athlete_status || null,
              favorited_companies: Array.isArray(profile.favorited_companies)
                ? profile.favorited_companies
                : [],

              // Education
              education: Array.isArray(profile.education)
                ? profile.education.map((edu) => ({
                    university: edu.institution || edu.university || '',
                    degree: edu.degree_type || edu.degree || '',
                    major: edu.field_of_study || edu.major || '',
                    is_hbcu: edu.is_hbcu || false,
                    graduation_date: edu.graduation_date || null
                  }))
                : [],

              // Industry and categories
              industries: Array.isArray(profile.industries)
                ? profile.industries
                : [],
              subcategories: Array.isArray(profile.subcategories)
                ? profile.subcategories
                : [],

              // Demographics
              gender: profile.gender || null,
              race: profile.race || null,
              veteran_status: profile.veteran_status || null,

              // Metadata
              intake_completed: profile.intake_completed || false,
              last_active: profile.last_active || user.created_at || null
            }
          })
          .filter((js) => js.intake_completed) // Only include completed profiles

        if (mounted) {
          // Get employer IDs if available
          const userDoc = await getDoc(doc(db, 'users', user.uid))
          if (userDoc.exists()) {
            const userData = userDoc.data()
            if (userData.company_id) {
              const employersQuery = query(
                collection(db, 'users'),
                where('company_id', '==', userData.company_id)
              )
              const employersSnapshot = await getDocs(employersQuery)
              const employerIds = employersSnapshot.docs.map((doc) => doc.id)
              setCompanyEmployerIds(employerIds)
              setCurrentUserCompanyId(userData.company_id)
            }
          }

          // Update all states
          setJobSeekers(combinedData)
          setFilteredJobSeekers(combinedData)
          setLastVisible(newLastVisible)
          setHasMore(newHasMore)
          setLoadedCount(combinedData.length)

          console.log(
            `Loaded initial ${combinedData.length} of ${totalCount} total profiles`
          )
        }
      } catch (err) {
        console.error('Error initializing data:', err)
        if (mounted) {
          setError(err.message)
          resetStates()
        }
      } finally {
        if (mounted) {
          setLoading(false)
        }
      }
    }

    initializeData()

    // Cleanup function
    return () => {
      mounted = false
    }
  }, [])

  const loadMore = async () => {
    if (loading || !hasMore) return

    try {
      setLoading(true)

      const {
        jobSeekers: newJobSeekers,
        lastVisible: newLastVisible,
        hasMore: newHasMore
      } = await getJobSeekers2(100, lastVisible)

      const profiles = await getAllJobSeekerProfiles(
        newJobSeekers.map((user) => user.id)
      )

      const processedJobSeekers = newJobSeekers
        .map((user) => {
          const profile = profiles[user.id] || {}
          // ... your existing processing logic ...
        })
        .filter((js) => js.has_profile && js.intake_completed)

      if (processedJobSeekers.length > 0) {
        setJobSeekers((prev) => [...prev, ...processedJobSeekers])
        setFilteredJobSeekers((prev) => [...prev, ...processedJobSeekers])
        setLastVisible(newLastVisible)
        setHasMore(newHasMore)
        setLoadedCount((prev) => prev + newJobSeekers.length) // Total loaded
        setCompletedProfilesCount((prev) => prev + processedJobSeekers.length) // Completed profiles
      } else {
        setHasMore(false)
      }
    } catch (error) {
      console.error('Error loading more job seekers:', error)
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }
  // Filter job seekers
  const filterJobSeekers = useCallback(() => {
    if (!jobSeekers.length) return

    const filtered = jobSeekers.filter((jobSeeker) => {
      // Keep profile picture check
      if (!jobSeeker.profile_picture) return false

      // Search filter
      if (searchTerm) {
        const searchTermLower = searchTerm.toLowerCase()
        const searchableFields = [
          jobSeeker.first_name,
          jobSeeker.last_name,
          jobSeeker.email,
          jobSeeker.current_title,
          jobSeeker.current_employer,
          jobSeeker.location,
          jobSeeker.current_level,
          jobSeeker.years_experience,
          // Education fields
          ...(jobSeeker.education
            ?.map((edu) => [edu.university, edu.degree, edu.major])
            .flat() || []),
          // Work history fields
          ...(jobSeeker.work_history
            ?.map((job) => [job.title, job.employer])
            .flat() || []),
          // Other fields
          ...(jobSeeker.top_strengths || []),
          ...(jobSeeker.languages || [])
        ]
          .filter(Boolean)
          .join(' ')
          .toLowerCase()

        if (!searchableFields.includes(searchTermLower)) return false
      }

      // Location filter
      if (selectedLocations.length > 0) {
        if (
          !jobSeeker.location ||
          !selectedLocations.includes(jobSeeker.location)
        )
          return false
      }

      // Current title filter
      if (selectedCurrentTitles.length > 0) {
        if (
          !jobSeeker.current_title ||
          !selectedCurrentTitles.includes(jobSeeker.current_title)
        )
          return false
      }

      // Current level filter
      if (selectedCurrentLevel.length > 0) {
        if (
          !jobSeeker.current_level ||
          !selectedCurrentLevel.includes(jobSeeker.current_level)
        )
          return false
      }

      // Current employer filter
      if (selectedCurrentEmployers.length > 0) {
        if (
          !jobSeeker.current_employer ||
          !selectedCurrentEmployers.includes(jobSeeker.current_employer)
        )
          return false
      }

      // Work experience filter
      if (selectedWorkExperience.length > 0) {
        const experience =
          jobSeeker.years_experience === 'None/Student'
            ? 'Student'
            : jobSeeker.years_experience
        if (!experience || !selectedWorkExperience.includes(experience))
          return false
      }

      // Education filters
      if (
        selectedHighestDegree.length > 0 ||
        selectedUniversities.length > 0 ||
        selectedMajors.length > 0 ||
        selectedHBCU
      ) {
        if (!jobSeeker.education || jobSeeker.education.length === 0)
          return false

        // Check highest degree
        if (selectedHighestDegree.length > 0) {
          const highestDegree =
            jobSeeker.education[jobSeeker.education.length - 1].degree
          if (!selectedHighestDegree.includes(highestDegree)) return false
        }

        // Check universities
        if (selectedUniversities.length > 0) {
          const hasMatchingUniversity = jobSeeker.education.some((edu) =>
            selectedUniversities.includes(edu.university)
          )
          if (!hasMatchingUniversity) return false
        }

        // Check majors
        if (selectedMajors.length > 0) {
          const hasMatchingMajor = jobSeeker.education.some((edu) =>
            selectedMajors.includes(edu.major)
          )
          if (!hasMatchingMajor) return false
        }

        // Check HBCU
        if (selectedHBCU && !jobSeeker.education.some((edu) => edu.is_hbcu)) {
          return false
        }
      }

      // Strengths filter
      if (selectedStrengths.length > 0) {
        if (
          !jobSeeker.top_strengths ||
          !selectedStrengths.some((strength) =>
            jobSeeker.top_strengths.includes(strength)
          )
        )
          return false
      }

      // Languages filter
      if (selectedLanguages.length > 0) {
        if (
          !jobSeeker.languages ||
          !selectedLanguages.some((lang) => jobSeeker.languages.includes(lang))
        )
          return false
      }

      // Athlete status filter
      if (selectedAthleteStatus.length > 0) {
        if (
          !jobSeeker.athlete_status ||
          !selectedAthleteStatus.includes(jobSeeker.athlete_status)
        )
          return false
      }

      // Industry and subcategories filter
      if (selectedIndustries.length > 0 || selectedSubcategories.length > 0) {
        const hasMatchingIndustry =
          selectedIndustries.length === 0 ||
          selectedIndustries.some((industry) =>
            jobSeeker.industries?.includes(industry)
          )

        const hasMatchingSubcategory =
          selectedSubcategories.length === 0 ||
          selectedSubcategories.some((subcat) =>
            jobSeeker.subcategories?.includes(subcat)
          )

        if (!hasMatchingIndustry || !hasMatchingSubcategory) return false
      }

      // Demographics filters
      if (
        selectedGenders.length > 0 ||
        selectedRaces.length > 0 ||
        selectedVeteranStatus.length > 0
      ) {
        if (
          selectedGenders.length > 0 &&
          (!jobSeeker.gender || !selectedGenders.includes(jobSeeker.gender))
        )
          return false
        if (
          selectedRaces.length > 0 &&
          (!jobSeeker.race || !selectedRaces.includes(jobSeeker.race))
        )
          return false
        if (
          selectedVeteranStatus.length > 0 &&
          (!jobSeeker.veteran_status ||
            !selectedVeteranStatus.includes(jobSeeker.veteran_status))
        )
          return false
      }

      return true
    })

    setFilteredJobSeekers(filtered)
    updateFavoritedCount(filtered)
  }, [
    jobSeekers,
    searchTerm,
    selectedLocations,
    selectedCurrentTitles,
    selectedCurrentLevel,
    selectedCurrentEmployers,
    selectedWorkExperience,
    selectedHighestDegree,
    selectedUniversities,
    selectedMajors,
    selectedHBCU,
    selectedStrengths,
    selectedLanguages,
    selectedAthleteStatus,
    selectedIndustries,
    selectedSubcategories,
    selectedGenders,
    selectedRaces,
    selectedVeteranStatus,
    sortOrder
  ])
  // Apply filters when conditions change
  useEffect(() => {
    filterJobSeekers()
  }, [filterJobSeekers])

  // Utility functions
  const processJobSeekerData = (user, profile) => ({
    id: user.id,
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    email: user.email || '',
    created_at: user.created_at,
    has_profile: !!profile,
    pronouns: profile.pronouns ?? null,
    location:
      profile.city && profile.state
        ? `${profile.city}, ${profile.state}`
        : null,
    current_employer: profile.current_employer ?? null,
    current_title: profile.current_title ?? null,
    work_experience: profile.years_experience ?? null,
    willing_to_relocate:
      profile.open_to_work?.includes('willing_to_relocate') ?? false,
    startup_experience: profile.startup_experience ?? false,
    languages: profile.languages ?? [],
    strengths: profile.top_strengths ?? [],
    athlete_status: profile.athlete_status ?? null,
    favorited_companies: profile.favorited_companies ?? [],
    education:
      profile.education?.map((edu) => ({
        university: edu.institution || edu.university,
        degree: edu.degree_type || edu.degree,
        major: edu.field_of_study || edu.major,
        is_hbcu: edu.is_hbcu || false
      })) ?? [],
    rating: Math.random() * 100 // Replace with actual rating logic
  })

  const sortJobSeekers = (jobSeekers) => {
    return [...jobSeekers].sort((a, b) => {
      if (!a.has_profile && b.has_profile) return 1
      if (a.has_profile && !b.has_profile) return -1
      return sortOrder === 'asc' ? a.rating - b.rating : b.rating - a.rating
    })
  }

  const updateFavoritedCount = (filtered) => {
    setFavoritedCount(
      filtered.filter((js) =>
        js.favorites?.some((id) => companyEmployerIds.includes(id))
      ).length
    )
  }

  const resetStates = () => {
    setJobSeekers([])
    setFilteredJobSeekers([])
    setHasMore(false)
    setLastVisible(null)
  }
  const matchesSearch = (jobSeeker, term) => {
    const searchTerm = term.toLowerCase().trim()

    // Create a searchable string for education
    const educationString = jobSeeker.education
      ?.map(
        (edu) =>
          `${edu.university || ''} ${edu.degree || ''} ${edu.major || ''}`
      )
      .join(' ')
      .toLowerCase()

    // Create searchable string for work history
    const workHistoryString = jobSeeker.work_history
      ?.map((job) => `${job.title || ''} ${job.employer || ''}`)
      .join(' ')
      .toLowerCase()

    // Create an array of all searchable fields
    const searchableFields = [
      jobSeeker.first_name?.toLowerCase(),
      jobSeeker.last_name?.toLowerCase(),
      jobSeeker.email?.toLowerCase(),
      jobSeeker.current_title?.toLowerCase(),
      jobSeeker.current_employer?.toLowerCase(),
      jobSeeker.location?.toLowerCase(),
      jobSeeker.current_level?.toLowerCase(), // Added this line
      educationString,
      jobSeeker.top_strengths?.join(' ').toLowerCase(),
      jobSeeker.languages?.join(' ').toLowerCase(),
      workHistoryString,
      jobSeeker.years_experience?.toLowerCase()
    ].filter(Boolean)

    // Check if any field contains the search term
    return searchableFields.some((field) => field.includes(searchTerm))
  }
  const matchesLocations = (jobSeeker) =>
    !selectedLocations.length || selectedLocations.includes(jobSeeker.location)

  const matchesTitles = (jobSeeker) =>
    !selectedCurrentTitles.length ||
    selectedCurrentTitles.includes(jobSeeker.current_title)

  const matchesEmployers = (jobSeeker) =>
    !selectedCurrentEmployers.length ||
    selectedCurrentEmployers.includes(jobSeeker.current_employer)

  const matchesWorkExperience = (jobSeeker) => {
    if (!selectedWorkExperience.length) return true
    const experience =
      jobSeeker.years_experience === 'None/Student'
        ? 'Student'
        : jobSeeker.years_experience
    return selectedWorkExperience.includes(experience)
  }

  const matchesEducation = (jobSeeker) => {
    if (!jobSeeker.education) return false

    // Check highest degree
    if (selectedHighestDegree.length > 0) {
      const highestDegree =
        jobSeeker.education[jobSeeker.education.length - 1].degree
      if (!selectedHighestDegree.includes(highestDegree)) return false
    }

    // Check university
    if (selectedUniversities.length > 0) {
      const hasMatchingUniversity = jobSeeker.education.some((edu) =>
        selectedUniversities.includes(edu.university)
      )
      if (!hasMatchingUniversity) return false
    }

    // Check major
    if (selectedMajors.length > 0) {
      const hasMatchingMajor = jobSeeker.education.some((edu) =>
        selectedMajors.includes(edu.major)
      )
      if (!hasMatchingMajor) return false
    }

    // Check HBCU
    if (selectedHBCU && !jobSeeker.education.some((edu) => edu.is_hbcu)) {
      return false
    }

    return true
  }

  const matchesCurrentLevel = (jobSeeker) =>
    !selectedCurrentLevel.length ||
    selectedCurrentLevel.includes(jobSeeker.current_level)

  const matchesStrengths = (jobSeeker) => {
    if (!selectedStrengths.length) return true
    if (!jobSeeker.strengths) return false
    return selectedStrengths.some((strength) =>
      jobSeeker.strengths.includes(strength)
    )
  }

  const matchesRelocate = (jobSeeker) =>
    selectedWillingToRelocate === null ||
    jobSeeker.willing_to_relocate === selectedWillingToRelocate

  const matchesStartupExperience = (jobSeeker) => {
    if (!selectedStartupExperience.length) return true
    const hasStartupExp = jobSeeker.startup_experience ? 'Yes' : 'No'
    return selectedStartupExperience.includes(hasStartupExp)
  }

  const matchesLanguages = (jobSeeker) => {
    if (!selectedLanguages.length) return true
    if (!jobSeeker.languages) return false
    return selectedLanguages.some((lang) => jobSeeker.languages.includes(lang))
  }

  const matchesAthleteStatus = (jobSeeker) =>
    !selectedAthleteStatus.length ||
    selectedAthleteStatus.includes(jobSeeker.athlete_status)

  const matchesDemographics = (jobSeeker) => {
    // Gender check
    if (
      selectedGenders.length > 0 &&
      !selectedGenders.includes(jobSeeker.gender)
    ) {
      return false
    }

    // Race check
    if (selectedRaces.length > 0 && !selectedRaces.includes(jobSeeker.race)) {
      return false
    }

    // Veteran status check
    if (
      selectedVeteranStatus.length > 0 &&
      !selectedVeteranStatus.includes(jobSeeker.veteran_status)
    ) {
      return false
    }

    return true
  }

  const matchesIndustries = (jobSeeker) => {
    if (!selectedIndustries.length && !selectedSubcategories.length) return true

    const jobSeekerIndustries = jobSeeker.industries || []
    const jobSeekerSubcategories = jobSeeker.subcategories || []

    if (selectedIndustries.length > 0) {
      const hasMatchingIndustry = selectedIndustries.some((industry) =>
        jobSeekerIndustries.includes(industry)
      )
      if (!hasMatchingIndustry) return false
    }

    if (selectedSubcategories.length > 0) {
      const hasMatchingSubcategory = selectedSubcategories.some((subcategory) =>
        jobSeekerSubcategories.includes(subcategory)
      )
      if (!hasMatchingSubcategory) return false
    }

    return true
  }

  const toggleSort = () => {
    setSortOrder((prevOrder) => (prevOrder === 'desc' ? 'asc' : 'desc'))
    setFilteredJobSeekers((prev) =>
      [...prev].sort((a, b) =>
        sortOrder === 'asc' ? b.rating - a.rating : a.rating - b.rating
      )
    )
  }

  const toggleFilter = (filterName) => {
    setOpenFilters((prev) => ({ ...prev, [filterName]: !prev[filterName] }))
  }

  const toggleDemographicFilter = (filterName) => {
    setOpenDemographicFilters((prev) => ({
      ...prev,
      [filterName]: !prev[filterName]
    }))
    setOpenFilters((prev) => ({ ...prev, demographics: true }))
  }

  const clearAllFilters = () => {
    setSelectedLocations([])
    setSelectedCurrentEmployers([])
    setSelectedCurrentTitles([])
    setSelectedWorkExperience([])
    setSelectedCurrentLevel([])
    setSelectedHighestDegree([])
    setSelectedMajors([])
    setSelectedUniversities([])
    setSelectedHBCU(false)
    setSelectedStrengths([])
    setSelectedWillingToRelocate(null)
    setSelectedGenders([])
    setSelectedRaces([])
    setSelectedStartupExperience([])
    setSelectedLanguages([])
    setSelectedAthleteStatus([])
    setSelectedVeteranStatus([])
    setSelectedIndustries([])
    setSelectedSubcategories([])
    setOpenFilters({})
    setOpenDemographicFilters({})
    setSearchTerm('')
  }

  // Filter Options Renderer
  const renderFilterOptions = (
    options,
    selected,
    setSelected,
    searchTerm = ''
  ) => {
    const filteredOptions = searchTerm
      ? options.filter((option) =>
          option?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : options

    const selectedOptions = filteredOptions.filter((option) =>
      selected.includes(option)
    )
    const unselectedOptions = filteredOptions.filter(
      (option) => !selected.includes(option)
    )

    return (
      <>
        {selectedOptions.map((option) => (
          <CheckboxLabel key={option}>
            <Checkbox
              type="checkbox"
              checked={true}
              onChange={() => {
                setSelected(selected.filter((item) => item !== option))
              }}
            />
            {option}
            <ClearButton
              onClick={() =>
                setSelected(selected.filter((item) => item !== option))
              }
            >
              <X size={12} />
            </ClearButton>
          </CheckboxLabel>
        ))}
        {unselectedOptions.map((option) => (
          <CheckboxLabel key={option}>
            <Checkbox
              type="checkbox"
              checked={false}
              onChange={() => {
                setSelected([...selected, option])
              }}
            />
            {option}
          </CheckboxLabel>
        ))}
      </>
    )
  }
  const getFilteredSubcategories = () => {
    if (selectedIndustries.length === 0) {
      // If no industries selected, show all subcategories
      return Object.values(allSubcategories)
        .flat()
        .filter((subcategory) =>
          subcategory.toLowerCase().includes(subcategorySearch.toLowerCase())
        )
    } else {
      // Show subcategories only for selected industries
      return selectedIndustries.reduce((acc, industry) => {
        const filteredSubs = allSubcategories[industry].filter((subcategory) =>
          subcategory.toLowerCase().includes(subcategorySearch.toLowerCase())
        )
        return [...acc, { industry, subcategories: filteredSubs }]
      }, [])
    }
  }

  return (
    <PageWrapper>
      <NavWrapper>
        <EmployerNav />
      </NavWrapper>
      <MainContent>
        <Header>Browse Job Seekers</Header>

        <SearchBar>
          <SearchInput
            type="text"
            placeholder="Search job seekers (name, email, location, employer, title, education, skills...)"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button onClick={filterJobSeekers}>
            <Search size={18} />
            Search
          </Button>
        </SearchBar>
        {/* <FavoriteFilterWrapper>
          <FavoriteLabel>
            <Checkbox
              type="checkbox"
              checked={showFavoritedOnly}
              onChange={() => setShowFavoritedOnly(!showFavoritedOnly)}
            />
            <Heart
              size={18}
              color={showFavoritedOnly ? '#CAAAE1' : '#666'}
              fill={showFavoritedOnly ? '#CAAAE1' : 'none'}
            />
            Show only job seekers who favorited your company
          </FavoriteLabel>
        </FavoriteFilterWrapper> */}

        <ContentGrid>
          <FiltersColumn>
            {/* Your existing sidebar JSX here */}

            <FilterSection>
              <h1>Filters</h1>
              {/* Location Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('location')}>
                  Location
                  {openFilters.location ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.location}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search locations"
                    value={locationSearch}
                    onChange={(e) => setLocationSearch(e.target.value)}
                  />
                  {renderFilterOptions(
                    Array.from(
                      new Set(jobSeekers.map((p) => p.location).filter(Boolean))
                    ),
                    selectedLocations,
                    setSelectedLocations,
                    locationSearch
                  )}
                </FilterContent>
              </FilterSection>
              {/* Current Title Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('currentTitle')}>
                  Current Title
                  {openFilters.currentTitle ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.currentTitle}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search current titles"
                    value={currentTitleSearch}
                    onChange={(e) => setCurrentTitleSearch(e.target.value)}
                  />
                  {renderFilterOptions(
                    Array.from(
                      new Set(
                        jobSeekers.map((p) => p.current_title).filter(Boolean)
                      )
                    ),
                    selectedCurrentTitles,
                    setSelectedCurrentTitles,
                    currentTitleSearch
                  )}
                </FilterContent>
              </FilterSection>
              {/* Current Level Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('currentLevel')}>
                  Current Level
                  {openFilters.currentLevel ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.currentLevel}>
                  {renderFilterOptions(
                    [
                      'Internship',
                      'Entry Level',
                      'Manager',
                      'Director',
                      'Head / Lead',
                      'Vice President',
                      'Senior Vice President',
                      'C-Suite Executive'
                    ],
                    selectedCurrentLevel,
                    setSelectedCurrentLevel
                  )}
                </FilterContent>
              </FilterSection>
              {/* Work Experience Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('workExperience')}>
                  Work Experience
                  {openFilters.workExperience ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.workExperience}>
                  {renderFilterOptions(
                    [
                      'None/Student',
                      '1-2 years',
                      '3-5 years',
                      '6-10 years',
                      '11-15 years',
                      '16+ years'
                    ],
                    selectedWorkExperience,
                    setSelectedWorkExperience
                  )}
                </FilterContent>
              </FilterSection>
              {/* Current Employer Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('currentEmployer')}>
                  Employer
                  {openFilters.currentEmployer ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.currentEmployer}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search current employers"
                    value={currentEmployerSearch}
                    onChange={(e) => setCurrentEmployerSearch(e.target.value)}
                  />
                  {renderFilterOptions(
                    Array.from(
                      new Set(
                        jobSeekers
                          .map((p) => p.current_employer)
                          .filter(Boolean)
                      )
                    ),
                    selectedCurrentEmployers,
                    setSelectedCurrentEmployers,
                    currentEmployerSearch
                  )}
                </FilterContent>
              </FilterSection>
              {/* Education Filters */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('universities')}>
                  College/University
                  {openFilters.universities ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.universities}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search universities"
                    value={universitySearch}
                    onChange={(e) => setUniversitySearch(e.target.value)}
                  />
                  {renderFilterOptions(
                    Array.from(
                      new Set(
                        jobSeekers.flatMap(
                          (p) =>
                            p.education
                              ?.map((edu) => edu.university)
                              .filter(Boolean) || []
                        )
                      )
                    ),
                    selectedUniversities,
                    setSelectedUniversities,
                    universitySearch
                  )}
                  <NestedFilterContent>
                    <FilterTitle onClick={() => toggleFilter('hbcu')}>
                      HBCU
                      {openFilters.hbcu ? (
                        <ChevronUp size={18} />
                      ) : (
                        <ChevronDown size={18} />
                      )}
                    </FilterTitle>
                    <FilterContent isOpen={openFilters.hbcu}>
                      <CheckboxLabel>
                        <Checkbox
                          type="checkbox"
                          checked={selectedHBCU === true}
                          onChange={() => setSelectedHBCU((prev) => !prev)}
                        />
                        Yes
                      </CheckboxLabel>
                    </FilterContent>
                  </NestedFilterContent>
                </FilterContent>
              </FilterSection>
              {/* Highest Degree Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('highestDegree')}>
                  Highest Level Of Education
                  {openFilters.highestDegree ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.highestDegree}>
                  {renderFilterOptions(
                    [
                      'Some high school or less',
                      'High school diploma or equivalent',
                      'Vocational certificate or credential',
                      'Some college',
                      'Associates degree',
                      'Bachelors degree',
                      'Masters degree',
                      'PhD',
                      'MD, OD or related',
                      'DDS, DMD or related',
                      'JD',
                      'Other'
                    ],
                    selectedHighestDegree,
                    setSelectedHighestDegree
                  )}
                </FilterContent>
              </FilterSection>
              {/* Industry Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('industry')}>
                  Industry
                  {openFilters.industry ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.industry}>
                  <div className="max-h-64 overflow-y-auto">
                    {industries
                      .filter((industry) =>
                        industry
                          .toLowerCase()
                          .includes(industrySearch.toLowerCase())
                      )
                      .map((industry) => (
                        <CheckboxLabel key={industry}>
                          <Checkbox
                            type="checkbox"
                            checked={selectedIndustries.includes(industry)}
                            onChange={() => {
                              if (selectedIndustries.includes(industry)) {
                                setSelectedIndustries(
                                  selectedIndustries.filter(
                                    (i) => i !== industry
                                  )
                                )
                                // Remove all subcategories of this industry
                                setSelectedSubcategories(
                                  selectedSubcategories.filter(
                                    (sub) =>
                                      !allSubcategories[industry].includes(sub)
                                  )
                                )
                              } else {
                                setSelectedIndustries([
                                  ...selectedIndustries,
                                  industry
                                ])
                              }
                            }}
                          />
                          {industry}
                          {/* <span className="text-sm text-gray-500 ml-2">
                            ({allSubcategories[industry].length})
                          </span> */}
                        </CheckboxLabel>
                      ))}
                  </div>
                  {selectedIndustries.length > 0 && (
                    <div className="mt-2">
                      <Button
                        onClick={() => {
                          setSelectedIndustries([])
                          setSelectedSubcategories([])
                        }}
                        style={{
                          fontSize: '0.8rem',
                          padding: '0.25rem 0.5rem'
                        }}
                      >
                        Clear Selected ({selectedIndustries.length})
                      </Button>
                    </div>
                  )}
                </FilterContent>
              </FilterSection>
              {/* Subcategory Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('subcategory')}>
                  Company Type
                  {openFilters.subcategory ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.subcategory}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search subcategories"
                    value={subcategorySearch}
                    onChange={(e) => setSubcategorySearch(e.target.value)}
                  />
                  <FilterGrid>
                    {selectedIndustries.length === 0
                      ? // Show all subcategories in grid layout
                        getFilteredSubcategories().map((subcategory) => (
                          <FilterCheckboxLabel key={subcategory}>
                            <Checkbox
                              type="checkbox"
                              checked={selectedSubcategories.includes(
                                subcategory
                              )}
                              onChange={() => {
                                if (
                                  selectedSubcategories.includes(subcategory)
                                ) {
                                  setSelectedSubcategories(
                                    selectedSubcategories.filter(
                                      (s) => s !== subcategory
                                    )
                                  )
                                } else {
                                  setSelectedSubcategories([
                                    ...selectedSubcategories,
                                    subcategory
                                  ])
                                }
                              }}
                            />
                            {subcategory}
                          </FilterCheckboxLabel>
                        ))
                      : // Show categorized subcategories in grid layout
                        getFilteredSubcategories().map(
                          ({ industry, subcategories }) => (
                            <React.Fragment key={industry}>
                              {subcategories.length > 0 && (
                                <>
                                  <CategoryHeader>
                                    {industry} ({subcategories.length})
                                  </CategoryHeader>
                                  {subcategories.map((subcategory) => (
                                    <FilterCheckboxLabel key={subcategory}>
                                      <Checkbox
                                        type="checkbox"
                                        checked={selectedSubcategories.includes(
                                          subcategory
                                        )}
                                        onChange={() => {
                                          if (
                                            selectedSubcategories.includes(
                                              subcategory
                                            )
                                          ) {
                                            setSelectedSubcategories(
                                              selectedSubcategories.filter(
                                                (s) => s !== subcategory
                                              )
                                            )
                                          } else {
                                            setSelectedSubcategories([
                                              ...selectedSubcategories,
                                              subcategory
                                            ])
                                          }
                                        }}
                                      />
                                      {subcategory}
                                    </FilterCheckboxLabel>
                                  ))}
                                </>
                              )}
                            </React.Fragment>
                          )
                        )}
                  </FilterGrid>
                  {selectedSubcategories.length > 0 && (
                    <div className="mt-2">
                      <Button
                        onClick={() => setSelectedSubcategories([])}
                        style={{
                          fontSize: '0.8rem',
                          padding: '0.25rem 0.5rem'
                        }}
                      >
                        Clear Selected ({selectedSubcategories.length})
                      </Button>
                    </div>
                  )}
                </FilterContent>
              </FilterSection>
              {/* Strengths Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('strengths')}>
                  Strengths
                  {openFilters.strengths ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.strengths}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search strengths"
                    value={strengthSearch}
                    onChange={(e) => setStrengthSearch(e.target.value)}
                  />
                  <StrengthsGrid>
                    {strengths
                      .filter((strength) =>
                        strength
                          .toLowerCase()
                          .includes(strengthSearch.toLowerCase())
                      )
                      .map((strength) => (
                        <StrengthCheckboxLabel key={strength}>
                          <Checkbox
                            type="checkbox"
                            checked={selectedStrengths.includes(strength)}
                            onChange={() => {
                              if (selectedStrengths.includes(strength)) {
                                setSelectedStrengths(
                                  selectedStrengths.filter(
                                    (s) => s !== strength
                                  )
                                )
                              } else {
                                setSelectedStrengths([
                                  ...selectedStrengths,
                                  strength
                                ])
                              }
                            }}
                          />
                          {strength}
                        </StrengthCheckboxLabel>
                      ))}
                  </StrengthsGrid>
                  {selectedStrengths.length > 0 && (
                    <div className="mt-2">
                      <Button
                        onClick={() => setSelectedStrengths([])}
                        style={{
                          fontSize: '0.8rem',
                          padding: '0.25rem 0.5rem'
                        }}
                      >
                        Clear Selected ({selectedStrengths.length})
                      </Button>
                    </div>
                  )}
                </FilterContent>
              </FilterSection>
              {/* Willing to Relocate Filter
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('willingToRelocate')}>
                  Willing to Relocate
                  {openFilters.willingToRelocate ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.willingToRelocate}>
                  <CheckboxLabel>
                    <Checkbox
                      type="checkbox"
                      checked={selectedWillingToRelocate === true}
                      onChange={() =>
                        setSelectedWillingToRelocate((prev) =>
                          prev === true ? null : true
                        )
                      }
                    />
                    Yes
                  </CheckboxLabel>
                  <CheckboxLabel>
                    <Checkbox
                      type="checkbox"
                      checked={selectedWillingToRelocate === false}
                      onChange={() =>
                        setSelectedWillingToRelocate((prev) =>
                          prev === false ? null : false
                        )
                      }
                    />
                    No
                  </CheckboxLabel>
                </FilterContent>
              </FilterSection> */}
              {/* Startup Experience Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('startupExperience')}>
                  Startup Experience
                  {openFilters.startupExperience ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.startupExperience}>
                  {renderFilterOptions(
                    ['Yes', 'No'],
                    selectedStartupExperience,
                    setSelectedStartupExperience
                  )}
                </FilterContent>
              </FilterSection>
              {/* Languages Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('languages')}>
                  Languages
                  {openFilters.languages ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.languages}>
                  {renderFilterOptions(
                    languageOptions,
                    selectedLanguages,
                    setSelectedLanguages
                  )}
                </FilterContent>
              </FilterSection>
              {/* Athlete Status Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('athleteStatus')}>
                  Athlete Status
                  {openFilters.athleteStatus ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.athleteStatus}>
                  {renderFilterOptions(
                    athleteStatusOptions,
                    selectedAthleteStatus,
                    setSelectedAthleteStatus
                  )}
                </FilterContent>
              </FilterSection>
              {/* Demographics Section */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('demographics')}>
                  Demographics
                  {openFilters.demographics ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.demographics}>
                  {/* Gender Filter */}
                  <NestedFilterContent>
                    <FilterTitle
                      onClick={() => toggleDemographicFilter('gender')}
                    >
                      Gender
                      {openDemographicFilters.gender ? (
                        <ChevronUp size={18} />
                      ) : (
                        <ChevronDown size={18} />
                      )}
                    </FilterTitle>
                    <FilterContent isOpen={openDemographicFilters.gender}>
                      {renderFilterOptions(
                        genderOptions,
                        selectedGenders,
                        setSelectedGenders
                      )}
                    </FilterContent>
                  </NestedFilterContent>

                  {/* Race/Ethnicity Filter */}
                  <NestedFilterContent>
                    <FilterTitle
                      onClick={() => toggleDemographicFilter('race')}
                    >
                      Race/Ethnicity
                      {openDemographicFilters.race ? (
                        <ChevronUp size={18} />
                      ) : (
                        <ChevronDown size={18} />
                      )}
                    </FilterTitle>
                    <FilterContent isOpen={openDemographicFilters.race}>
                      {renderFilterOptions(
                        raceOptions,
                        selectedRaces,
                        setSelectedRaces
                      )}
                    </FilterContent>
                  </NestedFilterContent>

                  {/* Veteran Status Filter */}
                  <NestedFilterContent>
                    <FilterTitle
                      onClick={() => toggleDemographicFilter('veteranStatus')}
                    >
                      Veteran Status
                      {openDemographicFilters.veteranStatus ? (
                        <ChevronUp size={18} />
                      ) : (
                        <ChevronDown size={18} />
                      )}
                    </FilterTitle>
                    <FilterContent
                      isOpen={openDemographicFilters.veteranStatus}
                    >
                      {renderFilterOptions(
                        veteranStatusOptions,
                        selectedVeteranStatus,
                        setSelectedVeteranStatus
                      )}
                    </FilterContent>
                  </NestedFilterContent>
                </FilterContent>
              </FilterSection>
              {/* Button Group */}
              <ButtonGroup>
                <Button onClick={clearAllFilters}>Clear</Button>
              </ButtonGroup>
            </FilterSection>
          </FiltersColumn>
          <div>
            {/* <SortContainer>
              <SortButton onClick={toggleSort}>
                <ArrowUpDown size={18} />
                Sort by {showMatchPercentage ? 'Match' : 'Relevance'}{' '}
                {sortOrder === 'desc' ? 'Descending' : 'Ascending'}
              </SortButton>
            </SortContainer> */}

            <JobSeekersColumn>
              <JobSeekersHeader>
                {loading ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px'
                    }}
                  >
                    <LoadingSpinner />
                    Loading job seekers...
                  </div>
                ) : (
                  <>
                    {filteredJobSeekers.length} Job Seekers shown
                    <span
                      style={{
                        color: '#666',
                        fontSize: '0.9em',
                        marginLeft: '8px'
                      }}
                    >
                      (of {loadedCount} loaded)
                    </span>
                  </>
                )}
              </JobSeekersHeader>

              {error && (
                <div className="error-message">
                  Error loading job seekers: {error}
                </div>
              )}

              {!loading && filteredJobSeekers.length === 0 ? (
                <div>No job seekers found matching your criteria</div>
              ) : (
                <>
                  {filteredJobSeekers.map((jobSeeker) => (
                    <JobSeekerCard
                      key={jobSeeker.id}
                      onClick={() =>
                        navigate(`/jobseeker-profile-view/${jobSeeker.id}`)
                      }
                    >
                      {jobSeeker.profile_picture ? (
                        <ProfileImage
                          src={jobSeeker.profile_picture}
                          alt={`${jobSeeker.first_name} ${jobSeeker.last_name}`}
                          onError={(e) => {
                            e.target.onerror = null
                            e.target.src = '/default-avatar.png'
                          }}
                        />
                      ) : (
                        <ProfileImageFallback size={24} />
                      )}
                      <JobSeekerInfo>
                        {/* Name and Pronouns */}
                        <JobSeekerNameWrapper>
                          <JobSeekerName>
                            {`${jobSeeker.first_name || ''} ${
                              jobSeeker.last_name || ''
                            }`}
                          </JobSeekerName>
                          {jobSeeker.pronouns && (
                            <Pronouns>({jobSeeker.pronouns})</Pronouns>
                          )}
                        </JobSeekerNameWrapper>

                        {/* Title and Company */}
                        <JobSeekerDetails>
                          {jobSeeker.work_history?.length > 0 ? (
                            (() => {
                              const presentJobs = jobSeeker.work_history.filter(
                                (job) => job.present
                              )
                              const pastJobs = jobSeeker.work_history.filter(
                                (job) => !job.present
                              )

                              const sortedPresentJobs = presentJobs.sort(
                                (a, b) =>
                                  new Date(b.start_date) -
                                  new Date(a.start_date)
                              )
                              const sortedPastJobs = pastJobs.sort(
                                (a, b) =>
                                  new Date(b.end_date) - new Date(a.end_date)
                              )

                              const mostRecentJob =
                                presentJobs.length > 0
                                  ? sortedPresentJobs[0]
                                  : sortedPastJobs[0]

                              return (
                                <div key={mostRecentJob.title}>
                                  <strong>
                                    {mostRecentJob.title || 'Unknown Title'}
                                  </strong>{' '}
                                  at{' '}
                                  {mostRecentJob.employer || 'Unknown Employer'}
                                  {mostRecentJob.start_date && (
                                    <span>
                                      {' '}
                                      (
                                      {new Date(
                                        mostRecentJob.start_date
                                      ).toLocaleDateString()}{' '}
                                      -{' '}
                                      {mostRecentJob.present
                                        ? 'Present'
                                        : mostRecentJob.end_date
                                        ? new Date(
                                            mostRecentJob.end_date
                                          ).toLocaleDateString()
                                        : 'Unknown End Date'}
                                      )
                                    </span>
                                  )}
                                </div>
                              )
                            })()
                          ) : (
                            <span>No work history available.</span>
                          )}
                        </JobSeekerDetails>

                        <JobSeekerDetails>
                          {[
                            jobSeeker.years_experience
                              ? `${
                                  jobSeeker.years_experience === 'None/Student'
                                    ? 'Student'
                                    : `${jobSeeker.years_experience} experience`
                                }`
                              : null,
                            jobSeeker.current_level,
                            jobSeeker.location
                          ]
                            .filter(Boolean)
                            .join(' • ')}
                        </JobSeekerDetails>

                        {/* Education */}
                        {jobSeeker.education &&
                          jobSeeker.education.length > 0 && (
                            <JobSeekerDetails>
                              {jobSeeker.education
                                .map(
                                  (edu) =>
                                    `${edu.degree || ''} ${
                                      edu.major ? `in ${edu.major}` : ''
                                    } ${
                                      edu.university
                                        ? `from ${edu.university}`
                                        : ''
                                    }`
                                )
                                .join(' • ')}
                            </JobSeekerDetails>
                          )}

                        {/* Strengths/Skills Tags */}
                        <TagList>
                          {jobSeeker.top_strengths?.length > 0 &&
                            jobSeeker.top_strengths
                              .slice(0, 3)
                              .map((strength, index) => (
                                <Tag key={index}>{strength}</Tag>
                              ))}
                        </TagList>
                      </JobSeekerInfo>
                    </JobSeekerCard>
                  ))}

                  {/* Load More Section */}
                  <LoadMoreWrapper>
                    <LoadingProgress>
                      {loadedCount} of {totalJobSeekers} job seekers loaded
                      <ProgressBar>
                        <ProgressFill
                          progress={(loadedCount / totalJobSeekers) * 100}
                        />
                      </ProgressBar>
                      {loading && (
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                          Loading
                          <LoadingDots />
                        </div>
                      )}
                    </LoadingProgress>

                    {!loading &&
                      (hasMore ? (
                        <LoadMoreButton onClick={loadMore}>
                          Load More ({totalJobSeekers - loadedCount} remaining)
                        </LoadMoreButton>
                      ) : (
                        <div
                          style={{
                            color: '#666',
                            textAlign: 'center',
                            padding: '20px'
                          }}
                        >
                          All {loadedCount} job seekers loaded
                        </div>
                      ))}
                  </LoadMoreWrapper>
                </>
              )}
            </JobSeekersColumn>
          </div>
        </ContentGrid>
      </MainContent>
    </PageWrapper>
  )
}
