import { db } from '../firebase'
import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  serverTimestamp,
  doc,
  increment
} from 'firebase/firestore'

const JOB_APPLICATIONS_COLLECTION = 'jobApplications'
const JOB_POSTINGS_COLLECTION = 'jobPostings'
const USERS_COLLECTION = 'users' // To get employer data

// JobApplication data structure
const createJobApplicationObject = (applicationData) => ({
  jobseeker_id: applicationData.jobseeker_id,
  job_posting_id: applicationData.job_posting_id,
  employer_id: applicationData.employer_id,
  cover_letter: applicationData.cover_letter || '',
  resume_url: applicationData.resume_url || null,
  match_score: applicationData.match_score || 0,
  status: applicationData.status || 'submitted',
  company_name: applicationData.company_name || 'Unknown Company',
  company_logo_url: applicationData.company_logo_url || null,
  job_title: applicationData.job_title || 'Untitled Position',
  department: applicationData.department || '',
  location: applicationData.location || '',
  work_policy: applicationData.work_policy || '',
  type: Array.isArray(applicationData.type) ? applicationData.type : [],
  level: applicationData.level || '',
  rejection_reasoning: applicationData.rejection_reasoning || null,
  created_at: serverTimestamp(),
  updated_at: serverTimestamp()
})

export const createJobApplication = async (applicationData) => {
  try {
    // Create sanitized application object
    const applicationObject = createJobApplicationObject(applicationData)

    const applicationRef = await addDoc(
      collection(db, JOB_APPLICATIONS_COLLECTION),
      applicationObject
    )

    // Increment the number of applicants for the job posting
    await incrementJobPostingApplicants(applicationData.job_posting_id)

    return applicationRef
  } catch (error) {
    console.error('Error creating job application:', error)
    throw error
  }
}
// Increment the number of applicants for a job posting
const incrementJobPostingApplicants = async (jobPostingId) => {
  try {
    const jobRef = doc(db, JOB_POSTINGS_COLLECTION, jobPostingId)
    await updateDoc(jobRef, {
      number_of_applicants: increment(1)
    })
  } catch (error) {
    console.error('Error incrementing job posting applicants:', error)
  }
}

// Decrement the number of applicants for a job posting
const decrementJobPostingApplicants = async (jobPostingId) => {
  try {
    const jobRef = doc(db, JOB_POSTINGS_COLLECTION, jobPostingId)
    await updateDoc(jobRef, {
      number_of_applicants: increment(-1)
    })
  } catch (error) {
    console.error('Error decrementing job posting applicants:', error)
  }
}

// // Create a job application
// export const createJobApplication = async (applicationData) => {
//   try {
//     console.log('Creating application with data:', applicationData)

//     // Fetch job posting to retrieve employer_id
//     const jobPostingRef = doc(
//       db,
//       JOB_POSTINGS_COLLECTION,
//       applicationData.job_posting_id
//     )
//     const jobPostingSnap = await getDoc(jobPostingRef)
//     if (!jobPostingSnap.exists()) throw new Error('Job posting not found')

//     const jobPostingData = jobPostingSnap.data()
//     const employerId = jobPostingData.employer_id

//     // Fetch employer details
//     const employerRef = doc(db, USERS_COLLECTION, employerId)
//     const employerSnap = await getDoc(employerRef)
//     if (!employerSnap.exists()) throw new Error('Employer not found')

//     const employerData = employerSnap.data()

//     // Create job application object with employer details
//     const applicationObject = createJobApplicationObject(applicationData, {
//       employer_id: employerId,
//       company_name: employerData.company_name,
//       company_logo_url: employerData.company_logo_url
//     })

//     const applicationRef = await addDoc(
//       collection(db, JOB_APPLICATIONS_COLLECTION),
//       applicationObject
//     )
//     console.log('Job application created with ID:', applicationRef.id)

//     // Increment the number of applicants for the job posting
//     await incrementJobPostingApplicants(applicationData.job_posting_id)

//     return applicationRef
//   } catch (error) {
//     console.error('Error creating job application:', error)
//     throw error
//   }
// }

// Get a specific job application
export const getJobApplication = async (applicationId) => {
  try {
    const applicationRef = doc(db, JOB_APPLICATIONS_COLLECTION, applicationId)
    const applicationSnap = await getDoc(applicationRef)

    if (applicationSnap.exists()) {
      return { id: applicationSnap.id, ...applicationSnap.data() }
    } else {
      throw new Error('Job application not found')
    }
  } catch (error) {
    console.error('Error fetching job application:', error)
    throw error
  }
}

// Get all job applications for a specific job posting
export const getJobApplicationsForPosting = async (jobPostingId) => {
  try {
    const q = query(
      collection(db, JOB_APPLICATIONS_COLLECTION),
      where('job_posting_id', '==', jobPostingId)
    )

    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  } catch (error) {
    console.error('Error fetching job applications for posting:', error)
    throw error
  }
}

// Get all job applications for a specific jobseeker
export const getJobseekerApplications = async (jobseekerId) => {
  try {
    const q = query(
      collection(db, JOB_APPLICATIONS_COLLECTION),
      where('jobseeker_id', '==', jobseekerId)
    )

    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  } catch (error) {
    console.error('Error fetching jobseeker applications:', error)
    throw error
  }
}

// Update a job application
export const updateJobApplication = async (applicationId, updateData) => {
  try {
    const applicationRef = doc(db, JOB_APPLICATIONS_COLLECTION, applicationId)
    await updateDoc(applicationRef, {
      ...updateData,
      updated_at: serverTimestamp()
    })
  } catch (error) {
    console.error('Error updating job application:', error)
    throw error
  }
}

// Delete a job application
export const deleteJobApplication = async (applicationId) => {
  try {
    const applicationRef = doc(db, JOB_APPLICATIONS_COLLECTION, applicationId)
    const applicationSnap = await getDoc(applicationRef)

    if (applicationSnap.exists()) {
      const applicationData = applicationSnap.data()
      await deleteDoc(applicationRef)

      // Decrement the number of applicants for the job posting
      await decrementJobPostingApplicants(applicationData.job_posting_id)
    } else {
      throw new Error('Job application not found')
    }
  } catch (error) {
    console.error('Error deleting job application:', error)
    throw error
  }
}

export const getApplicationsWithUserAndJobData = async (jobPostingId) => {
  try {
    // Step 1: Query applications for the specified job posting
    const applicationsQuery = query(
      collection(db, JOB_APPLICATIONS_COLLECTION),
      where('job_posting_id', '==', jobPostingId)
    )
    const applicationsSnapshot = await getDocs(applicationsQuery)

    // Step 2: Fetch user and job posting data for each application
    const applicationsWithDetails = await Promise.all(
      applicationsSnapshot.docs.map(async (applicationDoc) => {
        const applicationData = applicationDoc.data()

        // Fetch user data
        const userRef = doc(db, USERS_COLLECTION, applicationData.jobseeker_id)
        const userSnap = await getDoc(userRef)
        const userData = userSnap.exists() ? userSnap.data() : null

        // Fetch job posting data (optional, if you want to confirm job details)
        const jobPostingRef = doc(
          db,
          JOB_POSTINGS_COLLECTION,
          applicationData.job_posting_id
        )
        const jobPostingSnap = await getDoc(jobPostingRef)
        const jobPostingData = jobPostingSnap.exists()
          ? jobPostingSnap.data()
          : null

        return {
          id: applicationDoc.id,
          ...applicationData,
          user: userData,
          jobPosting: jobPostingData
        }
      })
    )

    return applicationsWithDetails
  } catch (error) {
    console.error('Error fetching applications with user and job data:', error)
    throw error
  }
}

export default {
  createJobApplication,
  getApplicationsWithUserAndJobData,
  getJobApplication,
  getJobApplicationsForPosting,
  getJobseekerApplications,
  updateJobApplication,
  deleteJobApplication
}
